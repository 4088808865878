import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TaskPrPreparationRoutingModule } from './task-pr-preparation.routing.module';
import { TaskPrPreparationComponent } from './task-pr-preparation.component';
import { BarComponent } from 'whb-loading';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    TaskPrPreparationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    NgbNavModule,
    TaskPrPreparationRoutingModule,
    BarComponent
  ],
  exports: [
  ]
})
export class TaskPrPreparationModule { }
