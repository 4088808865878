import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TaskMapComponent } from './task-map.component';

const routes: Routes = [
  {path: "", component: TaskMapComponent},
  {path: ":latitude/:longitude", component: TaskMapComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TaskMapRoutingModule { }
