<nav class="d-flex justify-content-between mb-3">
    <h4 class="m-0 px-3">PR/QTT Preparation</h4>
    <button class="btn btn-primary btn-sm w-25" [routerLink]="['/task-schedule/report/budget']">Costing Summary</button>
</nav>

<div class="card">
    <div class="card-body">
        <ng-container *ngIf="isLoading">
            <lib-bar></lib-bar>
        </ng-container>

        <ng-container  *ngIf="!isLoading">
            <ul ngbNav #nav="ngbNav" [(activeId)]="currentActive" (navChange)="onNavChange($event)" class="nav-tabs">
                <li [ngbNavItem]="'pending'">
                    <a ngbNavLink>Pending ({{pendingListing.length}})</a>
                    <ng-template ngbNavContent>

                        <input class="form-control mb-2 mt-3" type="text" placeholder="Search something... (Job No / Profile Code / Contact Person / Contact Number)" [(ngModel)]="search" (ngModelChange)="getSearch()">
                       <table class="table table-sm table-hover">
                            <thead class="thead-dark">
                                <tr>
                                    <th style="width: 3%;">No</th>
                                    <th>Job No</th>
                                    <th>Description</th>
                                    <th style="width: 10%;">Create On</th>
                                    <th>Prepare By</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let list of pendingListing; let i = index;">
                                    <td>{{i+1}}</td>
                                    <td>{{list.reference_no}}</td>
                                    <td>
                                        <span><small>Profile Code : </small> <b>{{list.customer_code}}</b></span><br>
                                        <span><small>Address : </small> <b>{{list.address}}</b></span><br>
                                        <span><small>Contact Person : </small> <b>{{list.contact_person}}</b></span><br>
                                        <span><small>Contact Number : </small> <b>{{list.contact_number}}</b></span>
                                    </td>
                                    <td>{{list.create_on | date: 'dd-MM-yyyy'}}</td>
                                    <th><span *ngIf="list.pick_by">{{list.pick_by_user}}</span><span *ngIf="!list.pick_by"> - </span></th>
                                    <td>
                                        <div class="d-flex">
                                            <button class="btn btn-primary me-2" [routerLink]="['/detail/task', list.task_uuid, 'list', 'estimate', list.task_uuid]">View</button>
                                            <ng-container *ngIf="!list.pick_by">
                                                <button class="btn btn-dark me-2" (click)="updatePreparationItem('pick', list)">Prepare</button>
                                            </ng-container>
                                            <ng-container *ngIf="list.pick_by && list.is_allow_view">
                                                <button class="btn btn-success me-2" (click)="updatePreparationItem('complete', list)">Complete</button>
                                            </ng-container>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-template>
                </li>
                <li [ngbNavItem]="'complete'">
                    <a ngbNavLink>Completed ({{data.total_list}})</a>
                    <ng-template ngbNavContent>
                        <input class="form-control mb-2 mt-3" type="text" placeholder="Search something... (Job No / Profile Code / Contact Person / Contact Number)" [(ngModel)]="search" (ngModelChange)="getSearch()">
                        <table class="table table-sm table-hover">
                            <thead class="thead-dark">
                                <tr>
                                    <th style="width: 3%;">No</th>
                                    <th>Job No</th>
                                    <th>Description</th>
                                    <th style="width: 10%;">Create On</th>
                                    <th>Prepare By</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let list of completeListing; let i = index;">
                                    <td>{{i+1}}</td>
                                    <td>{{list.reference_no}}</td>
                                    <td>
                                        <span><small>Profile Code : </small> <b>{{list.customer_code}}</b></span><br>
                                        <span><small>Address : </small> <b>{{list.address}}</b></span><br>
                                        <span><small>Contact Person : </small> <b>{{list.contact_person}}</b></span><br>
                                        <span><small>Contact Number : </small> <b>{{list.contact_number}}</b></span>
                                    </td>
                                    <td>{{list.create_on | date: 'dd-MM-yyyy'}}</td>
                                    <th><span *ngIf="list.pick_by">{{list.pick_by_user}}</span><span *ngIf="!list.pick_by"> - </span></th>
                                    <td>
                                        <button class="btn btn-primary me-2" [routerLink]="['/detail/task', list.task_uuid, 'list', 'task-budget', list.task_uuid]">View</button>
                                        <button class="btn btn-warning me-2" *ngIf="list.is_approved == 0" (click)="updatePreparationItem('approved', list)">Approve</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="d-flex flex-row justify-content-end pb-2 align-items-center small">
                            <div class="btn-group" role="group">
                                <button type="button" class="btn btn-light" [disabled]="data.total_page == 1 || data.current_page == 1" (click)="previous()">&laquo;</button>
                                <button type="button" class="btn" [hidden]="data.current_page -2 <= 0" (click)="selectedPage(data.current_page -2)">{{data.current_page -2}}</button>
                                <button type="button" class="btn" [hidden]="data.current_page -1 <= 0" (click)="selectedPage(data.current_page -1)">{{data.current_page -1}}</button>
                                <button type="button" class="btn active" (click)="selectedPage(data.current_page)">{{data.current_page}}</button>
                                <button type="button" class="btn" [hidden]="data.current_page +1 > data.total_page" (click)="selectedPage(data.current_page +1)">{{data.current_page + 1}}</button>
                                <button type="button" class="btn" [hidden]="data.current_page +2 > data.total_page" (click)="selectedPage(data.current_page +2)">{{data.current_page + 2}}</button>
                                <button type="button" class="btn btn-light" [disabled]="data.total_page == 1 || data.current_page == data.total_page" (click)="next()">&raquo;</button>
                            </div>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </ng-container>

    </div>
</div>