import { AfterViewInit, Component, Input, ViewChild, ViewContainerRef, ChangeDetectorRef } from '@angular/core';
import { ViewerService } from '../../viewer.service';
import { ApiService } from 'lexi-api';

@Component({
  selector: 'lib-framework',
  templateUrl: './framework.component.html',
  styleUrls: ['./framework.component.css']
})

export class FrameworkComponent implements AfterViewInit {

  public framework:any = {structure:{}};
  public view_loaded:any = false;
  public is_loading:any = false;
  public structure:any;
  private params:any;
  private layout_data_uuid:any;
  private layout_uuid:any;
  private framework_uuid:any;
  private framework_type:any;

  @Input() set setFramework(v:any) {
    this.framework = v;
  }

  @Input() set setParams(v:any) {
    this.params = v;
    
    if((this.framework && this.framework.framework_type == "outlet") || 
      (this.layout_uuid != v.layout_uuid) || 
      (this.layout_data_uuid != v.layout_data_uuid) || 
      (this.framework_uuid != v.framework_uuid && this.framework.framework_type != 'menu') || 
      (this.framework_type != v.framework_type))  {

        if(this.layout_uuid != v.layout_uuid){
          this.layout_uuid = v.layout_uuid;
        }
  
        if(this.layout_data_uuid != v.layout_data_uuid) {
          this.layout_data_uuid = v.layout_data_uuid
        }

        if(this.framework_uuid != v.framework_uuid){
          this.framework_uuid = v.framework_uuid
        }
  
        if(this.framework_type != v.framework_type){
          this.framework_type = v.framework_type
        }

        this.init()  
    }
  }

  @ViewChild("content", {read: ViewContainerRef}) contentView!: ViewContainerRef;

  constructor(private api: ApiService, private cdr: ChangeDetectorRef, public viewerService: ViewerService) { }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
    this.view_loaded = true;
    this.init();
  }

  async init() {
    if(!this.view_loaded) {
      return;
    }

    this.is_loading = true;
    this.contentView.clear();

    if(this.framework.framework_type == 'custom') {
      await this.viewerService.loadContent(this.framework.framework_type, this.framework, {}, this.contentView, this.params)
      this.is_loading = false;
      return;
    }

    if(this.framework.framework_type == 'outlet') {
      this.framework = await this.api.post("/view/my-cloud/framework", {framework_uuid: this.framework_uuid, framework_type: this.framework_type, layout_data_uuid: this.params.layout_data_uuid, data_uuid: this.params.data_uuid ?? this.params.layout_data_uuid})
      await this.viewerService.loadContent(this.framework.framework.type, this.framework.framework, this.framework, this.contentView, this.params)
      this.is_loading = false;
      return;
    }

    let y = await this.api.post("/view/my-cloud/framework", {framework_uuid: this.framework.framework_uuid ?? this.params.framework_uuid, framework_type: this.framework.framework_type ?? this.params.framework_type, layout_data_uuid: this.params.layout_data_uuid, data_uuid: this.params.data_uuid ?? this.params.layout_data_uuid})
    if(y.status) {
      this.structure = y;
    
      let vs = await this.viewerService.loadContent(y.framework.type, y.framework, this.structure, this.contentView, this.params)

      if(y.framework.type == 'list') {
        // vs.instance.retrieveTable.subscribe((val:any) =>{ 
          // this.structure = val;
          // this.init();
        // })
      }
    }
    
    this.is_loading = false;
  }
}
