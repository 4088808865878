<div class="modal-body">
    <h5><b>Transaction Detail</b></h5>
    <hr>
    <div class="form-group">
        <label>Warehouse</label>
        <ng-select class="form-control p-0 border-0" [(ngModel)]="data.location_id" name="location_id">
            <ng-option *ngFor="let location of locations" [value]="location.id">{{location.code}}</ng-option>
        </ng-select>
    </div>

    <div class="form-group">
        <label>Date</label>
        <input class="form-control" type="date" [(ngModel)]="data.date" name="date">
    </div> 
    <ng-container *ngIf="data.method == 'stock_in'">  
        <div class="form-group">
            <label>Supplier Name</label>
            <input class="form-control" type="text" [(ngModel)]="data.supplier" name="supplier_name">
        </div>   
        <div class="form-group">
            <label>Supplier Invoice No</label>
            <input class="form-control" type="text" [(ngModel)]="data.invoice_no" name="invoice_no">
        </div> 
        <div class="form-group">
            <label>Transaction Type</label>
            <select class="form-control" [(ngModel)]="data.type" name="type" disabled>
                <option [value]="'IN'">IN</option>
                <option [value]="'RETURN'">RETURN</option>
                <option [value]="'ADJUST'">ADJUSTMENT</option>
            </select>
        </div> 
        <div class="form-group">
            <label>Employee Name</label>
            <ng-multiselect-dropdown
                [placeholder]="''"
                [settings]="employeeDropdownSettings"
                [data]="users"
                [(ngModel)]="data.userIds"
                name="userIds">
            </ng-multiselect-dropdown>
        </div>   
        <div class="form-group">
            <label>Job Invoice No</label>
            <input class="form-control" type="text" [(ngModel)]="data.reference_no" name="reference_no">
        </div>
        <div class="form-group">
            <label>Unit Price</label>
            <input class="form-control" type="number" [(ngModel)]="data.rate" name="rate" disabled>
        </div>
        <div class="form-group">
            <label>Stock In Quantity</label>
            <input class="form-control" type="number" [(ngModel)]="data.debit" name="debit" disabled>
        </div>
        <div class="form-group">
            <label>License</label>
            <select class="form-control" [(ngModel)]="data.license" name="license">
                <option [value]="'YES'">YES</option>
                <option [value]="'NO'">NO</option>
            </select>
        </div>
        <div class="form-group">
            <label>License Expired</label>
            <input class="form-control" type="date" [(ngModel)]="data.license_expiry_date" name="license_expiry_date">
        </div>
        <div class="form-group">
            <label>Remark</label>
            <textarea class="form-control" rows="3" [(ngModel)]="data.remark" name="remark"></textarea>
        </div>
    </ng-container>
    
    <ng-container *ngIf="data.method == 'stock_out'">
        <div class="form-group">
            <label>Employee Name</label>
            <ng-multiselect-dropdown
                [placeholder]="''"
                [settings]="employeeDropdownSettings"
                [data]="users"
                [(ngModel)]="data.userIds"
                name="userIds">
            </ng-multiselect-dropdown>
        </div>   
        <div class="form-group">
            <label>Job Invoice No</label>
            <input class="form-control" type="text" [(ngModel)]="data.reference_no" name="reference_no">
        </div> 
        <div class="form-group">
            <label>Transaction Type</label>
            <select class="form-control" [(ngModel)]="data.type" name="type" disabled>
                <option [value]="'OUT'">OUT</option>
                <option [value]="'EXPIRED'">EXPIRED</option>
                <option [value]="'ADJUST'">ADJUSTMENT</option>
            </select>
        </div> 
        <div class="form-group">
            <label>Stock Out Quantity</label>
            <input class="form-control" type="number" [(ngModel)]="data.credit" name="credit" disabled>
        </div>
        <div class="form-group">
            <label>License</label>
            <select class="form-control" [(ngModel)]="data.license" name="license">
                <option [value]="'YES'">YES</option>
                <option [value]="'NO'">NO</option>
            </select>
        </div>
        <div class="form-group">
            <label>License Expired</label>
            <input class="form-control" type="date" [(ngModel)]="data.license_expiry_date" name="license_expiry_date">
        </div>
        <div class="form-group">
            <label>Remark</label>
            <textarea class="form-control" rows="3" [(ngModel)]="data.remark" name="remark"></textarea>
        </div>
    </ng-container>
</div>
<div class="modal-footer">
    <button class="btn btn-success btn-sm w-100 mt-2" (click)="submit()">Submit</button>
    <button class="btn btn-danger btn-sm w-100 mt-2" (click)="close()">Cancel</button>
</div>
