import { Component, OnInit } from '@angular/core';
import { ApiService } from 'lexi-api';
import { LexiNotifyService } from 'lexi-notify';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResourceManagerComponent } from 'lexi-resource';
import { TaskProjectFilterComponent } from './task-project-filter/task-project-filter.component';
import { TaskProjectConvertComponent } from './task-project-convert/task-project-convert.component';
import { TaskProjectMaterialInquiryComponent } from './task-project-material-inquiry/task-project-material-inquiry.component';
import { TaskProjectCompleteConfirmationComponent } from './task-project-complete-confirmation/task-project-complete-confirmation.component';
import { TaskProjectCompleteCancelComponent } from './task-project-complete-cancel/task-project-complete-cancel.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-task-project',
  templateUrl: './task-project.component.html',
  styleUrls: ['./task-project.component.css']
})
export class TaskProjectComponent implements OnInit {

  public data:any = {pages: {current_page: 1}};
  public listing:any = [];
  public users:any = [];
  public userList:any = [];
  public keyword:any = "";
  public value:any;
  public shown = false;
  public filterData:any = {filter: {}};
  public sumAmount:number = 0;
  public isBtnLoading:boolean = false;
  public isLoading:boolean = false;
  private timeout:any = null;
  public selectedRowIdx:number = 0;
  public sqlLoading:any = false;
  
  constructor(private api: ApiService, private notify: LexiNotifyService, private modalService: NgbModal, private activatedRoute: ActivatedRoute) { 
    this.activatedRoute.queryParams.subscribe((params:any) => {
      this.filterData.search = params.doc_no;
    })
    
    const date = new Date();

    this.data.date_from = date.getFullYear() +"-"+ ("0" + (date.getMonth() + 1)).slice(-2) +"-01";
    this.data.date_to = date.getFullYear() +"-"+ ("0" + (date.getMonth() + 1)).slice(-2) +"-"+ ("0" + date.getDate()).slice(-2);
  }

  ngOnInit(): void {
    this.initialize()
    this.getSchedule();
  }

  async initialize() {
    this.users = await this.api.post("/setting/get/users/bySiteLeader", {})
    this.userList = this.users;
  }

  async getSchedule() {
    this.isLoading = true;
    this.data['filter'] = this.filterData;

    const result = await this.api.post('/task/task-progress/get', this.data)
    if(result){
      this.listing = result.collections;
      this.data.pages = result.pages;
    }

    this.isLoading = false;
    this.sumTotal();
    this.initVal()
  }

  async sumTotal() {
    this.sumAmount = 0;
    this.listing.forEach((list:any) => {
      this.sumAmount += (list.total_payable ? parseFloat(list.total_payable) : 0);
    });

  }

  async initVal() {
    for(let z = 0; z < this.listing.length; z++){
      const val = this.userList.find((x:any) => {
        return x.user_id == parseInt(this.listing[z].assign_to)
      });
      if(val){
        this.retriveData("name", "user_id", val, this.listing[z])
      }
    }
  }

  retriveData(dataKey:any, dataValue:any, item:any, list:any) {
    list.keyword = item[dataKey];
    list.value = item[dataKey];
    list.assign_to = item[dataValue]
  }

  select(dataKey:any, dataValue:any, item:any, list:any) {
    list.keyword = item[dataKey];
    list.value = item[dataKey];
    list.assign_to = item[dataValue]

    list.shown = false;
    this.updateTask(list, 'assign_to')
  }

  getEndDate(startDate:any, workday:any) {
    const d = new Date(startDate);
    d.setDate(d.getDate() +  workday);
    return d;
  }

  async updateTask(task:any, type:any) {
    setTimeout(async () => {
      const data = {task_id: task.task_id, proforma_id: task.proforma_id ?? null, quotation_id: task.quotation_id ?? null, doc_id: task.doc_id, doc_type: task.entry_type, type: type, status: task[type]};
      const result = await this.api.post("/task/task-progress/update/individual", data)
      if(!result.status){
        return this.notify.error(result.message)
      }
  
      this.notify.success("Updated")
    }, 2000)
  }

  async selectMedia(task:any, type:any, task_uuid:any) {
    let modal = this.modalService.open(ResourceManagerComponent, {size: "xl"});
    modal.componentInstance.setPath = "whb/"+type+"/" + task_uuid + "/"
    modal.componentInstance.selectType = "single"
    modal.componentInstance.setSupportedFileType = ['image/jpg', 'image/jpeg', 'image/png', 'video/mp4']
    modal.componentInstance.getSelected.subscribe(async(v:any) => {
      task[type] = v[0]['key']
      await this.updateTask(task, type)
      modal.close();
    })
  }

  search(e:any) {
    if(!e || e == undefined) {
      this.users = this.userList;
      return;
    }
    let val = e.toLowerCase()
    const temp = this.userList.filter((x:any) => {
      if (x['name'].toLowerCase().indexOf(val) !== -1 || !val) {
        return x;
      }
    });
    this.users = temp;
  }

  show(list:any) {
    list.shown = !list.shown;
    list.keyword = "";

    setTimeout(() => {
    }, 0);
  }

  async getFilter() {
    let modal = this.modalService.open(
      TaskProjectFilterComponent, {ariaLabelledBy: 'modal-task-project-filter', backdrop: 'static', size: 'xl', keyboard: false, centered: true}
    );

    modal.componentInstance.setFilter = this.filterData.filter;
    modal.componentInstance.retrieveFilter.subscribe((e:any) => {
      modal.close();
      if(e) {
        this.filterData['filter'] = e
        this.getSchedule()
      }
    })
  }

  getReset() {
    this.filterData = {};
    this.filterData.filter = {}
    this.getSchedule()
  }

  async toggleAction(type:any, data:any, actionType?:any) {
    this.isBtnLoading = true;

    switch(type) {
      case "complete":
        let confirmModal:any = this.modalService.open(TaskProjectCompleteConfirmationComponent, {ariaLabelledBy: 'modal-task-project-complete-confirm', backdrop: 'static', size: 'xl', keyboard: false, centered: true})
        confirmModal.componentInstance.setData = data;
        confirmModal.componentInstance.closeEmitter.subscribe(async (e:any) => {
          
          confirmModal.close();
          if(!e){
            this.isBtnLoading = false;
            return;
          }

          const result = await this.api.post("/task/task-progress/complete", data)
          if(!result.status){
            this.isBtnLoading = false;
            this.notify.error(result.message)
            return;
          }
    
          this.notify.success("Save Successfull.")
          this.isBtnLoading = false;
          this.getSchedule()
        })
      break;
      
      case "convertInvoice":
        const modal = this.modalService.open(TaskProjectConvertComponent, {size: "md", backdrop: 'static', keyboard: false});
        modal.componentInstance.setType = actionType;
        modal.componentInstance.dataEmitter.subscribe(async (val:any) => {
          if(!val){
            this.isBtnLoading = false;
            modal.close();
          }else{
            const docRtn = await this.api.post('/task/project/entry/get', {type: data.entry_type, uuid: data.uuid})
            let dt = docRtn.data;
                dt.newEntry = true;
            delete dt.uuid;

            dt.status = 1;

            if(val.inv_no){
              dt.sql_reference_no = val.inv_no
            }else if(val.inv_date){
              dt.sql_reference_no = null
              dt.sql_create_date = val.inv_date
            }
            
            //fong works here
            if(actionType == "live_convert"){
              dt['notes'] = data?.notes;
              dt['payment_remark'] = data?.payment_remark;
              if(!dt['site_leader_name']){
                this.notify.error("Please assign site leader first.")
                return;
              }
              this.sqlLoading = true;
              modal.componentInstance.loading = true;

              //set timer get respond
              let result = await this.api.post("/admin/connect/sql", dt);
              if(!result || !result?.status){
                this.notify.error("Failed to request create invoice in SQL system.");
                return;
              }
              
              let callback = setInterval(async () => {
                let rtn = await this.api.post("/admin/connect/sql/callback", {uuid: result.uuid});
                
                if(rtn?.request_result && rtn?.request_status == 1){
                  this.notify.success(rtn?.request_result);
                }else if(rtn?.request_status == 2){
                  this.notify.error(rtn?.request_result);
                }

                if(rtn?.request_status != 0){
                  clearInterval(callback);
                  clearInterval(overTime);

                  dt['sql_reference_no'] = rtn?.request_result;
                  const result = await this.api.post('/task/project/entry/save', {data: dt, type: "invoice", convertFromType: data.entry_type})
                  this.isBtnLoading = false;

                  if(!result.status){
                    return this.notify.error(result.message)
                  }

                  this.notify.success("Save Successfull.")
                  this.sqlLoading = false;
                  modal.componentInstance.loading = false;
                  modal.close();
                }
              }, 10000);

              let overTime = setTimeout(() => {
                this.notify.error("Process terminated due to timeout.");
                clearInterval(callback); 
                this.isBtnLoading = false;
                this.sqlLoading = false;
                modal.componentInstance.loading = false;
                modal.close();
              }, 60000);
              return;
            }

            const result = await this.api.post('/task/project/entry/save', {data: dt, type: "invoice", convertFromType: data.entry_type})
            this.isBtnLoading = false;
            if(!result.status){
              return this.notify.error(result.message)
            }

            this.notify.success("Save Successfull.")
            modal.close();
          }
        })  
      break;

      case "cancelComplete":

        let cancelModal:any = this.modalService.open(TaskProjectCompleteCancelComponent, {ariaLabelledBy: 'modal-task-project-cancel-confirm', backdrop: 'static', size: 'xl', keyboard: false, centered: true})
        cancelModal.componentInstance.setData = data;
        cancelModal.componentInstance.closeEmitter.subscribe(async (e:any) => {
          cancelModal.close()
          if(!e){
            this.isBtnLoading = false;
            return;
          }

          const result = await this.api.post("/task/task-progress/cancel/complete", data)
          if(!result.status){
            this.isBtnLoading = false;
            this.notify.error(result.message)
            return;
          }
    
          this.notify.success("Save Successfull.")
          this.isBtnLoading = false;
          this.getSchedule()
        })
      break;
      
      case "sendSurveyForm":
          await this.api.post("/task/task-progress/form/customer-feedback", data);
          this.notify.success("Survey Form is Sent.")
      break;

      case "postToGrab": 
        const result = await this.api.post("/task/save/project/grab", data)
        if(!result.status){
          this.isBtnLoading = false;
          this.notify.error(result.message)
          return;
        }

        this.notify.success("Project post successful.")
        this.isBtnLoading = false;
        this.getSchedule()
      break;

      case "cancelAssign":
        this.notify.confirm("Are you sure you want to delete?", async (rtn?:any) =>{
          await this.api.post("/task/task-progress/cancel/assign", data);
          delete data.assign_to;
          delete data.keyword;
          delete data.value;
          this.notify.success("Delete successful.");
        })

        this.isBtnLoading = false;
      break;
    }
  }

  async generateInquiry(data:any) {
    let modal = this.modalService.open(
      TaskProjectMaterialInquiryComponent, {ariaLabelledBy: 'modal-task-project-material-inquiry', backdrop: 'static', size: 'xl', keyboard: false, centered: true}
    );
    modal.componentInstance.setData = data;
    modal.componentInstance.closeEmitter.subscribe((e:any) => {
      modal.close();
    })
  }

  numSequence(n: number): Array<number> {
    return Array(n);
  }

  selectedPage(page:any){
    this.data.pages.current_page = page;
    this.getSchedule();
  }

  previous(){
    this.data.pages.current_page = this.data.pages.current_page == 1 ? 1 : this.data.pages.current_page - 1;
    this.getSchedule();
  }

  next(){
    this.data.pages.current_page = this.data.pages.current_page == this.numSequence(this.data.pages.total_page).length ? this.numSequence(this.data.pages.total_page).length : this.data.pages.current_page+1;
    this.getSchedule();
  }

  async getSearch() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {

      this.timeout = null;
      this.getSchedule()
    }, 1000);

  }

  onRowClick(idx:number) {
    this.selectedRowIdx = idx;
  }

  async dev_getCollection() {
    const result = await this.api.post('/task/dev/progress/get', this.data)
  }
}
