  <label [for]="field.mapping.key">{{field.title}} <em *ngIf="field.required">*</em>
    <ng-container *ngIf="field.descp">
      <br>
      <small class="text-muted">{{field.descp}}</small>
  </ng-container>
  </label>
  <div class="input-group mb-3">
    <ng-select class="form-control form-control-sm p-0 border-0" [(ngModel)]="selected" (click)="detectChanges($event)">
      <ng-option *ngFor="let option of options" [value]="option">{{option.name + ' (' + option.dial_code + ')' }}</ng-option>
    </ng-select>
    <input type="text" class="form-control form-control-sm" [id]="field.mapping.key" [(ngModel)]="data.contact" [disabled]="field.editable && field.editable == 0" (input)="onChangeInput()">  
  </div>

  <input [formControl]="ctrl" [type]="field.type" class="form-control form-control-sm" [id]="field.mapping.key" [disabled]="field.editable && field.editable == 0" hidden>

  