import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WarrantyCardComponent } from './warranty-card.component';

const routes: Routes = [
  {path: ":uuid", component: WarrantyCardComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WarrantyCardRoutingModule { }
