<div class="modal-body">
    <h5><b>Project Progress Filter</b></h5>
    <hr>
    <div class="form-group">
        <label>Assign To</label>
        <div class="input-group">
            <div class="search-dropdown flex-grow-1" [ngClass]="{'open':shown && userList !== undefined && userList?.length !== 0}">
                <div class="search-dropdown__btn" (click)="show()"><span>{{value}}</span></div>
                <div class="search-dropdown__dropdown">
                    <input class="search-dropdown__dropdown__search" type="text" placeholder="Search" [(ngModel)]="keyword" name="keyword" (keyup)="search(keyword)" #input/>
                    <input type="hidden" [id]="'assign_to'" [name]="'assign_to'" class="form-control" [value]="data.assign_to"/>
                    <div class="search-dropdown__dropdown__scroller">
    
                        <div class="search-dropdown__dropdown__item" (click)="select('UNASSIGNED', 'unassigned')">UNASSIGNED</div>
                        <div class="search-dropdown__dropdown__item" *ngFor="let ee of users" (click)="select('name', 'user_id', ee)">{{ee.name}}</div>
                        <div class="search-dropdown__dropdown__msg" *ngIf="userList?.length == 0">No Result Found</div>
                    </div>
                </div>
            </div>
            <button class="btn btn-outline-dark" (click)="clear_assignTo()">Clear</button>
        </div>
    </div>

    <div class="form-group">
        <label>Start Date</label>
        <input class="form-control" type="date" [(ngModel)]="data.start_work_date" name="start_work_date">
    </div>

    <div class="form-group">
        <label>Start Date Sorting</label>
        <select class="form-control" [(ngModel)]="data.start_date_sort" name="start_date_sort">
            <option [value]="'ascending'">Ascending</option>
            <option [value]="'descending'">Descending</option>
        </select>
    </div>
    <div class="form-group">
        <label>Status</label>
        <ng-multiselect-dropdown
            [placeholder]="''"
            [settings]="dropdownSettings"
            [data]="statusOption"
            [(ngModel)]="data.work_status"
            (onSelect)="onItemSelect($event)"
            (onSelectAll)="onSelectAll($event)"
            (onDeSelect)="onDeSelect($event)"
            (onDeSelectAll)="onDeSelectAll($event)">
        </ng-multiselect-dropdown>
    </div>

    <div class="form-group">
        <label>Deposit Received</label>
        <ng-multiselect-dropdown
            [placeholder]="''"
            [settings]="dropdownSettings"
            [data]="depositStatusOptions"
            [(ngModel)]="data.deposit_status"
            (onSelect)="onItemSelect($event)"
            (onSelectAll)="onSelectAll($event)"
            (onDeSelect)="onDeSelect($event)"
            (onDeSelectAll)="onDeSelectAll($event)">
        </ng-multiselect-dropdown>
    </div>

    <div class="form-group">
        <label>QTT Signed Status</label>
        <ng-multiselect-dropdown
            [placeholder]="''"
            [settings]="dropdownSettings"
            [data]="qttSignedStatusOptions"
            [(ngModel)]="data.quotation_signed_status"
            (onSelect)="onItemSelect($event)"
            (onSelectAll)="onSelectAll($event)"
            (onDeSelect)="onDeSelect($event)"
            (onDeSelectAll)="onDeSelectAll($event)">
        </ng-multiselect-dropdown>
    </div>

    <div class="form-group">
        <label>M.O. Status</label>
        <ng-multiselect-dropdown
            [placeholder]="''"
            [settings]="dropdownSettings"
            [data]="moStatusOptions"
            [(ngModel)]="data.mo_status"
            (onSelect)="onItemSelect($event)"
            (onSelectAll)="onSelectAll($event)"
            (onDeSelect)="onDeSelect($event)"
            (onDeSelectAll)="onDeSelectAll($event)">
        </ng-multiselect-dropdown>
    </div>

    <div class="form-group">
        <label>Insurance Status</label>
        <ng-multiselect-dropdown
            [placeholder]="''"
            [settings]="dropdownSettings"
            [data]="insuranceStatusOptions"
            [(ngModel)]="data.insurance_status"
            (onSelect)="onItemSelect($event)"
            (onSelectAll)="onSelectAll($event)"
            (onDeSelect)="onDeSelect($event)"
            (onDeSelectAll)="onDeSelectAll($event)">
        </ng-multiselect-dropdown>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-success btn-sm w-100 mt-2" (click)="submit()">Submit</button>
    <button class="btn btn-danger btn-sm w-100 mt-2" (click)="closeModal()">Cancel</button>
</div>


