import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TaskStockReportComponent } from './task-stock-report.component';

const routes: Routes = [
  {path: "", component: TaskStockReportComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TaskStockReportRoutingModule { }
