<div class="row">
    <div class="col-12 col-sm-12 col-md-6">
        <div class="card card-shadow">
            <div class="card-body p-md-5">
                <h2 class="text-center mb-4"><b>WARRANTY CARD</b></h2>
                <div class="row">
                    <div class="col-3"><p class="mb-2 d-flex justify-content-between"><span>Branch</span> <span>:</span></p></div>
                    <div class="col-9"><p class="mb-2 bottom-line">{{data.branch_name}}</p></div>
                </div>
                <div class="row">
                    <div class="col-3"><p class="mb-2 d-flex justify-content-between"><span>Invoice No</span> <span>:</span></p></div>
                    <div class="col-9"><p class="mb-2 bottom-line">{{data.invoice_no}}</p></div>
                </div>
                <div class="row">
                    <div class="col-3"><p class="mb-2 d-flex justify-content-between"><span>Customer Code</span> <span>:</span></p></div>
                    <div class="col-9"><p class="mb-2 bottom-line">{{data.customer_code}}</p></div>
                </div>
                <div class="row">
                    <div class="col-3"><p class="mb-2 d-flex justify-content-between"><span>Customer Name</span> <span>:</span></p></div>
                    <div class="col-9"><p class="mb-2 bottom-line">{{data.customer_name}}</p></div>
                </div>
                <div class="row">
                    <div class="col-3"><p class="mb-2 d-flex justify-content-between"><span>Customer Address</span> <span>:</span></p></div>
                    <div class="col-9"><p class="mb-2 bottom-line">{{data.address_1}}, {{data.address_2}}, {{data.postcode}} {{data.area}}, {{data.state}}, {{data.country}}</p></div>
                </div>
                <div class="row">
                    <div class="col-3"><p class="mb-2 d-flex justify-content-between"><span>Start Date</span> <span>:</span></p></div>
                    <div class="col-3"><p class="mb-2 bottom-line">{{data.start_date | date: 'yyyy-MM-dd'}}</p></div>
                    <div class="col-3"><p class="mb-2 d-flex justify-content-between"><span>End Date</span> <span>:</span></p></div>
                    <div class="col-3"><p class="mb-2 bottom-line">{{data.end_date | date: 'yyyy-MM-dd'}}</p></div>
                </div>
                <div class="row">
                    <div class="col-3"><p class="mb-2 d-flex justify-content-between"><span>Warranty Period</span> <span>:</span></p></div>
                    <div class="col-9"><p class="mb-2 bottom-line">{{data.period}} (YEAR)</p></div>
                </div>
            </div>
        </div>
    </div>
</div>