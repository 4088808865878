import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-task-map-detail',
  templateUrl: './task-map-detail.component.html',
  styleUrls: ['./task-map-detail.component.css']
})
export class TaskMapDetailComponent implements OnInit {

  @Input() data:any;
  @Output() close = new EventEmitter();
  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  closeCanvas() {
    this.close.emit(true)
  }

  viewDocument(type:any) {    
    this.close.emit(true)
    this.router.navigate(['/detail/task', this.data.task_uuid, 'list', type, this.data.task_uuid])
  }

  view(){
    this.close.emit(true)
    this.router.navigate(['/detail/customer', this.data.uuid, 'form', 'customer-list', this.data.uuid])
  }

  viewProject() {
    this.close.emit(true)
    this.router.navigate(['/detail/task', this.data.task_uuid, 'extend', 'task-progress', this.data.task_uuid])
  }

  getDirection(type:any) {
    let url;
    switch(type) {
      case "waze":
        url = "https://waze.com/ul?ll="+this.data.latitude+","+this.data.longitude;
      break;
      case "google":
        url = "https://maps.google.com/?q=" + this.data.latitude + "," + this.data.longitude;     
      break;
    }
    window.open(url);
  }

  viewProvidedLink() {
    window.open(this.data.address_link)
  }

}
