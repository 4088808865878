import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LexiNotifyService } from 'lexi-notify';

@Component({
  selector: 'lib-task-project-convert',
  templateUrl: './task-project-convert.component.html',
  styleUrls: ['./task-project-convert.component.css']
})
export class TaskProjectConvertComponent implements OnInit {

  @Input() set setType(val:any) {
    if(val){
      this.type = val;
      if(this.type == 'live_convert'){
        let today = new Date()
        this.data.inv_date = today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-"  + ("0" + today.getDate()).slice(-2);
      }
    }
  }

  @Input() loading:any;

  @Output() dataEmitter = new EventEmitter();

  public data:any = {};
  public type:any = "manual_convert";

  constructor(private notify: LexiNotifyService) { }

  ngOnInit(): void {
  }

  async submit() {
    if(this.type == 'manual_convert' && !this.data.inv_no){
      return this.notify.warning("Invoice no cannot be null or empty.")
    }

    if(this.type == 'live_convert' && !this.data.inv_date){
      return this.notify.warning("Invoice create date cannot be null or empty.")
    }

    this.dataEmitter.emit(this.data)
  }

  closeModal() {
    this.dataEmitter.emit(false)
  }
}
