import { Component, OnInit } from '@angular/core';
import { ApiService } from 'lexi-api'

@Component({
  selector: 'lib-task-costing-exceed',
  templateUrl: './task-costing-exceed.component.html',
  styleUrls: ['./task-costing-exceed.component.css']
})
export class TaskCostingExceedComponent implements OnInit {

  public collections:any = [];
  public isLoading:boolean = false;
  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.init()
  }

  async init(){
    this.isLoading = true;
    this.collections = await this.api.post("/task/get/project/exceed", {})
    console.log("______rtn", this.collections)
    this.isLoading = false;
  }

}
