<div class="mb-4 d-flex justify-content-end">
    <button *ngIf="data.child_status === 'pending_prepare_doc'" class="btn btn-primary me-2">Generate Invoice</button>
    <button class="btn btn-primary" [routerLink]="[ '/task-map/location', data.address.latitude, data.address.longitude ]">View Location</button>
</div>

<div class="mb-4 border-bottom pb-2 d-flex flex-row align-items-stretch">
    <div class="col text-center align-items-center d-flex justify-content-start flex-column progress-wrapper" [ngClass]="{'active': data.status_sort >= status.sort}" *ngFor="let status of mainStatus; let i = index">
        <div class="position-absolute border-primary border rounded-5 vertical-center progress-indicator" >{{i + 1}}</div>
        <small [title]="status.title">{{status.title}}</small>
    </div>
</div>

<div class="row">
    <div class="col-12 col-md-6">
        <ng-template #navViews1></ng-template>
    </div>
    <div class="col-12 col-md-6">
        <ng-template #navViews2></ng-template>
    </div>
</div>