<nav class="d-flex justify-content-between mb-3">
    <h4 class="m-0">Pending Grab Project</h4>
</nav>

<div class="card">
    <div class="card-body p-0">
        <ng-container *ngIf="isLoading">
            <lib-bar></lib-bar>
        </ng-container>

        <div class="table-responsive" *ngIf="!isLoading">
            <table class="table table-sm table-hover table-striped" style="font-size: 80%; white-space:nowrap">
                <thead class="thead-dark thead-sticky">
                    <tr>
                        <th scope="col" style="position: sticky; left: 0; width:33px; z-index: 3;">No</th>
                        <th scope="col" style="position: sticky; left: 33px; width: 87px; z-index: 3;">ERP Doc No / SQL Doc No</th>
                        <th scope="col" style="position: sticky; left: 120px; z-index: 3;">Customer</th>
                        <th scope="col">Area</th>
                        <th scope="col">Status</th>
                        <th scope="col">Net Total</th>
                        <th scope="col">Invoice No</th>
                        <th scope="col">Type of Work</th>
                        <th scope="col">Start Date</th>
                        <th scope="col">Working Day</th>
                        <th scope="col">Manpower</th>
                        <th scope="col">Remark</th>
                        <th scope="col">Deposit Received</th>
                        <th scope="col">Deposit Received Month</th>
                        <th scope="col">Payment Remark</th>
                        <th scope="col">Qtt Signed</th>
                        <th scope="col">M.O Status</th>
                        <th scope="col">Insurance</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let list of listing; let i = index;" [class.selected]="(i+1)=== selectedRowIdx" (click)="onRowClick(i+1)">
                        <td style="position: sticky; left: 0; width:33px; background:#eee; z-index: 3;">{{i+1}}</td>
                        <td style="position: sticky; left: 33px; width: 87px; background:#eee; z-index: 3;">
                            <span>{{list.doc_no}} <br></span>
                            <small *ngIf="list.sql_reference_no" class="text-primary"><b>(SQL)</b> {{list.sql_reference_no}}<br></small>
                            <span class="badge badge-warning">{{list.is_additional == 0 ? 'MAIN' : "VO"}}</span>
                        </td>
                        <td style="position: sticky; left: 120px; width:auto;  background:#eee; z-index: 3;">
                            <span><small>Profile Code : </small> <b>{{list.customer.code}}</b></span><br>
                            <span><small>Contact Person : </small> <b>{{list.customer_entity.name}}</b></span><br>
                            <span><small>Contact No : </small> <b>{{list.customer_entity.mobile}}</b></span>
                        </td>
                        <td>
                            <span>{{list.customer_address.area}}</span><br>
                            <span>{{list.customer_address.state}}</span>
                        </td>
                        <td>{{list.work_status}}</td>
                        <td><b>{{list.total_payable | number: '1.2-2'}}</b></td>
                        <td>{{list.invoice_no ?? '-'}}</td>
                        <td>{{list.type_of_work}}</td>
                        <td><b>{{list.start_work_date}}</b></td>
                        <td>{{list.notes.work_period ?? '-'}}</td>
                        <td>{{list.notes.man_prower ?? '-'}}</td>
                        <td>{{list.progress_remark}}</td>
                        <td>{{list.deposit_status}}</td>
                        <td>{{list.deposit_month}}</td>
                        <td>{{list.payment_remark}}</td>
                        <td>{{list.quotation_signed_status}}</td>
                        <td>{{list.mo_status}}</td>
                        <td>{{list.insurance_status}}</td>                        
                    </tr>
                </tbody>
            </table>
        </div>
        
    </div>
</div>