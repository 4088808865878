import { Injectable } from '@angular/core';
import { ApiService } from 'lexi-api';

@Injectable({
  providedIn: 'root'
})

export class WhbTaskService {

    public mainStatus:any = [];
    constructor(private apiService: ApiService) { }

    async getMainStatus() {
      if(this.mainStatus && this.mainStatus.length > 0){
        return this.mainStatus;
      }

      const result = await this.apiService.post('/task/get-status', {})
      this.mainStatus = result;
      return this.mainStatus;
    }

}
