import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { LexiCalendarComponent } from 'lexi-calendar'
import { ApiService } from 'lexi-api'
import { Router } from '@angular/router';

@Component({
  selector: 'lib-task-project-calendar',
  templateUrl: './task-project-calendar.component.html',
  styleUrls: ['./task-project-calendar.component.css']
})
export class TaskProjectCalendarComponent implements AfterViewInit {

  @ViewChild("lexiCalendar", {static: false}) lexiCalendar !: LexiCalendarComponent

  constructor(private api:ApiService, private router:Router) { }

  ngAfterViewInit(): void {
    this.init();
  }

  async init() {
    this.lexiCalendar.eventDateClick.subscribe((c:any) => {
      let date = new Date(parseInt(c.year), parseInt(c.month) - 1, parseInt(c.date))
    })

    this.lexiCalendar.eventMonthChange.subscribe((c) => {
      const year = c.year;
      const month = c.month + 1;
      this.initData(year, month);
    })

    const date = new Date();
    this.initData(date.getFullYear(), date.getMonth() + 1)
  }

  async initData(year:any = null, month:any = null) {
    if(!year) {
      let split = this.lexiCalendar.filterMonth.split("-")
      year = split[0]
      month = split[1]
    }

    let rtn = await this.api.post("/task/task-progress/calendar", {month: month, year: year})
    this.lexiCalendar.title = year + "-" + month;
    
    rtn.forEach((v:any) => {
      if(v.labels && v.labels.length > 0) {

        v.labels.forEach((y:any) => {
          y.onclick = () => {
            this.router.navigate(['/task-schedule/progress'], { queryParams: {doc_no: y.data.sql_reference_no ?? y.data.doc_no} })
          }
        })
      }
    })

    this.lexiCalendar.setCalendarData({year, month}, rtn)
    this.lexiCalendar.initCalendar();
    this.lexiCalendar.updateCalendarData();
  }
}
