import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'lexi-api';
import { TaskCostingConfirmationComponent } from './task-costing-confirmation/task-costing-confirmation.component';
import { LexiNotifyService } from 'lexi-notify';

@Component({
  selector: 'lib-task-costing',
  templateUrl: './task-costing.component.html',
  styleUrls: ['./task-costing.component.css']
})
export class TaskCostingComponent implements OnInit {

  @Input() set parent_uuid(val : any) {
    this.uuid = val;
    this.init();
    // this.initCost()
  };

  public uuid:any;
  public data:any = {};
  public isLoading:boolean = false;
  public btn_isLoading:boolean = false;

  constructor(private api: ApiService, private modalService: NgbModal, private notify: LexiNotifyService) { }

  ngOnInit(): void {
  }

  async initCost() {
    this.isLoading = true;
    const rtn = await this.api.post('/costing/report/individual', {uuid: this.uuid})  
    console.log(rtn)

    this.isLoading = false
    if(!rtn.status){
      return;
    } 
    // this.data = rtn.data;
  }

  async init() {
    this.isLoading = true;
    this.data = await this.api.post('/costing/report/single', {uuid: this.uuid})   
    this.isLoading = false;
    console.log(this.data)
  }

  convertArrToSting(arr:any) {
    return arr.join(", ")
  }

  getWorkDay(targetArray:any) {
    return Math.max(...targetArray.map((obj:any) => obj['duration']))
  }

  async completeTask() {
    this.btn_isLoading = true;
    let modal:any = this.modalService.open(TaskCostingConfirmationComponent, {ariaLabelledBy: 'modal-task-costing-confirm', backdrop: 'static', size: 'lg', keyboard: false, centered: true})
    modal.componentInstance.setData = this.data;
    modal.componentInstance.dataEmitter.subscribe(async (val:any) => {
      if(!val){
        this.btn_isLoading = false;
        modal.close()
        return
      }

      const result = await this.api.post("/costing/report/complete", this.data)
      this.btn_isLoading = false;
      modal.close()
      
      if(!result.status){
        return this.notify.error(result.message)
      }
      this.notify.success("Costing save successfully.")
    })
  }
}
