import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TaskWorkerRateFormRoutingModule } from './task-worker-rate-form.routing.module';
import { BarComponent } from 'whb-loading';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TaskWorkerRateFormComponent } from './task-worker-rate-form.component';


@NgModule({
  declarations: [
    TaskWorkerRateFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    NgbNavModule,
    TaskWorkerRateFormRoutingModule,
    BarComponent
  ],
  exports: [
  ]
})
export class TaskWorkerRateFormModule { }
