import { Component, OnInit } from '@angular/core';
import { ApiService } from 'lexi-api';
import { LexiNotifyService } from 'lexi-notify';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lib-task-pr-preparation',
  templateUrl: './task-pr-preparation.component.html',
  styleUrls: ['./task-pr-preparation.component.css']
})
export class TaskPrPreparationComponent implements OnInit {

  public data:any = {current_page: 1}
  public pendingListing:any = [];
  public completeListing:any = [];
  public currentActive:any = "pending";
  public isLoading:Boolean = false;
  public search:any = ""
  private timeout:any = null;

  constructor(private api: ApiService, private notify: LexiNotifyService) { }

  ngOnInit(): void {
    this.getPreparationList();
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.currentActive = changeEvent.nextId;
    this.getPreparationList()
	}

  async getPreparationList() {
    this.isLoading = true;
    this.data.type = this.currentActive;
    const data = await this.api.post('/task/task-pending-preparation/get', {...this.data, search_data: this.search})
    this.pendingListing = data.pendingCollections.collections;
    this.completeListing = data.completeCollections.collections;
    this.data = data.completeCollections.pages;

    this.isLoading = false;
  }

  async updatePreparationItem(type:any, data:any) {
    const result = await this.api.post('/task/task-pending-preparation/update', {type, entry_uuid: data.uuid})
    if(!result.status){
      return this.notify.error(result.message)
    }

    this.notify.success('Update successful.')
    this.getPreparationList();
  }

  numSequence(n: number): Array<number> {
    return Array(n);
  }

  selectedPage(page:any){
    this.data.current_page = page;
    this.getPreparationList();
  }

  previous(){
    this.data.current_page = this.data.current_page == 1 ? 1 : this.data.current_page - 1;
    this.getPreparationList();
  }

  next(){
    this.data.current_page = this.data.current_page == this.numSequence(this.data.total_page).length ? this.numSequence(this.data.total_page).length : this.data.current_page+1;
    this.getPreparationList();
  }

  async getSearch() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {

      this.timeout = null;
      // if(this.search.length == 0){
      //   return;
      // }
      this.getPreparationList()
    }, 1000);

  }
}
