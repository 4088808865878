import { Component, OnInit, Input, AfterViewInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ApiService } from 'lexi-api';
import { WhbTaskService } from '../whb-task.service';

@Component({
  selector: 'lib-progress-dashboard',
  templateUrl: './progress-dashboard.component.html',
  styleUrls: ['./progress-dashboard.component.css']
})
export class ProgressDashboardComponent implements OnInit, AfterViewInit {

  @Input() set task_uuid(val : any) {
    this.uuid = val;
  };

  @ViewChild("navViews1", {read: ViewContainerRef}) navViews1!: ViewContainerRef;
  @ViewChild("navViews2", {read: ViewContainerRef}) navViews2!: ViewContainerRef;


  public uuid:any;
  public mainStatus:any = [];
  public data:any = {};

  constructor(private apiService: ApiService, private taskService: WhbTaskService) { 
    this.data.address = {};
  }

  ngOnInit(): void {
  
  }

  ngAfterViewInit(): void {
    this.initData();
    this.loadNav();
  }

  async initData() {
    this.mainStatus = await this.taskService.getMainStatus();
    console.log("check mainStatus: ", this.mainStatus)
    this.getTask();
  }

  async getTask() {
    this.data = await this.apiService.post('/task/get-task-detail', {task_uuid: this.uuid})
  }
 
  async loadNav() {
    this.navViews1.clear();
    let component1 = ((await import("lexi-nav")).TabsComponent);
    const tabsComponent1 = this.navViews1.createComponent(component1);
          tabsComponent1.instance.identifier = "task-progress-detail";
          tabsComponent1.instance.parent_uuid = this.uuid;

    this.navViews2.clear();
    let component2 = ((await import("lexi-nav")).TabsComponent);
    const tabsComponent2 = this.navViews2.createComponent(component2);
          tabsComponent2.instance.identifier = "task-progress-work-order";
          tabsComponent2.instance.parent_uuid = this.uuid;
          tabsComponent2.instance.closeTab.subscribe(($e:any) => {
            this.getTask()
            this.loadNav()
          })
  }
}
