<div class="d-flex flex-row justify-content-between pb-2 align-items-center">
    <h4 class="m-0">Stock Transaction Management</h4>
    <div class="d-flex justify-content-end">
        <button class="btn btn-success btn-sm ms-2" (click)="exportCSV()" [disabled]="!collections || collections.length == 0">Export CSV</button>
        <button class="btn btn-primary btn-sm ms-2" [routerLink]="['/detail/stock', uuid, 'extend', 'stock-transaction']">&#43; Add New</button>
    </div>
</div>
<div class="d-flex mb-3 w-100">
    <div class="flex-grow-1 me-2">
        <input 
            class="form-control form-control-sm w-100" 
            type="text" 
            placeholder="Search on invoice no, leader name or supplier" 
            [(ngModel)]="filterData.search" 
            name="search" 
            appInputMonitor 
            (ngModelChange)="getSearch()">
    </div>
    <div>
        <button class="btn btn-dark btn-sm w-100" (click)="openFilter()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="16" height="16" viewBox="0 0 24 24"><path d="M1 0h22l-9 14.094v9.906l-4-2v-7.906z"></path></svg> 
            Filter
        </button>
    </div>
</div>



<ng-container *ngIf="isLoading">
    <lib-bar></lib-bar>
</ng-container>
<div class="table-responsive" *ngIf="!isLoading">
    <table class="table table-sm table-hover table-striped table-bordered" style="font-size: 80%; white-space:nowrap">
        <thead class="thead-primary thead-sticky">
            <tr>
                <th scope="col" style="position: sticky; left: 0; width:33px; z-index: 3; background: var(--primary);">No</th>
                <th scope="col" style="position: sticky; left: 33px; width: 87px; z-index: 3; background: var(--primary);">Date</th>
                <th scope="col" style="position: sticky; left: 120px; z-index: 3; background: var(--primary);">Item Name</th>
                <th scope="col">Leader Name</th>
                <th scope="col">Invoice No</th>
                <th scope="col">U/Price</th>
                <th scope="col">Stock In</th>
                <th scope="col">Stock Out</th>
                <th scope="col">Return</th>
                <th scope="col">Expired</th>
                <th scope="col">Location</th>
                <th scope="col">Supplier</th>
                <th scope="col">Supplier Invoice No</th>
                <th scope="col">Balance</th>
                <th scope="col">Stock Closing Amount</th>
                <th scope="col">License</th>
                <th scope="col">License Expired</th>
                <th scope="col">Remark</th>
                <th scope="col"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let collect of collections; let i = index;">
                <td style="position: sticky; left: 0; width:33px; background:#eee; z-index: 3;">{{i+1}}</td>
                <td style="position: sticky; left: 33px; width: 87px; background:#eee; z-index: 3;">{{collect.date | date: "dd-MM-yyyy"}}</td>
                <td style="position: sticky; left: 120px; width:auto;  background:#eee; z-index: 3;">{{collect.item_name ?? "-"}}</td>
                <td>{{collect.name ?? "-"}}</td>
                <td>{{collect.reference_no ?? "-"}}</td>
                <td>{{collect.rate | number: '1.2-2'}}</td>
                <td><span *ngIf="collect.type !== 'RETURN'">{{collect.debit}}</span></td>
                <td><span *ngIf="collect.type !== 'EXPIRED'">{{collect.credit}}</span></td>
                <td><span *ngIf="collect.type == 'RETURN'">{{collect.debit}}</span></td>
                <td><span *ngIf="collect.type == 'EXPIRED'">{{collect.credit}}</span></td>
                <td>{{collect.location ?? "-"}}</td>
                <td>{{collect.supplier ?? "-"}}</td>
                <td>{{collect.invoice_no ?? "-"}}</td>
                <td>{{collect.balance}}</td>
                <td class="text-end">{{collect.balance_amount | number: '1.2-2'}}</td>
                <td>{{collect.license}}</td>
                <td>{{collect.license_expiry_date}}</td>
                <td>{{collect.remark ?? "-"}}</td>
                <td class="p-0">
                    <button class="btn btn-link btn-sm" (click)="editData(collect)">Edit</button>
                </td>
            </tr>
        </tbody>
        <tfoot class="bg-dark text-white tfoot-sticky">
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-end fw-semibold">{{grandTotal | number: "1.2-2"}}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </tfoot>
    </table>
</div>