import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'lexi-api';
import { LexiNotifyService } from 'lexi-notify';
import { PDFService } from 'lexi-pdf'; 
import { DomSanitizer } from '@angular/platform-browser'; 
import { Router } from '@angular/router';

@Component({
  selector: 'lib-task-budget',
  templateUrl: './task-budget.component.html',
  styleUrls: ['./task-budget.component.css']
})
export class TaskBudgetComponent implements OnInit {

  @Input() set parent_uuid(val : any) {
    this.uuid = val;
  };

  @Input() set data_uuid(val : any) {
    this.dataUUID = val;
    if(this.dataUUID){
      this.type = "EXIST"
    }
  };

  public editable:boolean = false;
  public isLoading:boolean = false;
  public submitLoading:boolean = false;

  public type:any = 'NEW'
  public uuid:any;
  public header:any;
  public footer:any;
  public branches:any = [];
  public bheader: any;
  public dataUUID:any;
  public project:any = {address: {}, detail:{}};
  public documents:any = {quotation: [], proforma: []};
  public inventories:any = [];
  public costs:any = [{}];
  public discount:any = {dis10: {amount: 0, balance: 0, disValue: 0, profit: 0}, dis20: {amount: 0, balance: 0, disValue: 0, profit: 0}, dis30: {amount: 0, balance: 0, disValue: 0, profit: 0}};
  public data:any = {};
  public workmanship:any = [{}];
  public others:any = []

  public previewPDF:any;

  constructor(private api: ApiService, private notify: LexiNotifyService, private pdf: PDFService, private sanitizer: DomSanitizer, private router: Router) { 
    this.data.totalHiddenCost = 0;
    this.data.totalCostAmt = 0;
    this.data.totalCustAmt = 0;
    this.data.totalDocAmt = 0;
    this.data.totalWorkmanCostAmt = 0;
    this.data.totalBudgetCost = 0;
    this.data.totalDifferences = 0;
    this.data.totalProfit = 0
    this.data.membrane = {measurement: 0, qty: 0, rate: 220, total: 0}
    this.data.bitumen = {measurement: 0, qty: 0, rate: 120, total: 0}
  }

  ngOnInit(): void {
    this.initData();
  }

  async initData() {
    this.inventories = await this.api.post("/setting/get/inventory", {})
    this.workmanship = await this.api.post("/setting/get/fixed/inventory", {})
    this.documents = await this.api.post("/task/get/project/document", {task_uuid: this.uuid})
    this.project = await this.api.post('/task/get-task-detail', {task_uuid: this.uuid, child_status: ['pending_confirm','pending_site_inspec', 'quote_issued_from_aftersales']})
    console.log(this.project)

    this.getBudget();
  }

  async getBudget() {
    this.isLoading = true;
    let result = await this.api.post("/task/get/project/budget-cost", {task_uuid: this.uuid, data_uuid: this.dataUUID})
    if(result.data){
      this.data = result.data;
      
      if(!this.data.membrane_cost){
        this.data.membrane = {measurement: 0, qty: 0, rate: 220, total: 0}
      }else{
        this.data.membrane = this.data.membrane_cost
      }

      if(!this.data.bitumen_cost){
        this.data.bitumen = {measurement: 0, qty: 0, rate: 120, total: 0}
      }else{
        this.data.bitumen = this.data.bitumen_cost
      }
      
      this.costs = result.data.cost;
      this.discount = result.data.discount;
      this.workmanship = result.data.workmanship;
      this.others = result.data.others;
    }else{
      this.others = []
      this.others.push({descp: "Administration", cost_measurement: 1, project_period: 1, cost_rate: 3, rate_type: "percent", percent_from: "totalDocAmt", cost_amt: 0})
      this.others.push({descp: "Sales Commission", cost_measurement: 1, project_period: 1, cost_rate: 5, rate_type: "percent", percent_from: "totalCustAmt", cost_amt: 0})
    }

    this.isLoading = false;
  }

  addRow() {
    this.costs.push({})
  }

  removeRow(index:any) {
    this.costs.splice(index, 1)
    this.summaryMaterialCost()
  }

  addWorkmanship() {
    this.workmanship.push({})
  }

  removeWorkmanship(index:any) {
    this.workmanship.splice(index, 1)
    this.summaryWorkmanshipCost()
  }

  async retrieveInventoryData(data:any, key:any, $e:any) {
    let inventory =this.inventories.filter((item:any) => {
      return item[key] == $e
    })

    data['code'] = inventory[0]['code']
    data['name'] = inventory[0]['name']
    data['cost_rate'] = parseFloat(inventory[0]['rate'])
    data['rate_type'] = inventory[0]['rate_type'];

    this.calculate(data)
    return data;
  }

  calculate(data:any){
    if(!data['cost_measurement'] || data['cost_measurement'] < 0){
      data['cost_measurement'] = 1
    }

    if(data['rate_type'] == 'percent'){
      data['cost_amt'] = this.data.totalCustAmt * data['cost_rate'] / 100
      data['cost_amt'] = parseFloat(data['cost_amt']).toFixed(2)
  
    }else{
      data['cost_amt'] = (data['cost_measurement'] ?? 0) * data['cost_rate'] 
      data['cost_amt'] = parseFloat(data['cost_amt']).toFixed(2)
    }

    data['cust_rate'] = (data['amount'] ?? 0) / (data['cost_measurement'] ?? 0)
    data['cust_rate'] = parseFloat(data['cust_rate']).toFixed(2)

    this.summaryMaterialCost()
  }

  summaryMaterialCost() {
    let totalCost = 0;
    let totalCust = 0;
    this.data.membrane.measurement = 0;
    this.data.bitumen.measurement = 0;
    for(let x = 0; x < this.costs.length; x++){

      let current = this.costs[x];

      console.log(x, current)
      console.log(this.data.membrane.measurement, this.data.bitumen.measurement)
      if(current?.name.toLowerCase().includes("membrane")) {
        this.data.membrane.measurement += parseFloat(this.costs[x]['cost_measurement']);
      }

      if(current?.name.toLowerCase().includes("bitumen")) {
        this.data.bitumen.measurement += parseFloat(this.costs[x]['cost_measurement']);
      }
      console.log(this.data.membrane.measurement, this.data.bitumen.measurement)

      totalCost += parseFloat(this.costs[x]['cost_amt'] ?? 0);
      // totalCust += parseFloat(this.costs[x]['amount'])
      totalCust += parseFloat(this.costs[x]['amount'] ?? 0)
    }

    let membrane_qty = (this.data.membrane.measurement % 80) / 80;
    if(membrane_qty <= 0){
      this.data.membrane.qty = Math.round(this.data.membrane.measurement / 80)
    }else{
      this.data.membrane.qty = Math.ceil((this.data.membrane.measurement / 80) + 1)
    }
    this.data.membrane.total = this.data.membrane.qty * this.data.membrane.rate;



    let bitumen_qty = (this.data.bitumen.measurement % 1600) / 1600;
    if(bitumen_qty <= 0){
      this.data.bitumen.qty = Math.round(this.data.bitumen.measurement / 1600)
    }else{
      this.data.bitumen.qty = Math.ceil(this.data.bitumen.measurement / 1600)
    }
    this.data.bitumen.total = this.data.bitumen.qty * this.data.bitumen.rate;

    totalCost += this.data.membrane.total;
    totalCost += this.data.bitumen.total;

    this.data.totalHiddenCost = totalCost * 0.20;

    this.data.totalCostAmt = totalCost + this.data.totalHiddenCost;
    this.data.totalCustAmt = totalCust;

    this.calculateRetriveOther();
    this.summarySUM();
  }

  cutomCalculate(type: any){
    let totalCost = 0;
    let totalCust = 0;
    this.data.membrane.measurement = 0;
    this.data.bitumen.measurement = 0;

    for(let x = 0; x < this.costs.length; x++){
      let current = this.costs[x];

      if(current?.name.toLowerCase().includes("membrane")) {
        this.data.membrane.measurement += parseFloat(this.costs[x]['cost_measurement'] ?? 1);
      }

      if(current?.name.toLowerCase().includes("bitumen")) {
        this.data.bitumen.measurement += parseFloat(this.costs[x]['cost_measurement'] ?? 1);
      }

      totalCost += parseFloat(this.costs[x]['cost_amt'] ?? 0);
      totalCust += parseFloat(this.costs[x]['amount'] ?? 0)
    }

    if(this.data[type].qty < 0){
      this.data.membrane.qty = 0;
    }

    this.data.membrane.total = this.data.membrane.qty * this.data.membrane.rate;
    this.data.bitumen.total = this.data.bitumen.qty * this.data.bitumen.rate;

    totalCost += this.data.membrane.total;
    totalCost += this.data.bitumen.total;

    this.data.totalHiddenCost = totalCost * 0.20;

    this.data.totalCostAmt = totalCost + this.data.totalHiddenCost;
    this.data.totalCustAmt = totalCust;

    this.calculateRetriveOther();
    this.summarySUM();
  }

  summarySUM() {
    this.data.totalBudgetCost = this.data.totalCostAmt + this.data.totalWorkmanCostAmt;

    this.data.totalDifferences = this.data.totalDocAmt - this.data.totalBudgetCost;
    this.data.totalProfit = this.data.totalDifferences / this.data.totalDocAmt * 100;

    this.discount.dis10.amount = this.data.totalDocAmt * 0.90;
    this.discount.dis20.amount = this.data.totalDocAmt * 0.80;
    this.discount.dis30.amount = this.data.totalDocAmt * 0.70;

    this.discount.dis10.balance = this.discount.dis10.amount - this.data.totalBudgetCost;
    this.discount.dis20.balance = this.discount.dis20.amount - this.data.totalBudgetCost;
    this.discount.dis30.balance = this.discount.dis30.amount - this.data.totalBudgetCost;

    this.discount.dis10.profit = this.discount.dis10.balance / this.discount.dis10.amount * 100;
    this.discount.dis20.profit = this.discount.dis20.balance / this.discount.dis20.amount * 100;
    this.discount.dis30.profit = this.discount.dis30.balance / this.discount.dis30.amount * 100;

    this.discount.dis10.disValue = this.data.totalDocAmt - this.discount.dis10.amount;
    this.discount.dis20.disValue = this.data.totalDocAmt - this.discount.dis20.amount;
    this.discount.dis30.disValue = this.data.totalDocAmt - this.discount.dis30.amount;
  }

  async submit(type:any) {
    switch(type) {
      case "edit":
        this.editable = !this.editable;
        this.getBudget();
      break;
      case "save":
        this.submitLoading = true;

        this.data['cost'] = this.costs;
        this.data['discount'] = this.discount;
        this.data['workmanship'] = this.workmanship;
        this.data['others'] = this.others;

        const result = await this.api.post("/task/save/project/budget-cost", {task_uuid: this.uuid, data: this.data})
        this.submitLoading = false;
        
        if(!result.status){
          return this.notify.error(result.message)
        }

        this.notify.success("Successfull save.")

        this.dataUUID = result.uuid;
        this.initData();
        this.router.navigate(['/detail/task', this.uuid, "extend", "task-budget", result.uuid])

        this.type = 'EXIST';
        this.editable = false;
      break;
      case "print":
        this.editable = false;
        this.previewPDFTemplate();
      break;
    }
  }

  calculateRetriveWorkman() {
    for(let x = 0; x < this.workmanship.length; x++){
      this.calculateWorkman(this.workmanship[x])
    }
  }

  calculateWorkman(data:any){
    if(!data['rate_type']) {
      data['rate_type'] = 'fixed'
    }

    if(data['rate_type'] == 'fixed'){
      data['cost_amt'] = ((data['cost_measurement'] ?? 0) * data['project_period']) * (data['cost_rate'] ?? 0) 
    }else{
      data['cost_amt'] = (this.data[data.percent_from] * (data['cost_rate'] ?? 0) / 100) * (data['cost_measurement'] ?? 0) 
    }

    data['cost_amt'] = parseFloat(data['cost_amt'] ?? 0).toFixed(2)

    this.summaryWorkmanshipCost()
  }

  summaryWorkmanshipCost() {
    let totalWorkmanshipCost = 0;
    let totalOthersCost = 0;

    for(let x = 0; x < this.workmanship.length; x++){
      totalWorkmanshipCost += parseFloat(this.workmanship[x]['cost_amt']);
    }

    for(let y = 0; y < this.others.length; y++){
      totalOthersCost += parseFloat(this.others[y]['cost_amt']);
    }

    this.data.totalWorkmanCostAmt = totalWorkmanshipCost + totalOthersCost

    this.summarySUM();
  }

  calculateRetriveOther() {
    for(let x = 0; x < this.others.length; x++){
      this.calculateOthers(this.others[x])
    }
  }

  calculateOthers(data:any) {
    data['cost_measurement'] = 1

    if((data['descp']).toLowerCase() === 'administration'){
      data['cost_amt'] = this.data.totalDocAmt * data['cost_rate'] / 100

    }else if((data['descp']).toLowerCase() === 'sales commission') {
      data['cost_amt'] = this.data.totalCustAmt * data['cost_rate'] / 100

    }else{
      data['cost_amt'] = this.data.totalCustAmt * data['cost_rate'] / 100
    }

    data['cost_amt'] = parseFloat(data['cost_amt']).toFixed(2)

    this.summaryWorkmanshipCost()
  }

  getDocumentAmount() {
    let doc;
    if(!this.data.pr_id) {
      console.log("check no")
      doc = this.documents.quotation.filter((qtt:any) => qtt.id == this.data.qtt_id);
    }else{
      console.log("check yes")
      doc = this.documents.proforma.filter((pr:any) => pr.id == this.data.pr_id);
    }
    console.log("check doc[0]['total_payable']: ", doc[0]['total_payable'])
    this.data.totalDocAmt = doc[0]['total_payable']

    this.calculateRetriveWorkman()
    this.calculateRetriveOther()
  }

  async previewPDFTemplate() {
    let data = {budget: this.data, task: this.project}

    try{
      let content = await this.api.post("/preview/get/template", {type: "budgetCost", data: data})
      this.pdf.action(content, 'open');
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  }


  async previewPDFTemplate_DEMO() {    
    try{
      // let URL = "https://erp.waihongbrothers.com/pdf/preview/"+ this.dataUUID;
      let content = await this.api.get("/preview/app/get/template/budgetCost/"+ this.uuid +"/"+ this.dataUUID)

      var pdfWindow:any = window.open("");
      pdfWindow.document.write(
          "<iframe width='100%' height='100%' src='" + encodeURI(content) + "'></iframe>"
      );

    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  }

  async refresh() {
    await this.getDocumentAmount()
    await this.submit("save")
  }
}
