import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TaskBudgetReportRoutingModule } from './task-budget-report.routing.module';
import { BarComponent } from 'whb-loading';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TaskBudgetReportComponent } from './task-budget-report.component';


@NgModule({
  declarations: [
    TaskBudgetReportComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    NgbNavModule,
    TaskBudgetReportRoutingModule,
    BarComponent
  ],
  exports: [
  ]
})
export class TaskBudgetReportModule { }
