import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TaskWorkerRateRoutingModule } from './task-worker-rate.routing.module';
import { BarComponent } from 'whb-loading';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TaskWorkerRateComponent } from './task-worker-rate.component';


@NgModule({
  declarations: [
    TaskWorkerRateComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    NgbNavModule,
    TaskWorkerRateRoutingModule,
    BarComponent
  ],
  exports: [
  ]
})
export class TaskWorkerRateModule { }
