import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskMapComponent } from './task-map.component';
import { TaskMapRoutingModule } from './task-map.routing.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { FormsModule } from '@angular/forms';
import { TaskMapDetailComponent } from '../task-map-detail/task-map-detail.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  declarations: [
    TaskMapComponent,
    TaskMapDetailComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TaskMapRoutingModule,
    GoogleMapsModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  exports: [
  ]
})
export class TaskMapModule { }
