<nav class="d-flex justify-content-between mb-3">
    <h4 class="m-0">Aftercare Progress Detail</h4>
    <!-- <button class="btn btn-warning btn-lg" [routerLink]="['/task-schedule/progress/calendar']">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-week" viewBox="0 0 16 16">
            <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z"/>
            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
        </svg>
        <span class="ms-2">Progress Calendar</span>
    </button> -->
</nav>
<!-- <div class="d-flex mb-3">
    <input class="form-control form-control-sm w-100" type="text" placeholder="Search something... (ERP Doc No / SQL Doc No / Profile Code / Contact No / Invoice No)" [(ngModel)]="filterData.search" name="search" appInputMonitor (ngModelChange)="getSearch()">
    <button class="btn btn-primary btn-sm ms-2" style="align-self: flex-end;" (click)="getFilter()">Filter</button>
    <button class="btn btn-dark btn-sm ms-2" style="align-self: flex-end;" (click)="getReset()">Reset</button>
</div> -->

<ng-container *ngIf="isLoading">
    <lib-bar></lib-bar>
</ng-container>

<div class="table-responsive" *ngIf="!isLoading">
    <table class="table table-sm table-hover table-striped" style="font-size: 80%; white-space:nowrap">
        <thead class="thead-dark thead-sticky">
            <tr>
                <th scope="col" style="position: sticky; left: 0; width:33px; z-index: 3;">No</th>
                <th scope="col" style="position: sticky; left: 33px; width: 87px; z-index: 3;">Date</th>
                <th scope="col" style="position: sticky; left: 120px; z-index: 3;">Assign To</th>
                <th scope="col">Technician</th>
                <th scope="col">Total Manpower</th>
                <th scope="col">Car Plate</th>
                <th scope="col" style="min-width: 300px; white-space: pre-line;">Remark</th>
                <th scope="col">Quotation Need</th>
                <th scope="col">Criteria of Aftercare</th>
                <th scope="col">Descp of Defects</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let collect of collections; let i = index;">
                <td style="position: sticky; left: 0; width:33px; background:#eee; z-index: 3;">{{i+1}}</td>
                <td style="position: sticky; left: 33px; width: 87px; background:#eee; z-index: 3;">{{collect.aftercare_date | date: "dd-MM-yyyy"}}</td>
                <td style="position: sticky; left: 120px; width:auto;  background:#eee; z-index: 3;">{{collect.assign_technician}}</td>
                <td>
                    <span *ngFor="let tech of collect.technician; let last = last;">{{tech}} <br *ngIf="!last"></span>
                </td>
                <td>
                    <span *ngIf="collect.technician && collect.technician[0] === null"></span>
                    <span *ngIf="collect.technician && collect.technician.length > 0 && collect.technician[0] !== null">{{collect.technician.length}}</span>
                </td>
                <td>{{collect.plate_no}}</td>
                <td>{{collect.remark}}</td>
                <td>{{collect.require_quotation == 1 ? "YES" : "NO"}}</td>
                <td>{{collect.criteria_title}}</td>
                <td>{{collect.defect_title}}</td>
            </tr>
        </tbody>
    </table>
</div>