<div class="d-flex flex-row justify-content-between pb-2 align-items-center">
    <h4>Project Costing</h4>
    <h4 class="text-success" *ngIf="data.task_detail?.project_complete_status == 1"><b>COMPLETED</b></h4>
    <h4 class="text-secondary" *ngIf="data.task_detail?.project_complete_status == 0"><b>ON GOING</b></h4>
</div>

<ng-container *ngIf="isLoading">
    <lib-bar></lib-bar>
</ng-container>

<ng-container *ngIf="!isLoading">
    <table class="table table-sm table-striped table-hover table-bordered" style="font-size: 80%; white-space:nowrap">
        <thead class="table-dark">
            <tr>
                <th colspan="2">Job No</th>
                <th colspan="4">{{data.task_detail.reference_no}}</th>
                <th colspan="2" rowspan="2" class="text-center bd-left" style="background-color: darkblue;">BUDGET COST</th>
            </tr>
            <tr>
                <th colspan="2">Technician</th>
                <th colspan="4">
                    <span *ngIf="!data.task_progress || !data.task_progress.technician || data.task_progress.technician.length == 0">-</span>
                    <ng-container *ngFor="let tech of data.task_progress.technician; let isLast = last">
                        {{tech}}<span *ngIf="!isLast"> / </span>
                    </ng-container>
                </th>
            </tr>
        </thead>
        <tbody>
            <!-- Customer Info -->
            <tr>
                <th colspan="2">Customer Code</th>
                <td colspan="4">{{data.task_detail.cust_code}}</td>
                <td colspan="2" class="bd-left"></td>
            </tr>
            <tr>
                <th colspan="2">Customer Profile</th>
                <td colspan="4">{{data.task_detail.cust_profile}}</td>
                <td colspan="2" class="bd-left"></td>
            </tr>
            <tr>
                <th colspan="2">Billing Address</th>
                <td colspan="4" style="min-width: 300px; white-space: pre-line;">{{data.task_detail.cust_billing_address}}</td>
                <td colspan="2" class="bd-left"></td>
            </tr>
            <tr>
                <th colspan="2">Site Address</th>
                <td colspan="4" style="min-width: 300px; white-space: pre-line;">{{data.task_detail.cust_address}}</td>
                <td colspan="2" class="bd-left"></td>
            </tr>
            <tr>
                <th colspan="2">Attention To</th>
                <td colspan="4">{{data.task_detail.cust_billing_person}}</td>
                <td colspan="2" class="bd-left"></td>
            </tr>
            <tr>
                <th colspan="2">Contact Number</th>
                <td colspan="4">{{data.task_detail.cust_billing_contact}}</td>
                <td colspan="2" class="bd-left"></td>
            </tr>
    
    
            <!-- Document Info -->
            <tr>
                <th colspan="2">Invoice Number</th>
                <td colspan="4">
                    <span *ngIf="!data.task_inv || data.task_inv.length == 0">-</span>
                    <ng-container *ngFor="let inv of data.task_inv; let isFirst = first; let isLast = last">
                        {{inv.sql_reference_no}}<span *ngIf="!isLast && inv.sql_reference_no && inv.sql_reference_no.length > 0"> & </span>
                    </ng-container>
                </td>
                <td colspan="2" class="bd-left"></td>
            </tr>
            <tr>
                <th colspan="2">Quotation / PR Number</th>
                <td colspan="4">
                    <span *ngIf="data.task_pr.length == 0 && data.task_qtt.length == 0">-</span>
                    <ng-container *ngFor="let qtt of data.task_qtt; let isLast = last">
                        {{qtt.quotation ?? qtt.quotation_no}}<span *ngIf="!isLast"> & </span>
                    </ng-container>
                    <ng-container *ngFor="let pr of data.task_pr; let isLast = last">
                        {{pr.proforma_no}}<span *ngIf="!isLast"> & </span>
                    </ng-container>
                </td>
                <td colspan="2" class="bd-left"></td>
            </tr>
            <tr>
                <th colspan="2">Inspection Date</th>
                <td colspan="4">{{data.task_progress.inspection_date | date: "dd-MM-yyyy"}}</td>
                <td colspan="2" class="bd-left"></td>
            </tr>
            <tr>
                <th colspan="2">Project Working Date</th>
                <td colspan="4" style="min-width: 300px; white-space: pre-line;">
                    <span *ngFor="let period of data.task_progress.work_period; let i = index; let isLast = last">{{period.date}}{{!isLast ? ', ' : ''}}</span>
                </td>
                <td colspan="2" class="bd-left"></td>
            </tr>
            <tr>
                <th colspan="2">Days of Completion</th>
                <td colspan="4"> <!--{{data.task_progress.work_period.length}} --> {{getWorkDay(data.wages)}} DAYS</td>
                <td colspan="2" class="bd-left">{{data.budget.project_period}} DAYS</td>
            </tr>
            <tr>
                <th colspan="2">Payment Collection <em><small>**From SQL</small></em></th>
                <td colspan="4" style="min-width: 300px; white-space: pre-line;">
                    <span *ngIf="data.payment_collection.length == 0">-</span>

                    <ng-container *ngFor="let paid of data.payment_collection; let isLast = last">
                        {{paid.payment_no}} <span class="text-muted">(RM {{paid.amount | number: '1.2-2'}})</span>
                        <span *ngIf="!isLast"> & </span>
                    </ng-container>
                </td>
                <td colspan="2" class="bd-left"></td>
            </tr>
            <tr>
                <th colspan="2">Total Sales Amount (RM)</th>
                <td colspan="4">{{data.task_progress.total_amount | number: '1.2-2'}}</td>
                <td colspan="2" class="bd-left"></td>
            </tr>
            <ng-container *ngIf="!data.credit_note || data.credit_note.length == 0">
                <tr>
                    <th colspan="2">Credit Note (RM) <em><small>**From SQL</small></em></th>
                    <td class="text-right" style="width: 10%;"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2" class="bd-left"></td>
                </tr>
            </ng-container>
            <ng-container *ngIf="data.credit_note && data.credit_note.length > 0">
                <tr *ngFor="let cn of data.credit_note; let isFirst = first; let isLast = last">
                    <th colspan="2"><span *ngIf="isFirst">Credit Note (RM) <em><small>**From SQL</small></em></span></th>
                    <td class="text-right" style="width: 10%;"><b>{{cn.amount | number: '1.2-2'}}</b></td>
                    <td>{{cn.credit_note_no}}</td>
                    <td></td>
                    <td></td>
                    <td colspan="2" class="bd-left"></td>
                </tr>
            </ng-container>
            
            <!-- Cost Info -->
            <tr>
                <th colspan="2">Actual Sales Amount (RM)</th>
                <td class="text-right" style="width: 10%;"><b>{{data.task_progress.actual_amount | number: '1.2-2'}}</b></td>
                <td class="text-center" style="width: 8%;"><em><b>100.00 %</b></em></td>
                <td></td>
                <td></td>
                <td class="bd-left" style="width: 10%;"></td>
                <td style="width: 8%;"></td>
            </tr>
            <tr>
                <th>Insurance (RM)</th>
                <th>{{data.config_percent.insuranceFee_percent}}%</th>
                <td class="text-right">{{data.costing.insuranceFee | number: "1.2-2"}}</td>
                <td class="text-center"><em><b>{{data.figure_percent.insurancePercent | number: "1.2-2"}} %</b></em></td>
                <td></td>
                <td></td>
                <td class="bd-left text-right">{{data.budget.total_insurance | number: '1.2-2'}}</td>
                <td class="text-left"><em><b>{{data.budget.total_insurance_percent | number: "1.2-2"}} %</b></em></td>
            </tr>
            <tr>
                <th colspan="2">Skylift / Crane (RM) <em><small>**From SQL</small></em></th>
                <td class="text-right">{{data.skylift_crane.total | number: '1.2-2'}}</td>
                <td class="text-center"><em><b>{{data.figure_percent.skylift_cranePercent | number: '1.2-2'}}</b></em></td>
                <td colspan="2">
                    <span *ngIf="data.skylift_crane.collection.length == 0">-</span>
                    <ng-container  *ngIf="data.skylift_crane.collection.length > 0">
                        <span *ngFor="let collect of data.skylift_crane.collection; let isLast = last">
                            {{collect.supplier_name}}({{collect.invoice_no}})<span *ngIf="!isLast"> & </span>
                        </span>
                    </ng-container>
                </td>
                <td class="bd-left text-right">{{data.budget.total_skylift_crane | number: "1.2-2"}}</td>
                <td class="text-left"><em><b>{{data.budget.total_skylift_crane_percent | number: "1.2-2"}} %</b></em></td>
            </tr>
            <tr>
                <th colspan="2">Rorobin (RM) <em><small>**From SQL</small></em></th>
                <td class="text-right">{{data.rorobin.total | number: '1.2-2'}}</td>
                <td class="text-center"><em><b>{{data.figure_percent.rorobinPercent | number: '1.2-2'}}</b></em></td>
                <td colspan="2">
                    <span *ngIf="data.rorobin.collection.length == 0">-</span>
                    <ng-container  *ngIf="data.rorobin.collection.length > 0">
                        <span *ngFor="let collect of data.rorobin.collection; let isLast = last">
                            {{collect.supplier_name}}({{collect.invoice_no}})<span *ngIf="!isLast"> & </span>
                        </span>
                    </ng-container>
                </td>
                <td class="bd-left text-right">{{data.budget.total_rorobin | number: "1.2-2"}}</td>
                <td class="text-left"><em><b>{{data.budget.total_rorobin_percent | number: "1.2-2"}} %</b></em></td>
            </tr>
            <tr>
                <th colspan="2">Referral Fee (RM) <em><small>**From SQL</small></em></th>
                <td class="text-right">{{data.referral.total | number: '1.2-2'}}</td>
                <td class="text-center"><em><b>{{data.figure_percent.referralPercent | number: '1.2-2'}}</b></em></td>
                <td colspan="2">
                    <span *ngIf="data.referral.collection.length == 0">-</span>
                    <ng-container  *ngIf="data.referral.collection.length > 0">
                        <span *ngFor="let collect of data.referral.collection; let isLast = last">
                            {{collect.supplier_name}}({{collect.invoice_no}})<span *ngIf="!isLast"> & </span>
                        </span>
                    </ng-container>
                </td>
                <td class="bd-left text-right"></td>
                <td class="text-left"><em><b></b></em></td>
            </tr>
            <tr>
                <th colspan="2">Outsource Contractor (RM) <em><small>**From SQL</small></em></th>
                <td class="text-right">{{data.outsource.total | number: '1.2-2'}}</td>
                <td class="text-center"><em><b>{{data.figure_percent.outsourcePercent | number: '1.2-2'}}</b></em></td>
                <td colspan="2">
                    <span *ngIf="data.outsource.collection.length == 0">-</span>
                    <ng-container  *ngIf="data.outsource.collection.length > 0">
                        <span *ngFor="let collect of data.outsource.collection; let isLast = last">
                            {{collect.supplier_name}}({{collect.invoice_no}})<span *ngIf="!isLast"> & </span>
                        </span>
                    </ng-container>
                </td>
                <td class="bd-left text-right"></td>
                <td class="text-left"><em><b></b></em></td>
            </tr>
            <tr>
                <th>Admin Fee (RM)</th>
                <th>{{data.config_percent.adminFee_percent}}%</th>
                <td class="text-right">{{data.costing.adminFee | number: "1.2-2"}}</td>
                <td class="text-center"><em><b>{{data.figure_percent.adminPercent | number: "1.2-2"}} %</b></em></td>
                <td></td>
                <td></td>
                <td class="bd-left text-right">{{data.budget.admin_fee | number: "1.2-2"}}</td>
                <td class="text-left"><em><b>{{data.budget.admin_fee_percent | number: "1.2-2"}} %</b></em></td>
            </tr>
            <tr>
                <th colspan="2">Net Sales (RM)</th>
                <td class="text-right"><b>{{data.costing.total_netSales | number: "1.2-2"}}</b></td>
                <td class="text-center"><em><b>{{data.figure_percent.netSalesPercent | number: "1.2-2"}} %</b></em></td>
                <td></td>
                <td></td>
                <td class="bd-left"></td>
                <td></td>
            </tr>
            <tr>
                <th>Inspector Commission (RM)</th>
                <th>{{data.config_percent.inspecFee_percent}}%</th>
                <td class="text-right">{{data.costing.inspecFee | number: "1.2-2"}}</td>
                <td></td>
                <!-- <td class="text-center"><em><b>{{data.figure_percent.inspecPercent | number: "1.2-2"}} %</b></em></td> -->
                <td>Inspector</td>
                <td>{{data.task_progress.inspection_person}}</td>
                <td class="bd-left text-right">{{data.budget.salesman_commission | number: "1.2-2"}}</td>
                <td class="text-left"><em><b>{{data.budget.salesman_commission_percent | number: "1.2-2"}} %</b></em></td>
            </tr>
            <tr>
                <th colspan="2">Total Amount (RM)</th>
                <td class="text-right"><b>{{data.costing.total_afterFee | number: "1.2-2"}}</b></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="bd-left"></td>
                <td></td>
            </tr>
            <tr>
                <th colspan="2">Job Description</th>
                <td colspan="4" style="white-space: pre-line;">{{data.task_inv && data.task_inv[0] ? data.task_inv[0]['descp'] : "-"}}</td>
                <td colspan="2" class="bd-left"></td>
            </tr>
    
            <!-- Vehicle Info -->        
            <tr>
                <th colspan="2"></th>
                <td class="bg-black text-white text-center">Qty</td>
                <td class="bg-black text-white text-center">Unit Rate</td>
                <td class="bg-black text-white text-center">Total (RM)</td>
                <td class="bg-black text-white text-center"></td>
                <td colspan="2" class="bd-left bg-black text-white text-center"></td>
            </tr>
            <tr>
                <th colspan="2">Inspector Transport Fees</th>
                <td class="text-center">{{(data.vehicles.inspection.qty ?? 0)}}</td>
                <td class="text-right">{{(data.vehicles.inspection.rate ?? 0) | number: "1.2-2"}}</td>
                <td class="text-right">{{(data.vehicles.inspection.total ?? 0) | number: "1.2-2"}}</td>
                <td></td>
                <td class="bd-left"></td>
                <td></td>
            </tr>
            <tr>
                <th colspan="2">Car Plate</th>
                <td colspan="4">
                    <span *ngFor="let v of data.task_inspection_vehicles; let i = index; let isLast = last">{{v.plate_no}}{{!isLast ? ', ' : ''}}</span>
                </td>
                <td colspan="2" class="bd-left"></td>
            </tr>
            <tr>
                <th colspan="2">Company Transport Fees</th>
                <td class="text-center">{{(data.vehicles.company.qty ?? 0)}}</td>
                <td class="text-right">{{(data.vehicles.company.rate ?? 0) | number: "1.2-2"}}</td>
                <td class="text-right">{{(data.vehicles.company.total ?? 0) | number: "1.2-2"}}</td>
                <td></td>
                <td colspan="2" class="bd-left"></td>
            </tr>
            <tr>
                <th colspan="2">Car Plate</th>
                <td colspan="4">{{data.vehicles.company.plate_no ? convertArrToSting(data.vehicles.company.plate_no) : "-"}}</td>
                <td colspan="2" class="bd-left"></td>
            </tr>
            <tr>
                <th colspan="2">Personal Car Transport Fees</th>
                <td class="text-center">{{(data.vehicles.private_car.qty ?? 0)}}</td>
                <td class="text-right">{{(data.vehicles.private_car.rate ?? 0) | number: "1.2-2"}}</td>
                <td class="text-right">{{(data.vehicles.private_car.total ?? 0) | number: "1.2-2"}}</td>
                <td></td>
                <td colspan="2" class="bd-left"></td>
            </tr>
            <tr>
                <th colspan="2">Car Plate</th>
                <td colspan="4">{{data.vehicles.private_car.plate_no ? convertArrToSting(data.vehicles.private_car.plate_no) : "-"}}</td>
                <td colspan="2" class="bd-left"></td>
            </tr>
            <tr>
                <th colspan="2">Personal Motocycle Transport Fees</th>
                <td class="text-center">{{(data.vehicles.private_motor.qty ?? 0)}}</td>
                <td class="text-right">{{(data.vehicles.private_motor.rate ?? 0) | number: "1.2-2"}}</td>
                <td class="text-right">{{(data.vehicles.private_motor.total ?? 0) | number: "1.2-2"}}</td>
                <td></td>
                <td colspan="2" class="bd-left"></td>
            </tr>
            <tr>
                <th colspan="2">Motor Plate</th>
                <td colspan="4">{{data.vehicles.private_motor.plate_no ? convertArrToSting(data.vehicles.private_motor.plate_no) : "-"}}</td>
                <td colspan="2" class="bd-left"></td>
            </tr>
            <ng-container *ngIf="data.grab.collection == 0">
                <th colspan="2">Grab <em><small>**From SQL</small></em></th>
                <td class="text-center"></td>
                <td class="text-right">{{0 | number: '1.2-2'}}</td>
                <td class="text-right">{{0 | number: '1.2-2'}}</td>
                <td></td>
                <td colspan="2" class="bd-left"></td>
            </ng-container>
            <ng-container *ngIf="data.grab.collection > 0">
                <tr *ngFor="let grab of data.grab.collection;">
                    <th colspan="2">Grab <em><small><br>**From SQL</small></em></th>
                    <td class="text-center">1</td>
                    <td class="text-right">{{grab.total | number: '1.2-2'}}</td>
                    <td class="text-right">{{grab.total | number: '1.2-2'}}</td>
                    <td>{{grab.invoice_no}}</td>
                    <td colspan="2" class="bd-left"></td>
                </tr>
            </ng-container>
            <tr class="importantRow">
                <th colspan="2"></th>
                <td></td>
                <td></td>
                <td class="text-right"><b>{{(data.total_vehicles ?? 0) | number: "1.2-2"}}</b></td>
                <td><em><b>{{data.figure_percent.vehiclePercent | number: '1.2-2'}} %</b></em></td>
                <td class="bd-left text-right"><b>{{data.budget.total_vehicle | number: '1.2-2'}}</b></td>
                <td class="text-center"><em><b>{{data.budget.total_vehicle_percent | number: "1.2-2"}} %</b></em></td>
            </tr>
            <tr *ngFor="let ironBox of data.iron_box.collection;">
                <th colspan="2">Rental Of Iron Box <em><small>**From SQL</small></em></th>
                <td class="text-right">{{ironBox.qty}}</td>
                <td class="text-right">{{ironBox.unitprice | number: "1.2-2"}}</td>
                <td class="text-right">{{ironBox.total | number: "1.2-2"}}</td>
                <td></td>
                <td class="bd-left"></td>
                <td></td>
            </tr>
            <tr *ngIf="!data.iron_box || data.iron_box.collection.length == 0">
                <th colspan="2">Rental Of Iron Box / Day <em><small>**From SQL</small></em></th>
                <td class="text-right">-</td>
                <td class="text-right">0</td>
                <td class="text-right">0.00</td>
                <td></td>
                <td class="bd-left"></td>
                <td></td>
            </tr>
            <tr class="importantRow">
                <th colspan="2"></th>
                <td></td>
                <td></td>
                <td class="text-right"><b>{{data.iron_box.total | number: "1.2-2"}}</b></td>
                <td><em><b>{{data.figure_percent.ironBoxPercent | number: '1.2-2'}} %</b></em></td>
                <td class="bd-left"></td>
                <td></td>
            </tr>
    
    
            <!-- Material Info -->
            <tr>
                <th colspan="2">Materials Fees (RM) <em><small>**From SQL</small></em></th>
                <td colspan="4"></td>
                <td colspan="2" class="bd-left"></td>
            </tr>

            <tr *ngFor="let material of data.material_combine;">
                <th colspan="2" style="min-width: 250px; white-space: pre-line;">{{material.actual.item_name}}</th>
                <td class="text-right">{{material.actual.item_qty}}</td>
                <td class="text-right">{{material.actual.item_unitprice | number: '1.2-2'}}</td>
                <td class="text-right">{{material.actual.total | number: '1.2-2'}}</td>
                <td>{{material.actual.supplier_name}} <small class="text-muted" *ngIf="material.actual.invoice_no">({{material.actual.invoice_no}})</small></td>
                <td class="text-right">{{ (material.budget ? (material.budget.cost_amt ?? material.budget.total) : 0) | number: '1.2-2'}}</td>
                <td class="text-left" style="min-width: 200px; white-space: pre-line;">{{material.budget.name ?? null}}</td>
            </tr>
            <!-- <tr *ngFor="let material of data.materials.collection;">
                <th colspan="2">{{material.item_name}}</th>
                <td class="text-right">{{material.item_qty}}</td>
                <td class="text-right">{{material.item_unitprice | number: '1.2-2'}}</td>
                <td class="text-right">{{material.total | number: '1.2-2'}}</td>
                <td>{{material.supplier_name}} <small class="text-muted">({{material.invoice_no}})</small></td>
                <td colspan="2" class="bd-left"></td>
            </tr> -->
            <tr class="importantRow">
                <th colspan="2"></th>
                <td></td>
                <td></td>
                <td class="text-right"><b>{{data.materials.total | number: '1.2-2'}}</b></td>
                <td><em><b>{{data.figure_percent.materialPercent | number: '1.2-2'}} %</b></em></td>
                <td class="bd-left text-right"><b>{{data.budget.total_materials | number: '1.2-2'}}</b></td>
                <td class="text-left"><em><b>{{data.budget.total_materials_percent | number: "1.2-2"}} %</b></em></td>
            </tr>
    
    
            <!-- Inspector Info -->
            <tr>
                <th colspan="2">Inspection (RM)</th>
                <td colspan="4"></td>
                <td colspan="2" class="bd-left"></td>
            </tr>
            <tr>
                <th colspan="2">First Inspector</th>
                <td class="text-right">{{data.task_inspection.first.qty}}</td>
                <td class="text-right">{{data.task_inspection.first.rate | number: '1.2-2'}}</td>
                <td class="text-right">{{(data.task_inspection.first.total ?? 0) | number: '1.2-2'}}</td>
                <td>{{data.task_inspection.first.inspection_person}}</td>
                <td colspan="2" class="bd-left"></td>
            </tr>
            <tr>
                <th colspan="2">Second Inspector</th>
                <td class="text-right">{{data.task_inspection.second.qty}}</td>
                <td class="text-right">{{data.task_inspection.second.rate | number: "1.2-2"}}</td>
                <td class="text-right">{{(data.task_inspection.second.total ?? 0) | number: '1.2-2'}}</td>
                <td>{{data.task_inspection.second.inspection_person}}</td>
                <td colspan="2" class="bd-left"></td>
            </tr>
            <tr>
                <th colspan="2">Third Inspector</th>
                <td class="text-right">{{data.task_inspection.third.qty}}</td>
                <td class="text-right">{{data.task_inspection.third.rate | number: '1.2-2'}}</td>
                <td class="text-right">{{(data.task_inspection.third.total ?? 0) | number: "1.2-2"}}</td>
                <td>{{data.task_inspection.third.inspection_person}}</td>
                <td colspan="2" class="bd-left"></td>
            </tr>
            <tr class="importantRow">
                <th colspan="2" class="bd-left"></th>
                <td></td>
                <td></td>
                <td class="text-right"><b>{{data.total_inspection | number: '1.2-2'}}</b></td>
                <td><em><b>{{data.figure_percent.inspectorPercent | number: '1.2-2'}} %</b></em></td>
                <td class="bd-left text-right"><b>{{data.budget.salesman_fee | number: '1.2-2'}}</b></td>
                <td class="text-left"><em><b>{{data.budget.salesman_fee_percent | number: '1.2-2'}} %</b></em></td>
            </tr>
    
    
            <!-- Site Worker Info -->
            <tr>
                <th colspan="2">Site Worker Wages (RM)</th>
                <td colspan="4"></td>
                <td colspan="2" class="bd-left"></td>
            </tr>
            <tr *ngFor="let wages of data.wages; let i = index;">
                <th colspan="2">Worker Fees</th>
                <td class="text-right">{{wages.duration}}</td>
                <td class="text-right">{{wages.rate}}</td>
                <td class="text-right">{{wages.total | number: '1.2-2'}}</td>
                <td>{{wages.name}}</td>
                <td colspan="2" class="bd-left"></td>
            </tr>
            <tr class="importantRow">
                <th colspan="2"></th>
                <td></td>
                <td></td>
                <td class="text-right"><b>{{data.total_wages | number: '1.2-2'}}</b></td>
                <td><em><b>{{data.figure_percent.wagesPercent | number: '1.2-2'}} %</b></em></td>
                <td class="bd-left text-right"><b>{{data.budget.total_manpower | number: '1.2-2'}}</b></td>
                <td class="text-left"><em><b>{{data.budget.total_manpower_percent | number: '1.2-2'}} %</b></em></td>
            </tr>
    
            <tr>
                <th colspan="2">Total Costs (RM)</th>
                <td></td>
                <td></td>
                <td class="text-right"><b>{{data.costing.total_costFee | number: '1.2-2'}}</b></td>
                <td><em><b>{{data.figure_percent.totalCostPercent | number: '1.2-2'}} %</b></em></td>
                <td class="bd-left text-right"><b>{{data.budget.total_cost| number: '1.2-2'}}</b></td>
                <td></td>
            </tr>
            <tr>
                <td colspan="8"></td>
            </tr>
            <tr>
                <th colspan="2">Total Headcount</th>
                <td class="text-right">{{data.total_headCount}}</td>
                <td class="text-right"></td>
                <td class="text-right"></td>
                <td></td>
                <td class="bd-left text-right"><b>{{data.budget.total_headcount}}</b></td>
                <td></td>
            </tr>
            <tr>
                <td colspan="8"></td>
            </tr>
            <tr>
                <th>Gross Profit/Loss (RM)</th>
                <th>{{data.config_percent.grossProfit_percent}}%</th>
                <td class="text-right"><b>{{data.costing.total_GrossProfit | number: '1.2-2'}}</b></td>
                <td><em><b>{{data.figure_percent.totalGrossProfitPercent | number: '1.2-2'}} %</b></em></td>
                <td></td>
                <td></td>
                <td class="bd-left text-right"><b>{{data.budget.total_profit | number: '1.2-2'}}</b></td>
                <td class="text-left"><em><b>{{data.budget.total_profit_percent | number: '1.2-2'}} %</b></em></td>
            </tr>
            <tr>
                <th>Leader Commission (RM)</th>
                <th>{{data.config_percent.leaderCommission_percent_1}}%</th>
                <td class="text-right"><b>{{data.costing.total_leaderCommission1 | number: '1.2-2'}}</b></td>
                <td><em><b>{{data.figure_percent.totalLeaderPercent1 | number: '1.2-2'}} %</b></em></td>
                <td></td>
                <td></td>
                <td class="bd-left"></td>
                <td></td>
            </tr>
            <tr>
                <th>Leader Commission (RM)</th>
                <th>{{data.config_percent.leaderCommission_percent_2}}%</th>
                <td class="text-right"><b>{{data.costing.total_leaderCommission2 | number: '1.2-2'}}</b></td>
                <td><em><b>{{data.figure_percent.totalLeaderPercent2 | number: '1.2-2'}} %</b></em></td>
                <td></td>
                <td></td>
                <td class="bd-left"></td>
                <td></td>
            </tr>
            <tr>
                <th>Company Profit (RM)</th>
                <th>{{data.config_percent.companyProfit_percent}}%</th>
                <td class="text-right"><b>{{data.costing.total_companyProfit | number: '1.2-2'}}</b></td>
                <td><em><b>{{data.figure_percent.totalCompanyProfitPercent | number: '1.2-2'}} %</b></em></td>
                <td></td>
                <td></td>
                <td class="bd-left"></td>
                <td></td>
            </tr>
    
        </tbody>
    </table>
</ng-container>






