
<div class="row">
    <div class="col-12 col-sm-12 col-md-6">
        <div class="card">
            <div class="card-body">
                <section class="mb-5">
                    <h5 class="text-primary"><b>Project Information</b></h5>
                    <hr class="border-dark">
                </section>
        
                <form>
                    <div class="form-group">
                        <label>Type</label>
                        <select class="form-control" [(ngModel)]="data.type" name="type" (change)="onChangeValue()">
                            <option [value]="'on_going'">On Going Project</option>
                            <option [value]="'after_care'">After Care</option>
                            <option [value]="'inspection'">Inspection</option>
                        </select>
                    </div>
                    <ng-container *ngIf="data.type && data.type == 'inspection'">
                        <div class="form-group">
                            <label>Job No</label>
                            <input type="text" class="form-control" [(ngModel)]="data.doc_no" name="doc_no">
                        </div>
                        <div class="form-group">
                            <label>Assign To</label>
                            <div class="search-dropdown flex-grow-1" [ngClass]="{'open':shown && userList !== undefined && userList?.length !== 0}">
                                <div class="search-dropdown__btn" (click)="show()"><span>{{value}}</span></div>
                                <div class="search-dropdown__dropdown">
                                    <input class="search-dropdown__dropdown__search" type="text" placeholder="Search" [(ngModel)]="keyword" name="keyword" (keyup)="search(keyword)" #input/>
                                    <input type="hidden" [id]="'assign_to'" [name]="'assign_to'" class="form-control" [value]="data.assign_to"/>
                                    <div class="search-dropdown__dropdown__scroller">
                                        <div class="search-dropdown__dropdown__item" *ngFor="let ee of users" (click)="select('name', 'user_id', ee)">
                                        {{ee.name}}
                                        </div>
                                        <div class="search-dropdown__dropdown__msg" *ngIf="userList?.length == 0">No Result Found</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Appointment Date</label>
                            <input class="form-control" type="date" [(ngModel)]="data.appointment_date" name="appointment_date">
                        </div>
                        <div class="form-group">
                            <label>Appointment Time</label>
                            <input class="form-control" type="time" [(ngModel)]="data.appointment_time" name="appointment_time">
                        </div>
                        <div class="form-group">
                            <label>Customer Name</label>
                            <input class="form-control" type="text" [(ngModel)]="data.customer_name" name="customer_name">
                        </div>
                        <div class="form-group">
                            <label>Customer Contact</label>
                            <input class="form-control" type="text" [(ngModel)]="data.customer_contact" name="customer_contact">
                        </div>
                        <div class="form-group">
                            <label>Customer Address</label>
                            <textarea class="form-control" rows="5" [(ngModel)]="data.address" name="address"></textarea>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="data.type && data.type !== 'inspection'">
                        <div class="form-group">
                            <label>Invoice No</label>
                            <input type="text" class="form-control" [(ngModel)]="data.doc_no" name="doc_no">
                        </div>
                        <div class="form-group">
                            <label>Assign To (By Department)</label>
                            <div class="search-dropdown flex-grow-1" [ngClass]="{'open':shown && deptList !== undefined && deptList?.length !== 0}">
                                <div class="search-dropdown__btn" (click)="show()"><span>{{value}}</span></div>
                                <div class="search-dropdown__dropdown">
                                    <input class="search-dropdown__dropdown__search" type="text" placeholder="Search" [(ngModel)]="keyword" name="keyword" (keyup)="searchDept(keyword)" #input/>
                                    <input type="hidden" [id]="'assign_to'" [name]="'assign_to'" class="form-control" [value]="data.assign_to"/>
                                    <div class="search-dropdown__dropdown__scroller">
                                        <div class="search-dropdown__dropdown__item" *ngFor="let ee of departments" (click)="select('descp', 'id', ee)">
                                        {{ee.descp}}
                                        </div>
                                        <div class="search-dropdown__dropdown__msg" *ngIf="deptList?.length == 0">No Result Found</div>
                                    </div>
                                </div>
                            </div>
                            <!-- <select class="form-control" [(ngModel)]="data.assign_to" name="assign_to">
                                <option *ngFor="let dept of departments" [value]="dept.id">{{dept.descp}}</option>
                            </select> -->
                        </div>
                        <div class="form-group">
                            <label>Customer Address</label>
                            <textarea class="form-control" rows="5" [(ngModel)]="data.address" name="address"></textarea>
                        </div>
                    </ng-container>
                </form>
        
                <ng-container *ngIf="data.type">
                    <button class="btn btn-primary w-100 mt-4" (click)="submit()" [disabled]="btnLoading">
                        <span *ngIf="btnLoading">Loading...</span>
                        <span *ngIf="!btnLoading">Confirm & Save</span>
                    </button>
                </ng-container>
                <button class="btn btn-danger w-100 mt-2" [routerLink]="['/temp-task/project']"> < Back</button>
            </div>
        </div>
    </div>
</div>