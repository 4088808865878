import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'lexi-api';
import { LexiNotifyService } from 'lexi-notify';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'lib-task-stock-transaction-quick',
  templateUrl: './task-stock-transaction-quick.component.html',
  styleUrls: ['./task-stock-transaction-quick.component.css']
})
export class TaskStockTransactionQuickComponent implements OnInit {

  @Output() closeEvent = new EventEmitter()
  public currentActive:any = "stock_in";
  public data:any = {};
  public items:any = [];
  public users:any = [];
  public locations:any = [];
  public selectedItem:any = {};
  public isLoading:boolean = false;
  public isBtnLoading:boolean = false;

  employeeDropdownSettings:IDropdownSettings = {};

  constructor(
    private api: ApiService,
    private notify: LexiNotifyService
  ) { 
    this.employeeDropdownSettings = {
      singleSelection: true,
      idField: 'user_id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 20,
      allowSearchFilter: true
    };  
  }

  ngOnInit(): void {
    this.initialize();
  }

  async initialize() {
    this.isLoading = true;
    await Promise.all([
      this.initUser(),
      this.initWarehouse(),
      this.initItem()
    ]);
    this.preData()
    this.isLoading = false;
  }

  async initUser() {
    this.users = await this.api.post("/setting/get/all-users", {});
  }

  async initWarehouse() {
    this.locations = await this.api.post("/inventory/get/warehouse", {status: 1})
  }

  async initItem(){
    this.items = await this.api.post("/inventory/get/all/stock", {});
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.currentActive = changeEvent.nextId;
    this.preData()
	}

  async preData() {
    this.selectedItem = {};
    this.data = {};
    this.data.date = new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2)
    this.data.location_id = this.locations[0]['id']
  }

  async detectItems(){
    this.selectedItem = {};
    delete this.data.stock_id;
    delete this.data.stock_balance;
  }

  async balanceCheck() {
    if(!this.data.stock_id){
      this.notify.warning("Please select stock "+ (this.currentActive == 'stock_in' ? "in" : "out") +" item." )
      return;
    }

    if(!this.data.location_id){
      this.notify.warning("Please select warehouse." )
      return;
    }

    let rtn = await this.api.post("/inventory/check/warehouse-balance", this.data)
    if(!rtn.location_balance){
      return;      
    }

    this.data.stock_balance = rtn.location_balance;
  }

  async detectChanges($event:any) {
    this.data.stock_id = this.selectedItem['id']
    this.data.stock_balance = this.selectedItem['balance']
    await this.balanceCheck()
  }

  async submit() {
    this.isBtnLoading = true;
    
    if(!this.data.location_id){
      this.isBtnLoading = false;
      this.notify.warning('Warehouse cannot be empty.')
      return;
    }

    if(!this.data.stock_id){
      this.isBtnLoading = false;
      this.notify.warning('Item cannot be empty.')
      return;
    }

    if(!this.data.type){
      this.isBtnLoading = false;
      this.notify.warning('Transaction type cannot be empty.')
      return;
    }

    if(this.currentActive == 'stock_in' && !this.data.debit || this.data.debit == 0){
      this.isBtnLoading = false;
      this.notify.warning('Quantity cannot be empty or 0.')
      return;
    }

    this.data.method = this.currentActive;

    const result = await this.api.post("/inventory/save/stock/transaction", this.data)
    if(!result.status){
      this.isBtnLoading = false;
      this.notify.error(result.message)
      return;
    }

    this.notify.success("Transaction saved successfully.")
    await this.initItem();
    await this.preData();
    this.isBtnLoading = false;
  }

  async close() {
    this.closeEvent.emit(false)
  }
  
}
