import { Component, OnInit } from '@angular/core';
import { ApiService } from 'lexi-api';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'lib-task-schedule',
  templateUrl: './task-schedule.component.html',
  styleUrls: ['./task-schedule.component.css']
})
export class TaskScheduleComponent implements OnInit {

  public data:any = {};
  public listing:any = {};
  public isLoading:boolean = false;
  constructor(private api: ApiService, private cookiesService: CookieService) { 
    const date = new Date();

    if(this.cookiesService.get("schedule_date_from")){
      this.data.date_from = this.cookiesService.get("schedule_date_from")
    }else{
      this.data.date_from = date.getFullYear() +"-"+ ("0" + (date.getMonth() + 1)).slice(-2) +"-01";
    }

    if(this.cookiesService.get("schedule_date_to")){
      this.data.date_to = this.cookiesService.get("schedule_date_to")
    }else{
      this.data.date_to = date.getFullYear() +"-"+ ("0" + (date.getMonth() + 1)).slice(-2) +"-"+ ("0" + date.getDate()).slice(-2);
    }
  }

  ngOnInit(): void {
    this.getSchedule();
  }

  async getSchedule() {
    this.isLoading = true;
    this.captureDate()
    this.listing = await this.api.post('/task/task-schedule/get', this.data)
    this.isLoading = false;
  }

  getDetail(key:any) {
    return this.listing[key]
  }

  async captureDate() {
    this.cookiesService.set("schedule_date_from", this.data.date_from)
    this.cookiesService.set("schedule_date_to", this.data.date_to)
  }
}
