import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TaskWarehouseDetailComponent } from './task-warehouse-detail/task-warehouse-detail.component';
import { ApiService } from 'lexi-api';

@Component({
  selector: 'lib-task-warehouse',
  templateUrl: './task-warehouse.component.html',
  styleUrls: ['./task-warehouse.component.css']
})
export class TaskWarehouseComponent implements OnInit {

  @Input() set parent_uuid(val : any) {
    this.uuid = val;
    this.initialize();
  };

  public uuid:any;
  public filterData:any = {};
  public collections:any = [];
  public isLoading:boolean = false;
  constructor(
    private modalService: NgbModal,
    private api: ApiService
  ) { }

  ngOnInit(): void {
  }

  async initialize() {
    this.isLoading = true;
    this.filterData['stock_uuid'] = this.uuid;
    this.collections = await this.api.post("/inventory/get/warehouse", this.filterData)
    this.isLoading = false;
  }

  async getSearch() {
    this.initialize()
  }

  async getReset() {
    this.filterData = {}
    await this.initialize()
  }

  async detailModal(data?:any) {
    let modal = await this.modalService.open(TaskWarehouseDetailComponent, {ariaLabelledBy: 'warehouse-modal', backdrop: 'static', size: 'lg', keyboard: false, centered: true})
    modal.componentInstance.setData = data;
    modal.componentInstance.closeEvent.subscribe(( val:any ) => {
      modal.close()
      if(val){
        this.initialize()
      }
    })
  }
}
