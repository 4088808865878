import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'lexi-api';
import { LexiNotifyService } from 'lexi-notify';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'lib-task-stock-transaction',
  templateUrl: './task-stock-transaction.component.html',
  styleUrls: ['./task-stock-transaction.component.css']
})
export class TaskStockTransactionComponent implements OnInit {

  employeeDropdownSettings:IDropdownSettings = {};

  @Input() set parent_uuid(val : any) {
    this.uuid = val;
    this.initData();
  };

  public uuid:any;
  public currentActive:any = "stock_in";
  public isLoading:boolean = false;
  public isBtnLoading:boolean = false;
  public stock:any = {};
  public data:any = {};
  public users:any = [];
  public locations:any = [];
  
  constructor(private api: ApiService, private notify: LexiNotifyService, private router: Router) { 
    this.employeeDropdownSettings = {
      singleSelection: true,
      idField: 'user_id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 20,
      allowSearchFilter: true
    };  

    this.data.date = new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2)
  }

  ngOnInit(): void {
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.currentActive = changeEvent.nextId;
    this.data = {}
    this.data.stock_id = this.stock.id;
    this.data.date = new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2)
	}

  async initData() {
    this.isLoading = true;
    this.users = await this.api.post("/setting/get/all-users", {});
    this.stock = await this.api.post("/inventory/get/stock/detail", {uuid: this.uuid});
    this.locations = await this.api.post("/inventory/get/warehouse", {status: 1})
    this.data.stock_id = this.stock.id;
    this.isLoading = false;
  }

  async submit() {
    this.isBtnLoading = true;

    if(!this.data.location_id){
      this.isBtnLoading = false;
      this.notify.warning('Warehouse cannot be empty.')
      return;
    }

    if(!this.data.type){
      this.isBtnLoading = false;
      this.notify.warning('Transaction type cannot be empty.')
      return;
    }

    if(this.currentActive == 'stock_in' && !this.data.debit || this.data.debit == 0){
      this.isBtnLoading = false;
      this.notify.warning('Quantity cannot be empty or 0.')
      return;
    }

    this.data.method = this.currentActive;

    const result = await this.api.post("/inventory/save/stock/transaction", this.data)
    this.isBtnLoading = false;
    if(!result.status){
        return this.notify.error(result.message)
    }

    this.notify.success("Transaction saved successfully.")
    this.router.navigate(['/detail/stock', this.uuid, 'extend', 'stock-transaction-list', this.uuid])
  }
}
