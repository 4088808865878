
<nav class="d-flex justify-content-between mb-3">
    <h4 class="m-0">Worker Rate Management</h4>
</nav>
<div class="d-flex mb-3">
    <input class="form-control form-control-sm w-100" type="text" placeholder="Search something..." [(ngModel)]="filterData.search" name="search" (ngModelChange)="getSearch()">
    <button class="btn btn-dark btn-sm ms-2" style="align-self: flex-end;" (click)="getReset()">Reset</button>
    <button class="btn btn-primary btn-sm ms-2" style="align-self: flex-end; min-width: 10%;" [routerLink]="['/task-material/worker-rate-form/new']">+ Add New</button>
</div>

<div class="card">
    <div class="card-body p-0">
        <ng-container *ngIf="isLoading">
            <lib-bar></lib-bar>
        </ng-container>
        <div class="table-responsive" *ngIf="!isLoading">
            <table class="table table-sm table-hover table-striped">
                <thead class="thead-primary">
                    <tr>
                        <th>No</th>
                        <th>Employee Code</th>
                        <th>Employee Name</th>
                        <th>Position</th>
                        <th>Department</th>
                        <th>Cost Rate</th>
                        <th>HR Rate</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let collect of collections; let i = index;">
                        <td>{{i+1}}</td>
                        <td>{{collect.code}}</td>
                        <td>{{collect.name}}</td>
                        <td>{{collect.position}}</td>
                        <td>{{collect.department}}</td>
                        <td>{{collect.rate}}</td>
                        <td>{{collect.hr_rate}}</td>
                        <td class="d-flex gap-2">
                            <button class="btn btn-link text-danger p-0 me-3" (click)="removeRate(collect)">Delete</button>
                            <a [routerLink]="['/cost/worker-rate', collect.user_uuid]">View</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>