<div class="form-group">
    <label [for]="fields.mapping.key">{{fields.title}} <em *ngIf="fields.required">*</em>
        <ng-container *ngIf="fields.descp">
            <br>
            <small class="text-muted">{{fields.descp}}</small>
        </ng-container>
    </label>
    <ng-select [multiple]="true" [formControl]="ctrl" [id]="fields.mapping.key" (change)="detectChanges($event)" [disabled]="fields.editable && fields.editable == 0">
        <ng-option *ngFor="let option of options" [value]="option.val">{{option.title}}</ng-option>
    </ng-select>
</div>