import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ProgressDashboardComponent } from './progress-dashboard/progress-dashboard.component';
import { WHBTaskRoutingModule } from './whb-task.routing.module';
import { TaskProgressComponent } from './task-progress/task-progress.component';
import { MediaComponent } from './media/media.component';
import { TaskDailyRecordComponent } from './task-daily-record/task-daily-record.component';
import { TaskBudgetComponent } from './task-budget/task-budget.component';
import { TaskCostingComponent } from './task-costing/task-costing.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TaskFollowupComponent } from './task-followup/task-followup.component';
import { DetailComponent } from './task-followup/detail/detail.component';
import { BarComponent, DotComponent } from 'whb-loading';
import { TaskCostingConfirmationComponent } from './task-costing/task-costing-confirmation/task-costing-confirmation.component';
import { TaskStockTransactionComponent } from './task-stock-transaction/task-stock-transaction.component';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TaskStockTransactionListComponent } from './task-stock-transaction-list/task-stock-transaction-list.component';
import { TaskStockSupplierListComponent } from './task-stock-supplier-list/task-stock-supplier-list.component';
import { TransactionDetailComponent } from './task-stock-transaction-list/transaction-detail/transaction-detail.component';
import { TaskAftercareProgressComponent } from './task-aftercare-progress/task-aftercare-progress.component';
import { TaskCostingAftercareComponent } from './task-costing-aftercare/task-costing-aftercare.component';
import { FilterComponent } from './task-stock-transaction-list/filter/filter.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TaskStockTransactionQuickComponent } from './task-stock-transaction-quick/task-stock-transaction-quick.component';
import { TaskWarehouseComponent } from './task-warehouse/task-warehouse.component';
import { TaskWarehouseDetailComponent } from './task-warehouse/task-warehouse-detail/task-warehouse-detail.component';

@NgModule({
  declarations: [
    ProgressDashboardComponent,
    TaskProgressComponent,
    MediaComponent,
    TaskDailyRecordComponent,
    TaskBudgetComponent,
    TaskCostingComponent,
    TaskCostingConfirmationComponent,
    TaskFollowupComponent,
    DetailComponent,
    TaskStockTransactionListComponent,
    TaskStockTransactionComponent,
    TaskStockSupplierListComponent,
    TransactionDetailComponent,
    TaskAftercareProgressComponent,
    TaskCostingAftercareComponent,
    FilterComponent,
    TaskStockTransactionQuickComponent,
    TaskWarehouseComponent,
    TaskWarehouseDetailComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    WHBTaskRoutingModule,
    NgMultiSelectDropDownModule.forRoot(),
    BarComponent,
    DotComponent,
    NgbNavModule,
    NgSelectModule
  ],
  exports: [
  ]
})
export class WhbTaskModule { }
