<div class="d-flex flex-row justify-content-between pb-2 align-items-center">
    <h4>Follow-up</h4>
</div>

<ng-container *ngIf="isLoading">
    <lib-bar></lib-bar>
</ng-container>

<div class="row" *ngIf="!isLoading">
    <div class="col-12 col-sm-12 col-md-6 col-lg-6">
        <ul class="list-group">
            <li class="list-group-item list-group-item-action" *ngFor="let list of listing;">
                <small class="text-primary"><b>{{list.name}}</b></small>
                <p>{{list.note}}</p>
                <small class="text-muted">{{list.create_on | date: "dd-MM-yyyy"}}</small>
            </li>
            <li class="list-group-item list-group-item-action" *ngIf="!listing || listing.length == 0">
                <p class="text-muted">No data found.</p>
            </li>
        </ul>
    </div>
    <div class="col-12 col-sm-12 col-md-6 col-lg-6">
        <textarea class="form-control" rows="5" placeholder="Enter comment.." [(ngModel)]="data.note" name="note"></textarea>
        <button class="btn btn-success w-100 mt-3" (click)="submit()">Submit</button>
    </div>
</div>