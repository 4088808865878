<div class="d-flex justify-content-between">
    <h3 class="mb-0"><b>Costing Report Management <em class="text-danger"><small>** In Construction **</small></em></b></h3>
</div>
<hr>
<div class="row">
    <div class="col-12 col-sm-12 col-md-6">
        <div class="form-group mb-2">
            <label class="mb-1">From Date</label>
            <input class="form-control" type="date" [(ngModel)]="filter.date_from" name="date_from">
        </div>
    </div>
    <div class="col-12 col-sm-12 col-md-6">
        <div class="form-group mb-2">
            <label class="mb-1">To Date</label>
            <input class="form-control" type="date" [(ngModel)]="filter.date_to" name="date_to">
        </div>
    </div>
    <div class="col-12 col-sm-12 col-md-12">
        <div class="form-group mb-2">
            <label class="mb-1">Salesman</label>
            <ng-multiselect-dropdown
                [placeholder]="''"
                [settings]="employeeDropdownSettings"
                [data]="employees"
                [(ngModel)]="filter.salesman_ids">
            </ng-multiselect-dropdown>
        </div>
    </div>
    <div class="col-12 col-sm-12 col-md-12">
        <div class="form-group mb-2">
            <label class="mb-1">Technician</label>
            <ng-multiselect-dropdown
                [placeholder]="''"
                [settings]="employeeDropdownSettings"
                [data]="employees"
                [(ngModel)]="filter.technician_ids">
            </ng-multiselect-dropdown>
        </div>
    </div>
</div>
<button class="btn btn-primary w-100 mb-3" (click)="initData()">Search</button>

<ng-container *ngIf="isLoading">
    <lib-dot></lib-dot>
</ng-container>

<ng-container *ngIf="!isLoading">
    <ng-container *ngIf="listing && listing.length > 0">
        <div class="card mb-3">
            <div class="card-body p-0">
                <div class="table-responsive">
                    <table class="table table-sm table-hover table-striped table-bordered" style="font-size: 80%; white-space:nowrap">
                        <thead class="table-dark thead-sticky">
                            <tr>
                                <th scope="col" style="position: sticky; left: 0; width:30px; z-index: 3;"></th>
                                <th scope="col" style="position: sticky; left: 30px; width: 98px; min-width: 98px; z-index: 3;"></th>
                                <th scope="col" style="position: sticky; left: 128px; width: 205px; min-width: 205px; z-index: 3;"></th>
                                <th scope="col" style="position: sticky; left: 333px; z-index: 3;"></th>
                                <th scope="col" colspan="10"></th>
                                <th scope="col" colspan="27" class="text-center bg-light-green">Actual Costs</th>
                                <th scope="col" colspan="14" class="text-center bg-light-red">Budget Costs</th>
                                <th scope="col" colspan="13" class="text-center bg-light-blue">Variances</th>
                            </tr>
                            <tr>
                                <th scope="col" style="position: sticky; left: 0; width:30px; z-index: 3;">#</th>
                                <th scope="col" style="position: sticky; left: 30px; width: 98px; min-width: 98px; z-index: 3;">Job No</th>
                                <th scope="col" style="position: sticky; left: 128px; width: 205px; min-width: 205px; z-index: 3;">Salesman</th>
                                <th scope="col" style="position: sticky; left: 333px; z-index: 3;">Technician</th>
                                <th scope="col">Belt</th>
                                <th scope="col">Invoice Month</th>
                                <th scope="col">QT/PR No</th>
                                <th scope="col">Invoice No</th>
                                <th scope="col">Complete Month</th>
                                <th scope="col">Sales Amount</th>
                                <th scope="col">Credit Note</th>
                                <th scope="col">Actual Amount</th>
                                <th scope="col">Days of Complete</th>
                                <th scope="col">Manpower Headcount</th>

                                <th scope="col" class="bg-light-green">Referral Fees</th>
                                <th scope="col" class="bg-light-green">Skylift/Crane</th>
                                <th scope="col" class="bg-light-green">Hire of Equipment</th>
                                <th scope="col" class="bg-light-green">Site Insurance</th>
                                <th scope="col" class="bg-light-green">Rubish Disposal</th>
                                <th scope="col" class="bg-light-green">Outsorce Contractor</th>
                                <th scope="col" class="bg-light-green">Admin Fee</th>
                                <th scope="col" class="bg-light-green">Salesman Commission</th>
                                <th scope="col" class="bg-light-green">Compensation</th>
                                <th scope="col" class="bg-light-green">Outstation Claims</th>
                                <th scope="col" class="bg-light-green">Site Purchase Supplier</th>
                                <th scope="col" class="bg-light-green">Site Purchase Leader</th>
                                <th scope="col" class="bg-light-green">Stock Out / Comsup</th>
                                <th scope="col" class="bg-light-green">Site Worker</th>
                                <th scope="col" class="bg-light-green">Company Car</th>
                                <th scope="col" class="bg-light-green">Personal Car</th>
                                <th scope="col" class="bg-light-green">Grab Car</th>
                                <th scope="col" class="bg-light-green">Personal Motor</th>
                                <th scope="col" class="bg-light-green">Salesman/Vehicle</th>
                                <th scope="col" class="bg-light-green">Total Costing</th>
                                <th scope="col" class="bg-light-green">Gross Profit</th>
                                <th scope="col" class="bg-light-green">G. Profit vs Sales Margin</th>
                                <th scope="col" class="bg-light-green">Belt</th>
                                <th scope="col" class="bg-light-green">Leader Commission</th>
                                <th scope="col" class="bg-light-green">Company Profit</th>
                                <th scope="col" class="bg-light-green">C. Profit vs Sales Margin</th>
                                <th scope="col" class="bg-light-green">Total Commision</th>

                                <th scope="col" class="bg-light-red">Budgeted Project Period</th>
                                <th scope="col" class="bg-light-red">Budgeted Headcount</th>
                                <th scope="col" class="bg-light-red">Total Budgeted Headcount</th>
                                <th scope="col" class="bg-light-red">Budget Vehicles</th>
                                <th scope="col" class="bg-light-red">Budget Skylift / Crane</th>
                                <th scope="col" class="bg-light-red">Budget Insurance</th>
                                <th scope="col" class="bg-light-red">Budget Materials</th>
                                <th scope="col" class="bg-light-red">Budget Manpower</th>
                                <th scope="col" class="bg-light-red">Admin Fee</th>
                                <th scope="col" class="bg-light-red">Salesman Wage</th>
                                <th scope="col" class="bg-light-red">Salesman 5% Commission</th>
                                <th scope="col" class="bg-light-red">Total Budgeted Cost</th>
                                <th scope="col" class="bg-light-red">Budgeted Profit</th>
                                <th scope="col" class="bg-light-red">Budgeted Profit Margin</th>

                                <th scope="col" class="bg-light-blue">Days of Completion</th>
                                <th scope="col" class="bg-light-blue">Manpower Headcount</th>
                                <th scope="col" class="bg-light-blue">Vehicle</th>
                                <th scope="col" class="bg-light-blue">Skylift/Crane</th>
                                <th scope="col" class="bg-light-blue">Insurance</th>
                                <th scope="col" class="bg-light-blue">Materials</th>
                                <th scope="col" class="bg-light-blue">Manpower</th>
                                <th scope="col" class="bg-light-blue">Admin Fee</th>
                                <th scope="col" class="bg-light-blue">Salesman Wages</th>
                                <th scope="col" class="bg-light-blue">Salesman 5% Commission</th>
                                <th scope="col" class="bg-light-blue">Profit (Budget vs Actual)</th>
                                <th scope="col" class="bg-light-blue">Profit Margin (%) (Budget vs Actual)</th>
                                <th scope="col" class="bg-light-blue">Efficiency between Budget and Actual</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let list of listing; let i=index;" [class.selected]="(i+1)=== selectedRowIdx" (click)="onRowClick(i+1)">
                                <td style="position: sticky; left: 0; width:30px; background:#eee; z-index: 3;">{{i+1}}</td>
                                <td style="position: sticky; left: 30px; width: 98px; min-width: 98px; background:#eee; z-index: 3;">
                                    <a [routerLink]="['/detail/task', list.data.task_detail.uuid, 'extend', 'task-costing', list.data.task_detail.uuid]">{{list.data.task_detail.reference_no}}</a>
                                </td>
                                <td style="position: sticky; left: 128px; width: 205px; min-width: 205px; background:#eee; z-index: 3;">{{list.data.task_progress.inspection_person}}</td>
                                <td style="position: sticky; left: 333px; background:#eee; z-index: 3;">
                                    <ng-container *ngFor="let tech of list.data.task_progress.technician; let last = last">
                                        {{tech}}<span *ngIf="!last"> / </span>
                                    </ng-container>
                                </td>
                                <td>{{getBelt(list.data.task_progress.site_leader_grade)}}</td>
                                <td class="text-center">{{month[list.inv_month - 1]}}</td>
                                <td>
                                    <span *ngIf="list.data.task_pr.length == 0 && list.data.task_qtt.length == 0">-</span>
                                    <ng-container *ngFor="let qtt of list.data.task_qtt; let last = last">
                                        {{qtt.quotation}}<span *ngIf="!last"> & </span>
                                    </ng-container>
                                    <ng-container *ngFor="let pr of list.data.task_pr; let last = last">
                                        {{pr.proforma_no}}<span *ngIf="!last"> & </span>
                                    </ng-container>
                                </td>
                                <td>
                                    <span *ngIf="!list.data.task_inv || list.data.task_inv.length == 0">-</span>
                                    <ng-container *ngFor="let inv of list.data.task_inv; let last = last">
                                        {{inv.sql_reference_no}}<span *ngIf="!last"> & </span>
                                    </ng-container>
                                </td>
                                <td></td> <!-- Complete Month -->
                                <td class="text-right">{{list.total_sales}}</td>
                                <td class="text-right">{{list.total_credit_note}}</td>
                                <td class="text-right">{{list.actual_sales}}</td>
                                <td class="text-center">{{list.data.task_progress.work_period.length}}</td>
                                <td class="text-center">{{list.workers.length * list.data.task_progress.work_period.length}}</td>
                                <td class="text-right"></td> <!-- Referral Fee -->
                                <td class="text-right"></td> <!-- Skylift/Crane -->
                                <td class="text-right"></td> <!-- Equipment -->
                                <td class="text-right">{{list.data.costing.insuranceFee | number: '1.2-2'}}</td>
                                <td class="text-right"></td> <!-- Rubbish -->
                                <td class="text-right"></td> <!-- Outsource -->
                                <td class="text-right">{{list.data.costing.adminFee | number: '1.2-2'}}</td>
                                <td class="text-right">{{list.data.costing.inspecFee | number: '1.2-2'}}</td>
                                <td class="text-right"></td> <!-- Compensation -->
                                <td class="text-right"></td> <!-- Outstation Claim -->
                                <td class="text-right">{{list.total_materials}}</td>
                                <td class="text-right"></td> <!-- Site Purchase Leader -->
                                <td class="text-right"></td> <!-- Stock Out -->
                                <td class="text-right">{{list.total_wages}}</td>
                                <td class="text-right">{{list.vehicles.company.total ? (list.vehicles.company.total | number: '1.2-2') : "-"}}</td>
                                <td class="text-right">{{list.vehicles.private_car.total ? (list.vehicles.private_car.total | number: '1.2-2') : "-"}}</td>
                                <td class="text-right"></td> <!-- Grab Car -->
                                <td class="text-right">{{list.vehicles.private_motor.total ? (list.vehicles.private_motor.total | number: '1.2-2') : "-"}}</td>
                                <td class="text-right">{{ (list.data.task_inspection.first.total ?? 0) + (list.vehicles.inspection.total ?? 0) | number: '1.2-2'}}</td>
                                <td class="text-right">{{list.total_costs}}</td>
                                <td class="text-right">{{list.total_gross_profit}}</td>
                                <td class="text-right">{{list.gross_profit_percent}}%</td>
                                <td class="text-right">{{(list.data.config_percent.leaderCommission_percent_1 + list.data.config_percent.leaderCommission_percent_2) | number: '1.2-2'}}%</td>
                                <td class="text-right">{{(list.data.costing.total_leaderCommission1 + list.data.costing.total_leaderCommission2) | number: '1.2-2'}}</td>
                                <td class="text-right">{{list.total_company_profit}}</td>
                                <td class="text-right">{{list.company_profit_percent}}%</td>
                                <td class="text-right">{{(list.data.costing.total_leaderCommission1 + list.data.costing.total_leaderCommission2 + list.data.costing.inspecFee) | number: '1.2-2'}}</td>
                            
                                <td class="text-center">{{list.budgetCost.project_period}}</td>
                                <td class="text-center">{{list.budgetCost.headcount}}</td>
                                <td class="text-center">{{list.budgetCost.total_headcount}}</td>
                                <td class="text-right">{{list.budgetCost.total_vehicle | number: '1.2-2'}}</td>
                                <td class="text-right">{{list.budgetCost.total_skylift_crane | number: '1.2-2'}}</td>
                                <td class="text-right">{{list.budgetCost.total_insurance | number: '1.2-2'}}</td>
                                <td class="text-right">{{list.budgetCost.total_materials | number: '1.2-2'}}</td>
                                <td class="text-right">{{list.budgetCost.total_manpower | number: '1.2-2'}}</td>
                                <td class="text-right">{{list.budgetCost.admin_fee | number: '1.2-2'}}</td>
                                <td class="text-right">{{list.budgetCost.salesman_fee | number: '1.2-2'}}</td>
                                <td class="text-right">{{list.budgetCost.salesman_commission | number: '1.2-2'}}</td>
                                <td class="text-right">{{list.budgetCost.total_cost | number: '1.2-2'}}</td>
                                <td class="text-right">{{list.budgetCost.total_profit | number: '1.2-2'}}</td>
                                <td class="text-right">{{list.budgetCost.total_profit_percent | number: '1.2-2'}}%</td>

                                <td class="text-right">{{list.varianceCost.project_period | number: '1.2-2'}}</td>
                                <td class="text-right">{{list.varianceCost.headcount | number: '1.2-2'}}</td>
                                <td class="text-right">{{list.varianceCost.project_period | number: '1.2-2'}}</td>
                                <td class="text-right">{{list.varianceCost.vehicles | number: '1.2-2'}}</td>
                                <td class="text-right">{{list.varianceCost.skylift_crane | number: '1.2-2'}}</td>
                                <td class="text-right">{{list.varianceCost.materials | number: '1.2-2'}}</td>
                                <td class="text-right">{{list.varianceCost.manpower | number: '1.2-2'}}</td>
                                <td class="text-right">{{list.varianceCost.admin_fee | number: '1.2-2'}}</td>
                                <td class="text-right">{{list.varianceCost.salesman_fee | number: '1.2-2'}}</td>
                                <td class="text-right">{{list.varianceCost.salesman_commission | number: '1.2-2'}}</td>
                                <td class="text-right">{{list.varianceCost.profit | number: '1.2-2'}}</td>
                                <td class="text-right">{{list.varianceCost.profit_margin | number: '1.2-2'}}</td>
                                <td class="text-center">{{list.varianceCost.efficiency}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </ng-container>
    
    <div class="card"  *ngIf="!listing || listing.length == 0">
        <div class="card-body">
            <p class="text-muted">No data found!</p>        
        </div> 
    </div>
</ng-container>