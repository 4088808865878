import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'lexi-api';
import { WhbDataService } from 'whb-data';

@Component({
  selector: 'lib-task-project-report-detail',
  templateUrl: './task-project-report-detail.component.html',
  styleUrls: ['./task-project-report-detail.component.css']
})
export class TaskProjectReportDetailComponent implements OnInit {

  public filter:any = {};
  public listing:any = []
  public data:any = {user: {}}
  public isLoading:boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private api:ApiService, private dataService: WhbDataService) { 
    this.activatedRoute.queryParams.subscribe((params:any) => {
      this.filter = params;
      this.init()
    })
  }

  ngOnInit(): void {
  }

  async init(){
    this.isLoading = true;
    const rtn = await this.api.post("/admin/leader/report-detail", this.filter)
    this.data = rtn.data;
    this.listing = rtn.collections;
    this.isLoading = false;
  }
  
  async exportCSV() {
    let date = new Date();
    const todayDate = date.getFullYear()+("0" + (date.getMonth() + 1)).slice(-2)+("0" + (date.getDate())).slice(-2) 
    let filename = todayDate+" Pending Progress Photo - " + this.data.user.name;
    const exportData:any = []
    exportData.push(["Reference No", "Sql Doc No", "Sql Inv No", "Customer Code", "Entity Name", "Entity Contact", "Address"])
    
    for(let x = 0; x < this.listing.length; x++){
      const singleData:any = []
      singleData.push(this.listing[x]['reference_no'])
      singleData.push(this.listing[x]['sql_doc_no'])
      singleData.push(this.listing[x]['sql_inv_no'])
      singleData.push(this.listing[x]['customer_code'])
      singleData.push(this.listing[x]['entity_name'])
      singleData.push(this.listing[x]['entity_contact'])
      singleData.push(JSON.stringify(this.listing[x]['address']));
      exportData.push(singleData)
    }
    this.dataService.exportCSV(filename, exportData)
  }
}
