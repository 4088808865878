import { Component, OnInit } from '@angular/core';
import { ApiService } from 'lexi-api';
import { LexiNotifyService } from 'lexi-notify';

@Component({
  selector: 'lib-task-worker-rate-list',
  templateUrl: './task-worker-rate-list.component.html',
  styleUrls: ['./task-worker-rate-list.component.css']
})
export class TaskWorkerRateListComponent implements OnInit {

  public collections:any;
  public filterData:any = {};
  public isLoading:boolean = false;
  private timeout:any = null;
  constructor(private api: ApiService, private notify: LexiNotifyService) { }

  ngOnInit(): void {
    this.init();
  }

  async init() {
    this.isLoading = true;
    this.collections = await this.api.post("/task/get/worker-rate", {filterData: this.filterData})
    this.isLoading = false;
  }

  async getSearch() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.timeout = null;
      this.init()
    }, 1000);
  }

  async getReset() {
    this.init()
  }

  async removeRate(data:any) {
    await this.notify.confirm("Are you sure to remove this record?", async (status?:any) => {
      let rtn = await this.api.post("/task/remove/worker-rate", data)
      if(!rtn.status){
        return this.notify.error(rtn.message)
      }

      this.notify.success("Remove successful.")
      this.init();
    })
  }
}
