import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'lexi-api';
import { LexiNotifyService } from 'lexi-notify';

@Component({
  selector: 'lib-task-followup',
  templateUrl: './task-followup.component.html',
  styleUrls: ['./task-followup.component.css']
})
export class TaskFollowupComponent implements OnInit {

  @Input() set parent_uuid(val : any) {
    this.uuid = val;
    this.init();
  };

  public uuid:any;
  public data:any = {};
  public listing:any = [];
  public isLoading:boolean = false;

  constructor(private api: ApiService, private notify: LexiNotifyService) { }

  ngOnInit(): void {
  }

  async init() {
    this.isLoading = true;
    this.listing = await this.api.post('/task/get/project/follow-up', {task_uuid: this.uuid})
    this.isLoading = false;
  }

  async submit() {
    this.data.task_uuid = this.uuid;
    const result = await this.api.post('/task/save/project/follow-up', this.data)
    if(!result.status){
      this.notify.error(result.message)
      return;
    }

    this.notify.success("Save successfully.")
    this.init()
  }

}
