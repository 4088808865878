<div class="card">
    <div class="card-body">
        <div class="d-flex flex-row justify-content-between">
            <h3>Project Invoice</h3>
            <button class="btn btn-primary px-5" [routerLink]="['/temp-task/project/detail/new']">Add New</button>
        </div>
       

        <ng-container *ngIf="!isLoading">
            <ul ngbNav #nav="ngbNav" [(activeId)]="currentActive" (navChange)="onNavChange($event)" class="nav-tabs">
                <li [ngbNavItem]="'new'">
                    <a ngbNavLink>New</a>
                    <ng-template ngbNavContent>
                        <table class="table table-sm table-striped">
                            <thead class="thead-dark">
                                <tr>
                                    <th style="width: 5%;">No</th>
                                    <th style="width: 15%;">Assign To Team</th>
                                    <th style="width: 15%;">Doc No</th>
                                    <th style="width: 10%;">Type</th>
                                    <th>Address</th>
                                    <th style="width: 10%;">Status</th>
                                    <th style="width: 15%;"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let list of listing; let i = index;">
                                    <td>{{i+1}}</td>
                                    <td>{{list.assign_team}}</td>
                                    <td>{{list.doc_no}}</td>
                                    <td>{{typeConverter(list.type)}}</td>
                                    <td>{{list.address}}</td>
                                    <td>{{list.status == 1 ? "NEW" : "COMPLETE"}}</td>
                                    <td class="d-flex">
                                        <button class="btn btn-primary py-1 me-2 w-100" [routerLink]="['/temp-task/project/detail', list.uuid]">View</button>
                                        <button class="btn btn-success py-1 w-100" (click)="statusChange(list)" [disabled]="list.status == 0">Complete</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>   
                    </ng-template>
                </li>
                <li [ngbNavItem]="'complete'">
                    <a ngbNavLink>Complete</a>
                    <ng-template ngbNavContent>
                        <table class="table table-sm table-striped">
                            <thead class="thead-dark">
                                <tr>
                                    <th style="width: 5%;">No</th>
                                    <th style="width: 15%;">Assign To Team</th>
                                    <th style="width: 15%;">Doc No</th>
                                    <th style="width: 10%;">Type</th>
                                    <th>Address</th>
                                    <th style="width: 10%;">Status</th>
                                    <th style="width: 15%;"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let list of listing; let i = index;">
                                    <td>{{i+1}}</td>
                                    <td>{{list.assign_team}}</td>
                                    <td>{{list.doc_no}}</td>
                                    <td>{{typeConverter(list.type)}}</td>
                                    <td>{{list.address}}</td>
                                    <td>{{list.status == 1 ? "NEW" : "COMPLETE"}}</td>
                                    <td class="d-flex">
                                        <button class="btn btn-primary py-1 me-2 w-100" [routerLink]="['/temp-task/project/detail', list.uuid]">View</button>
                                        <button class="btn btn-success py-1 w-100" (click)="statusChange(list)" [disabled]="list.status == 0">Complete</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>   
                    </ng-template>
                </li>
                <li [ngbNavItem]="'all'">
                    <a ngbNavLink>All</a>
                    <ng-template ngbNavContent>
                        <table class="table table-sm table-striped">
                            <thead class="thead-dark">
                                <tr>
                                    <th style="width: 5%;">No</th>
                                    <th style="width: 15%;">Assign To Team</th>
                                    <th style="width: 15%;">Doc No</th>
                                    <th style="width: 10%;">Type</th>
                                    <th>Address</th>
                                    <th style="width: 10%;">Status</th>
                                    <th style="width: 15%;"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let list of listing; let i = index;">
                                    <td>{{i+1}}</td>
                                    <td>{{list.assign_team}}</td>
                                    <td>{{list.doc_no}}</td>
                                    <td>{{typeConverter(list.type)}}</td>
                                    <td>{{list.address}}</td>
                                    <td>{{list.status == 1 ? "NEW" : "COMPLETE"}}</td>
                                    <td class="d-flex">
                                        <button class="btn btn-primary py-1 me-2 w-100" [routerLink]="['/temp-task/project/detail', list.uuid]">View</button>
                                        <button class="btn btn-success py-1 w-100" (click)="statusChange(list)" [disabled]="list.status == 0">Complete</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>    
                    </ng-template>
                </li>
            </ul>
            
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </ng-container>
        
        <ng-container *ngIf="isLoading">
            <lib-bar></lib-bar>
        </ng-container>
    </div>
</div>