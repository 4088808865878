import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TaskScheduleComponent } from './task-schedule.component';

const routes: Routes = [
  {path: "", component: TaskScheduleComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TaskScheduleRoutingModule { }
