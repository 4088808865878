<div class="row">
    <div class="col-12 col-sm-12 col-md-6">
        <div class="card">
            <div class="card-body">
                <section class="mb-4">
                    <h5 class="text-primary"><b>Worker Rate Detail</b></h5>
                    <hr class="border-dark">
                </section>
                <p class="d-flex flex-row mb-1"><span>Code :&nbsp;</span><b>{{data.code}}</b></p>
                <p class="d-flex flex-row mb-1"><span>Name :&nbsp;</span><b>{{data.name}}</b></p>
                <p class="d-flex flex-row mb-1"><span>Department :&nbsp;</span><b>{{data.department}}</b></p>

                <table class="table table-sm table-striped table-hover my-4">
                    <thead class="table-dark">
                        <tr>
                            <th style="width: 20%;">Effective Date</th>
                            <th>Rate</th>
                            <th style="width: 20%;">Create Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let rate of data.rates">
                            <td>{{rate.effective_date | date: 'dd-MM-yyyy'}}</td>
                            <td>{{rate.rate | number: '1.2-2'}}</td>
                            <td>{{rate.create_on | date: 'dd-MM-yyyy'}}</td>
                        </tr>
                    </tbody>
                </table>

                <button class="btn btn-danger btn-sm w-100" [routerLink]="['/task-material/worker-rate-list']">Back</button>
            </div>
        </div>
    </div>
</div>
