import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { LexiCalendarComponent } from 'lexi-calendar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'lexi-api';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'lib-task-calendar',
  templateUrl: './task-calendar.component.html',
  styleUrls: ['./task-calendar.component.css']
})
export class TaskCalendarComponent implements AfterViewInit {

  @ViewChild("calendar", {static: false}) calendar!: LexiCalendarComponent

  public date:any = {}
  public selectedStatus:any;
  public taskStatus:any = [];

  dropdownList:any = [];
  selectedItems:any = [];
  dropdownSettings:IDropdownSettings = {};
  
  constructor(private api: ApiService, private ngbModal: NgbModal) { 
    this.init();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'identifier',
      textField: 'title',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true
    };
  }

  ngAfterViewInit() {
    this.calendar.eventMonthChange.subscribe(async (calender:any) => {
      this.date['year'] = calender.year;
      this.date['month'] = calender.month + 1;
      await this.getCalender();
    })
  }

  async init() {
    this.taskStatus = await this.api.post("/setting/get/calendar/task-status", {})
    
    await this.initCalender()
  }

  async initCalender() {
    let date:any = new Date();
    this.date['year'] = date.getFullYear();
    this.date['month'] = date.getMonth() + 1;
    await this.getCalender();
  }

  async search() {
    this.selectedStatus.forEach((obj1:any) => {
      const matchingObj = this.taskStatus.find((obj2:any) => obj2.identifier === obj1.identifier);      
      if (matchingObj) {
        obj1.mapping = matchingObj.mapping;
      }
    });
    this.getCalender()
  }

  async getCalender() {
    let filter = this.date;
        filter['search'] = this.selectedStatus;



    let rtn = await this.api.post("/task/task/calender/byBranch", filter);
    console.log(rtn)
  //   let data = rtn.appointment;
    
  //   data.forEach((v:any) => {
  //     v.labels.forEach((element:any) => {
  //       element.onclick = () => {
  //         let model = this.ngbModal.open(AppointmentDetailComponent, {size: 'md'});
  //         model.componentInstance.setData = element;
  //         model.componentInstance.close.subscribe(() => {
  //           this.getCalender();
  //           model.close();
  //         })
  //       }
  //     });
  //   });

  //   this.calendar.setCalendarData(this.date, data)
  //   this.calendar.initCalendar();
  //   this.calendar.updateCalendarData();
  }

  // async addEvent() {
  //   let modal = this.ngbModal.open(CalenderEventComponent, {size:"md"});

  //   modal.componentInstance.close.subscribe((val:boolean) => {
  //     if (val) {
  //       this.getCalender();
  //       modal.close();
  //     }
  //   })
  // }
}
