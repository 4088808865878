import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'lexi-api';
import { LexiNotifyService } from 'lexi-notify';

@Component({
  selector: 'lib-task-project-material-inquiry',
  templateUrl: './task-project-material-inquiry.component.html',
  styleUrls: ['./task-project-material-inquiry.component.css']
})
export class TaskProjectMaterialInquiryComponent implements OnInit {

  @Input() set setData (val:any) {
    this.data.branch_id = val.branch_id;
    this.data.task_id = val.task_id;
    this.data.project_entry_id = val.id;
    this.data.reference_no = val.proforma_no ?? val.quotation_no;
    this.data.ship_address = val.customer_address.full_address;
    this.data.ship_date = val.start_work_date;

    this.data.items = [{descp: "", qty: 1}]
  }
  @Output() closeEmitter = new EventEmitter();

  public data:any = {items: []}
  constructor(private api: ApiService, private notify:LexiNotifyService) { }

  ngOnInit(): void {
  }

  addItem() {
    this.data.items.push({descp: "", qty: 1})
  }

  async removeItem(item:any, index:number) {
    if(this.data.items.length <= 1) {
      return;
    }
    this.data.items.splice(index, 1)
  }

  async submit() {
    const result = await this.api.post("/task/save/material-inquiry", this.data)
    if(!result.status){
      return this.notify.error(result.message)
    }

    this.notify.success("Submit Successfull.")
    this.closeEmitter.emit(true)
  }

  closeModal() {
    this.closeEmitter.emit(false)
  }
}
