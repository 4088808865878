import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lib-task-project-complete-confirmation',
  templateUrl: './task-project-complete-confirmation.component.html',
  styleUrls: ['./task-project-complete-confirmation.component.css']
})
export class TaskProjectCompleteConfirmationComponent implements OnInit {

  @Input() set setData (val:any) {
    this.data = val
  }
  @Output() closeEmitter = new EventEmitter();

  public data:any;
  constructor() { }

  ngOnInit(): void {
  }

  async submit() {
    this.closeEmitter.emit(true)
  }

  closeModal() {
    this.closeEmitter.emit(false)
  }
}
