<div class="modal-body">
    <h5><b>Project Progress <span class="text-danger">Cancel Complete</span> Confirmation</b></h5>
    <hr>

    <p>Are you sure you want to cancel "Complete" for this project?</p>
    <p>
        <span>Doc No. : <b>{{data.doc_no}}</b></span>
        <br>
        <span>SQL Doc No. : <b>{{data.sql_reference_no}}</b></span>
    </p>

</div>
<div class="modal-footer">
    <button class="btn btn-success btn-sm w-100" (click)="submit()">YES</button>
    <button class="btn btn-danger btn-sm w-100" (click)="closeModal()">NO</button>
</div>


