
<ng-container *ngIf="isLoading">
    <lib-bar></lib-bar>
</ng-container>

<ng-container  *ngIf="!isLoading">
    <ul ngbNav #nav="ngbNav" [(activeId)]="currentActive" (navChange)="onNavChange($event)" class="nav-tabs">
        <li [ngbNavItem]="'stock_in'" class="col">
            <a ngbNavLink class="rounded-0">Stock In</a>
            <ng-template ngbNavContent>
                <div class="card p-5 border-top-0 rounded-0">
                    <h4><b>STOCK IN</b></h4>
                    <hr>
                    <form>
                        <div class="form-group">
                            <label>Warehouse</label>
                            <ng-select class="form-control p-0 border-0" [(ngModel)]="data.location_id" name="location_id">
                                <ng-option *ngFor="let location of locations" [value]="location.id">{{location.code}}</ng-option>
                            </ng-select>
                        </div>
                        <div class="form-group">
                            <label>Date</label>
                            <input class="form-control" type="date" [(ngModel)]="data.date" name="date">
                        </div>
                        <div class="form-group">
                            <label>Supplier Name</label>
                            <input class="form-control" type="text" [(ngModel)]="data.supplier" name="supplier_name">
                        </div>   
                        <div class="form-group">
                            <label>Supplier Invoice No</label>
                            <input class="form-control" type="text" [(ngModel)]="data.invoice_no" name="invoice_no">
                        </div> 
                        <div class="form-group">
                            <label>Transaction Type</label>
                            <select class="form-control" [(ngModel)]="data.type" name="type">
                                <option [value]="'IN'">IN</option>
                                <option [value]="'RETURN'">RETURN</option>
                                <option [value]="'ADJUST'">ADJUSTMENT</option>
                            </select>
                        </div> 
                        <div class="form-group">
                            <label>Employee Name</label>
                            <ng-multiselect-dropdown
                                [placeholder]="''"
                                [settings]="employeeDropdownSettings"
                                [data]="users"
                                [(ngModel)]="data.userIds"
                                name="userIds">
                            </ng-multiselect-dropdown>
                        </div>   
                        <div class="form-group">
                            <label>Job Invoice No</label>
                            <input class="form-control" type="text" [(ngModel)]="data.reference_no" name="reference_no">
                        </div> 
                        <div class="form-group">
                            <label>Unit Price</label>
                            <input class="form-control" type="number" [(ngModel)]="data.rate" name="rate">
                        </div>
                        <div class="form-group">
                            <label>Stock In Quantity</label>
                            <input class="form-control" type="number" [(ngModel)]="data.debit" name="debit">
                        </div>
                        <div class="form-group">
                            <label>License</label>
                            <select class="form-control" [(ngModel)]="data.license" name="license">
                                <option [value]="'YES'">YES</option>
                                <option [value]="'NO'">NO</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>License Expired</label>
                            <input class="form-control" type="date" [(ngModel)]="data.license_expiry_date" name="license_expiry_date">
                        </div>
                        <div class="form-group">
                            <label>Remark</label>
                            <textarea class="form-control" rows="3" [(ngModel)]="data.remark" name="remark"></textarea>
                        </div>

                        <button class="btn btn-primary w-100" (click)="submit()" [disabled]="isBtnLoading">
                            <ng-container *ngIf="!isBtnLoading">Save</ng-container>
                            <ng-container *ngIf="isBtnLoading"><lib-dot></lib-dot></ng-container>
                        </button>
                    </form>
                </div>
            </ng-template>
        </li>
        <li [ngbNavItem]="'stock_out'" class="col">
            <a ngbNavLink class="rounded-0">Stock Out</a>
            <ng-template ngbNavContent>
                <div class="card p-5 border-top-0 rounded-0">
                    <h4><b>STOCK OUT</b></h4>
                    <hr>
                    <form>
                        <div class="form-group">
                            <label>Warehouse</label>
                            <ng-select class="form-control p-0 border-0" [(ngModel)]="data.location_id" name="location_id">
                                <ng-option *ngFor="let location of locations" [value]="location.id">{{location.code}}</ng-option>
                            </ng-select>
                        </div>
                        <div class="form-group">
                            <label>Date</label>
                            <input class="form-control" type="date" [(ngModel)]="data.date" name="date">
                        </div>
                        <div class="form-group">
                            <label>Employee Name</label>
                            <ng-multiselect-dropdown
                                [placeholder]="''"
                                [settings]="employeeDropdownSettings"
                                [data]="users"
                                [(ngModel)]="data.userIds"
                                name="userIds">
                            </ng-multiselect-dropdown>
                        </div>   
                        <div class="form-group">
                            <label>Job Invoice No</label>
                            <input class="form-control" type="text" [(ngModel)]="data.reference_no" name="reference_no">
                        </div> 
                        <div class="form-group">
                            <label>Transaction Type</label>
                            <select class="form-control" [(ngModel)]="data.type" name="type">
                                <option [value]="'OUT'">OUT</option>
                                <option [value]="'EXPIRED'">EXPIRED</option>
                                <option [value]="'ADJUST'">ADJUSTMENT</option>
                            </select>
                        </div> 
                        <div class="form-group">
                            <label>Stock Out Quantity</label>
                            <input class="form-control" type="number" [(ngModel)]="data.credit" name="credit">
                        </div>
                        <div class="form-group">
                            <label>License</label>
                            <select class="form-control" [(ngModel)]="data.license" name="license">
                                <option [value]="'YES'">YES</option>
                                <option [value]="'NO'">NO</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>License Expired</label>
                            <input class="form-control" type="date" [(ngModel)]="data.license_expiry_date" name="license_expiry_date">
                        </div>
                        <div class="form-group">
                            <label>Remark</label>
                            <textarea class="form-control" rows="3" [(ngModel)]="data.remark" name="remark"></textarea>
                        </div>

                        <button class="btn btn-primary w-100" (click)="submit()" [disabled]="isBtnLoading">
                            <ng-container *ngIf="!isBtnLoading">Save</ng-container>
                            <ng-container *ngIf="isBtnLoading"><lib-dot></lib-dot></ng-container>
                        </button>
                    </form>
                </div>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
</ng-container>
