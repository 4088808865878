import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lib-task-daily-record',
  templateUrl: './task-daily-record.component.html',
  styleUrls: ['./task-daily-record.component.css']
})
export class TaskDailyRecordComponent implements OnInit {

  @Input() set parent_uuid(val : any) {
    this.uuid = val;
  };

  public uuid:any;
  public todayDate:any;
  public data:any = {vehicles:[{}], workers:[{}]}
  constructor() { 
    var date = new Date()
    this.todayDate = ("0" + (date.getDate() + 1)).slice(-2)+"-"+("0" + (date.getMonth() + 1)).slice(-2)+"-"+date.getFullYear()
  }

  ngOnInit(): void {
    console.log(this.data)
  }

  calculateRowSubtotal(vehicle:any) {
    vehicle.subtotal = (parseFloat(vehicle.price) * parseFloat(vehicle.quantity)).toFixed(2);
  }

  addNewRow(data:any) {
    data.push({})
  }

  async removeRow(data:any, index:number) {
    if(data.length <= 1) {
      return;
    }

    let itemToRemove = data[index];
    // if(itemToRemove.uuid) {
    //   this.deleted_items.push(itemToRemove);
    // }
    data.splice(index, 1)
  }
}
