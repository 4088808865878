<div class="modal-header d-flex justify-content-between">
    <span>
        <h4 class="modal-title mb-0">Filter</h4>
    </span>
    <button class="btn btn-link text-danger" (click)="close()">
        <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"/></svg>
    </button>
</div>

<div class="d-flex my-3 mx-2 row">
    <div class="form-group col-12 mb-2">
        <div class="row">
            <div class="col-12 col-sm-6 mb-2">
                <label>Date From</label>
                <input 
                    class="form-control" 
                    type="date" 
                    [(ngModel)]="filterData.dateFrom" 
                    name="dateFrom">
            </div>
            <div class="col-12 col-sm-6 mb-2">
                <label>Date To</label>
                <input 
                    class="form-control" 
                    type="date" 
                    [(ngModel)]="filterData.dateTo" 
                    name="dateTo">
            </div>
        </div>
    </div>

    <div class="form-group col-12 mb-0">
        <div class="row">
            <div class="col-12 col-sm-7 col-md-8 mb-2">
                <label>Sort By</label>
                <select 
                    class="form-control" 
                    [(ngModel)]="filterData.sortBy">
                    <option value="item_name">Item Name</option>
                    <option value="name">Leader Name</option>
                    <option value="balance">Balance</option>
                    <option value="balance_amount">Balance Amount</option>
                    <option value="date">Date</option>
                </select>
            </div>
            <div class="col-12 col-sm-5 col-md-4 ps-0">
                <label>Order By</label>
                <select 
                    class="form-control" 
                    [(ngModel)]="filterData.sortOrder">
                    <option value="ASC">Ascending</option>
                    <option value="DESC">Descending</option>
                </select>
            </div>
        </div>
    </div>

    <div class="form-group">
        <label>Warehouse</label>
        <ng-select [multiple]="true" class="form-control p-0 border-0" [(ngModel)]="filterData.location_ids" name="location_id">
            <ng-option *ngFor="let location of locations" [value]="location.id">{{location.code}}</ng-option>
        </ng-select>
    </div>

    <div class="d-flex flex-row mt-4">
        <button class="btn btn-sm btn-primary w-100 mx-1 fit-width" (click)="submit()">Confirm</button>
        <button class="btn btn-sm btn-dark mx-1 fit-width fit-width-content" (click)="reset()">Reset</button>
    </div>
</div>
