import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'lexi-api'
import { LexiNotifyService } from 'lexi-notify';

@Component({
  selector: 'lib-task-warehouse-detail',
  templateUrl: './task-warehouse-detail.component.html',
  styleUrls: ['./task-warehouse-detail.component.css']
})
export class TaskWarehouseDetailComponent implements OnInit {
  
  @Input() set setData(val:any) {
    if(val){
      this.data = val;
    }
  }
  @Output() closeEvent = new EventEmitter()

  public data:any = {status: 1};
  public isLoading:boolean = false;
  constructor(
    private api: ApiService,
    private notify: LexiNotifyService 
  ) { }

  ngOnInit(): void {
  }

  async submit() {
    this.isLoading = true;
    if(!this.data.code){
      this.isLoading = false;
      this.notify.warning('Code cannot be empty.')
      return;
    }
    const result = await this.api.post("/inventory/save/warehouse", this.data)
    if(!result.status){
      this.isLoading = false;
      this.notify.error(result.message)
      return;
    }
    this.notify.success("Warehouse saved successfully")
    this.closeEvent.emit(true)
  }

  async close() {
    this.closeEvent.emit(false)
  }

}
