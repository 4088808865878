import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TaskWorkerRateListComponent } from './task-worker-rate-list.component';

const routes: Routes = [
  {path: "", component: TaskWorkerRateListComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TaskWorkerRateListRoutingModule { }
