<div class="row">
    <div class="col-12 col-sm-12 col-md-6">
        <div class="card">
            <div class="card-body">
                <section class="mb-4">
                    <h5 class="text-primary"><b>Worker Rate Management</b></h5>
                    <hr class="border-dark">
                </section>
                <form>
                    <div class="form-group">
                        <label>Employee Name</label>
                        <div class="search-dropdown flex-grow-1" [ngClass]="{'open':shown && userList !== undefined && userList?.length !== 0}">
                            <div class="search-dropdown__btn" (click)="show()"><span>{{value}}</span></div>
                            <div class="search-dropdown__dropdown">
                                <input class="search-dropdown__dropdown__search" type="text" placeholder="Search" [(ngModel)]="keyword" name="keyword" (keyup)="search(keyword)" #input/>
                                <input type="hidden" [id]="'user_id'" [name]="'user_id'" class="form-control" [value]="data.user_id"/>
                                <div class="search-dropdown__dropdown__scroller">
                                    <div class="search-dropdown__dropdown__item" *ngFor="let ee of users" (click)="select('name', 'user_id', ee)">
                                    {{ee.name}}
                                    </div>
                                    <div class="search-dropdown__dropdown__msg" *ngIf="userList?.length == 0">No Result Found</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="data.user_id && !isLoading">
                        <div class="form-group">
                            <label>Department</label>
                            <input type="text" class="form-control" disabled [(ngModel)]="data.department" name="department">
                        </div>
                        <div class="form-group">
                            <label>Position</label>
                            <input type="text" class="form-control" disabled [(ngModel)]="data.position" name="position">
                        </div>
                        <div class="form-group">
                            <label>HR Rate</label>
                            <input type="number" step="0.01" class="form-control" disabled [(ngModel)]="data.hr_rate" name="hr_rate">
                        </div>
                        <div class="form-group">
                            <label>Rate</label>
                            <input type="text" class="form-control" [(ngModel)]="data.rate" name="rate">
                        </div>
                        <div class="form-group">
                            <label>Effective Date</label>
                            <input class="form-control" type="date" [(ngModel)]="data.effective_date" name="effective_date">
                        </div>
                        <button class="btn btn-primary w-100 mt-4" (click)="submit()" type="submit" [disabled]="btnLoading">
                            <span *ngIf="btnLoading">Loading...</span>
                            <span *ngIf="!btnLoading">Confirm & Save</span>
                        </button>
                    </ng-container>
                    <button class="btn btn-danger w-100 mt-2" [routerLink]="['/task-material/worker-rate-list']"> < Back</button>
                </form>
            </div>
        </div>
    </div>
</div>