<ng-container *ngIf="type === 'manual_convert'">
    <div class="modal-body">
        <h5><b>Please enter the invoice no before save.</b></h5>
        <hr>
        <div class="form-group">
            <label>Invoice No.</label>
            <input type="text" class="form-control" [(ngModel)]="data.inv_no" name="inv_no">
        </div>
    </div>
</ng-container>
<ng-container *ngIf="type === 'live_convert'">
    <div class="modal-body">
        <h5><b>Please enter the invoice date before save.</b></h5>
        <hr>
        <div class="form-group">
            <label>Invoice Create Date</label>
            <input type="date" class="form-control" [(ngModel)]="data.inv_date" name="inv_date">
        </div>
    </div>
</ng-container>

<div class="modal-footer">
    <button *ngIf="!loading" class="btn btn-success btn-sm w-100 mt-2" (click)="submit()">Save</button>
    <button *ngIf="!loading" class="btn btn-danger btn-sm w-100 mt-2" (click)="closeModal()">Cancel</button>
    <button *ngIf="loading" class="btn btn-outline-dark btn-sm w-100 mt-2">Processing, please wait...</button>
</div>