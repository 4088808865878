import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TempProjectRoutingModule } from './temp-project.routing.module';
import { FormsModule } from '@angular/forms';
import { TempProjectComponent } from './temp-project.component';
import { TempProjectDetailComponent } from './temp-project-detail/temp-project-detail.component';
import { BarComponent, DotComponent } from 'whb-loading';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    TempProjectComponent,
    TempProjectDetailComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    NgbNavModule,
    TempProjectRoutingModule,
    DotComponent,
    BarComponent
  ],
  exports: [
  ]
})
export class TempProjectModule { }
