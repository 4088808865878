
<div class="d-flex flex-row justify-content-between pb-2 align-items-center">
    <h4 class="m-0">Supplier List</h4>
</div>

<ng-container *ngIf="isLoading">
    <lib-bar></lib-bar>
</ng-container>

<div class="row" *ngIf="!isLoading">
    <div class="col-12 col-sm-12 col-md-8 col-lg-6">
        <div class="table-responsive">
            <table class="table table-sm table-hover table-striped mt-3">
                <thead class="thead-dark">
                    <tr>
                        <th>Supplier Name</th>
                        <th class="text-right" style="width: 130px;">Latest Unit Price</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let collect of collections; let i = index;">
                        <td>{{collect.supplier}}</td>
                        <td class="text-right">{{collect.rate | number: '1.2-2'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>