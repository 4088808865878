import { Component, OnInit } from '@angular/core';
import { ApiService } from 'lexi-api';

@Component({
  selector: 'lib-task-project-grab',
  templateUrl: './task-project-grab.component.html',
  styleUrls: ['./task-project-grab.component.css']
})
export class TaskProjectGrabComponent implements OnInit {

  public isLoading:boolean = false;
  public listing:any = [];
  public selectedRowIdx:number = 0;

  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.initData()
  }

  async initData() {
    this.isLoading = true;
    this.listing = await this.api.post('/task/get/project/grab/list', {})
    this.isLoading = false;
  }

  onRowClick(idx:number) {
    this.selectedRowIdx = idx;
  }

}
