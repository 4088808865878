    <nav class="d-flex justify-content-between mb-3">
        <h4 class="m-0 px-3">Project Map</h4>
        <button class="btn btn-primary btn-sm" (click)="openModal(mapFilter)">Map Filter</button>
    </nav>
    
    <google-map width="100%" [center]="center" [zoom]="zoom">
        <map-marker *ngFor="let marker of markerPositions"
                    [position]="marker.position"
                    [label]="marker.label"
                    [title]="marker.title"
                    [options]="marker.options"
                    (mapClick)="viewDetail(marker)">
        </map-marker>
    </google-map>
    
    
    <ng-template #mapFilter let-modal>
        <div class="modal-body">
            <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
            <hr>
            <div class="form-group">
                <label>Filter Status</label>
                <ng-multiselect-dropdown
                    [placeholder]="''"
                    [settings]="dropdownSettings"
                    [data]="options"
                    [(ngModel)]="data.status"
                    (onSelect)="onItemSelect($event)"
                    (onSelectAll)="onSelectAll($event)">
                </ng-multiselect-dropdown>
            </div>
            <div class="form-group">
                <label>Search Address</label>
                <input class="form-control" [(ngModel)]="data.address" name="address" placeholder="Enter address">
            </div>
            <button class="btn btn-secondary btn-block mt-3" (click)="search()">Search</button>
        </div>
    </ng-template>
