import { Component, OnInit } from '@angular/core';
import { ApiService } from 'lexi-api';
import { LexiNotifyService } from 'lexi-notify';

@Component({
  selector: 'lib-task-worker-rate-form',
  templateUrl: './task-worker-rate-form.component.html',
  styleUrls: ['./task-worker-rate-form.component.css']
})
export class TaskWorkerRateFormComponent implements OnInit {

  public data:any = {};
  public users:any = [];
  public userList:any = [];
  public keyword:any = "";
  public value:any;
  public shown = false;

  public btnLoading:boolean = false;
  public isLoading:boolean = true;

  constructor(private api: ApiService, private notify: LexiNotifyService) { }

  ngOnInit(): void {
    this.init()
  }

  async init(){
    this.users = await this.api.post("/setting/get/all-users", {})
    this.userList = this.users;
  }

  async getUserData() {
    this.isLoading = true;
    const result = await this.api.post("/setting/get/user/detail", {user_id: this.data.user_id})
    if(result){
      this.data = {...this.data, ...result}
    }

    this.isLoading = false;
  }

  search(e:any) {
    if(!e || e == undefined) {
      this.users = this.userList;
      return;
    }
    let val = e.toLowerCase()
    const temp = this.userList.filter((x:any) => {
      if (x['name'].toLowerCase().indexOf(val) !== -1 || !val) {
        return x;
      }
    });
    this.users = temp;
  }

  select(dataKey:any, dataValue:any, item:any) {
    console.log(item)
    this.keyword = item[dataKey];
    this.value = item[dataKey];
    this.data.user_id = item[dataValue]

    this.getUserData()
    this.shown = false;
  }


  show() {
    this.shown = !this.shown;
    this.keyword = "";

    setTimeout(() => {
    }, 0);
  }

  async submit() {
    this.btnLoading = true;
    const result = await this.api.post("/task/save/worker-rate", this.data)
    this.btnLoading = false;
    if(!result.status){
      return this.notify.error(result.message)
    }

    this.notify.success("Submit successful.")
  }

}
