<div class="d-flex justify-content-between">
    <h3 class="mb-0"><b>{{data.title}}</b></h3>
    <div class="d-flex flex-rows">
        <div class="col">
            <span class="text-right">
                <h5 class="mb-0"><b>{{data.user.name}}</b></h5>
                <h5 class="text-muted">{{data.user.position}}</h5>
            </span>
        </div>
        <div class="col-3">
            <button class="btn btn-success" (click)="exportCSV()">Export</button>
        </div>
    </div>
</div>
<hr>


<div class="card">
    <div class="card-body p-0">
        <ng-container *ngIf="isLoading">
            <lib-dot></lib-dot>
        </ng-container>

        <div class="table-responsive" *ngIf="!isLoading">
            <table class="table table-sm table-hover table-striped" style="font-size: 80%; white-space:nowrap">
                <thead class="table-dark thead-sticky">
                    <tr>
                        <th scope="col" style="position: sticky; left: 0; width:33px; z-index: 3;">No</th>
                        <th scope="col" style="position: sticky; left: 33px; width: 87px; z-index: 3;">Job No</th>
                        <th scope="col" style="position: sticky; left: 120px; width:100px; z-index: 3;">QTT/PR No</th>
                        <th scope="col" style="position: sticky; left: 220px; width:auto; z-index: 3;">INV No</th>
                        <th scope="col">Customer Code</th>
                        <th scope="col">Contact Person</th>
                        <th scope="col">Address</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let list of listing; let i = index;">
                        <td style="position: sticky; left: 0; width:33px; background:#eee; z-index: 3;">{{i+1}}</td>
                        <td style="position: sticky; left: 33px; width: 87px; background:#eee; z-index: 3;">{{list.reference_no}}</td>
                        <td style="position: sticky; left: 120px; width:100px;  background:#eee; z-index: 3;">{{list.sql_doc_no}}</td>
                        <td style="position: sticky; left: 220px; width:auto;  background:#eee; z-index: 3;">{{list.sql_inv_no}}</td>
                        <td>{{list.customer_code}}</td>
                        <td>{{list.entity_name}} ({{list.entity_contact}})</td>
                        <td>{{list.address}}</td>
                        <td>
                            <button class="btn btn-outline-primary btn-sm" [routerLink]="['/detail/task', list.uuid, 'view', 'task', list.uuid]">View</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>