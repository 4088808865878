<nav class="d-flex justify-content-between mb-3">
    <h4 class="m-0">Project Progress</h4>
    <span>
        <button class="btn btn-dark btn-lg me-2" [routerLink]="['/task-schedule/progress/leader/report']">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-bar-graph" viewBox="0 0 16 16">
                <path d="M10 13.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-6a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zm-2.5.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5zm-3 0a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5z"/>
                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
            </svg>
            <span class="ms-2">Leader Report</span>
        </button>
        <!-- <button class="btn btn-dark btn-lg me-2" [routerLink]="['/task-schedule/progress/grab']">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-rocket-takeoff" viewBox="0 0 16 16">
                <path d="M9.752 6.193c.599.6 1.73.437 2.528-.362s.96-1.932.362-2.531c-.599-.6-1.73-.438-2.528.361-.798.8-.96 1.933-.362 2.532"/>
                <path d="M15.811 3.312c-.363 1.534-1.334 3.626-3.64 6.218l-.24 2.408a2.56 2.56 0 0 1-.732 1.526L8.817 15.85a.51.51 0 0 1-.867-.434l.27-1.899c.04-.28-.013-.593-.131-.956a9 9 0 0 0-.249-.657l-.082-.202c-.815-.197-1.578-.662-2.191-1.277-.614-.615-1.079-1.379-1.275-2.195l-.203-.083a10 10 0 0 0-.655-.248c-.363-.119-.675-.172-.955-.132l-1.896.27A.51.51 0 0 1 .15 7.17l2.382-2.386c.41-.41.947-.67 1.524-.734h.006l2.4-.238C9.005 1.55 11.087.582 12.623.208c.89-.217 1.59-.232 2.08-.188.244.023.435.06.57.093q.1.026.16.045c.184.06.279.13.351.295l.029.073a3.5 3.5 0 0 1 .157.721c.055.485.051 1.178-.159 2.065m-4.828 7.475.04-.04-.107 1.081a1.54 1.54 0 0 1-.44.913l-1.298 1.3.054-.38c.072-.506-.034-.993-.172-1.418a9 9 0 0 0-.164-.45c.738-.065 1.462-.38 2.087-1.006M5.205 5c-.625.626-.94 1.351-1.004 2.09a9 9 0 0 0-.45-.164c-.424-.138-.91-.244-1.416-.172l-.38.054 1.3-1.3c.245-.246.566-.401.91-.44l1.08-.107zm9.406-3.961c-.38-.034-.967-.027-1.746.163-1.558.38-3.917 1.496-6.937 4.521-.62.62-.799 1.34-.687 2.051.107.676.483 1.362 1.048 1.928.564.565 1.25.941 1.924 1.049.71.112 1.429-.067 2.048-.688 3.079-3.083 4.192-5.444 4.556-6.987.183-.771.18-1.345.138-1.713a3 3 0 0 0-.045-.283 3 3 0 0 0-.3-.041Z"/>
                <path d="M7.009 12.139a7.6 7.6 0 0 1-1.804-1.352A7.6 7.6 0 0 1 3.794 8.86c-1.102.992-1.965 5.054-1.839 5.18.125.126 3.936-.896 5.054-1.902Z"/>
              </svg>
            <span class="ms-2">Project To Grab</span>
        </button> -->
        <button class="btn btn-warning btn-lg" [routerLink]="['/task-schedule/progress/calendar']">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-week" viewBox="0 0 16 16">
                <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z"/>
                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
            </svg>
            <span class="ms-2">Progress Calendar</span>
        </button>
    </span>
</nav>
<div class="d-flex mb-3">
    <input class="form-control form-control-sm w-100" type="text" placeholder="Search something... (ERP Doc No / SQL Doc No / Profile Code / Contact No / Invoice No)" [(ngModel)]="filterData.search" name="search" appInputMonitor (ngModelChange)="getSearch()">
    <button class="btn btn-primary btn-sm ms-2" style="align-self: flex-end;" (click)="getFilter()">Filter</button>
    <button class="btn btn-dark btn-sm ms-2" style="align-self: flex-end;" (click)="getReset()">Reset</button>
</div>


<div class="card">
    <div class="card-body p-0">
        <ng-container *ngIf="isLoading">
            <lib-bar></lib-bar>
        </ng-container>
        <div class="table-responsive" *ngIf="!isLoading">
            <table class="table table-sm table-hover table-striped" style="font-size: 80%; white-space:nowrap">
                <thead class="thead-dark thead-sticky">
                    <tr>
                        <th scope="col" style="position: sticky; left: 0; width:33px; z-index: 3;">No</th>
                        <th scope="col" style="position: sticky; left: 33px; width: 87px; z-index: 3;">ERP Doc No / SQL Doc No</th>
                        <th scope="col" style="position: sticky; left: 120px; z-index: 3;">Customer</th>
                        <th scope="col">Area</th>
                        <th scope="col">Status</th>
                        <th scope="col">Net Total</th>
                        <th scope="col">Invoice No</th>
                        <th scope="col">Type of Work</th>
                        <th scope="col" style="width:200px">Assign To</th>
                        <th scope="col">Start Date</th>
                        <th scope="col">Working Day</th>
                        <th scope="col">Manpower</th>
                        <th scope="col">Remark</th>
                        <th scope="col">Deposit Received</th>
                        <th scope="col">Deposit Received Month</th>
                        <th scope="col">Payment Remark</th>
                        <th scope="col">Qtt Signed</th>
                        <th scope="col">M.O Status</th>
                        <th scope="col">Insurance</th>
                        <th scope="col">Send Survey Form</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let list of listing; let i = index;" [class.selected]="(i+1)=== selectedRowIdx" (click)="onRowClick(i+1)">
                        <td style="position: sticky; left: 0; width:33px; background:#eee; z-index: 3;">{{i+1}}</td>
                        <td style="position: sticky; left: 33px; width: 87px; background:#eee; z-index: 3;">
                            <span>{{list.doc_no}} <br></span>
                            <small *ngIf="list.sql_reference_no" class="text-primary"><b>(SQL)</b> {{list.sql_reference_no}}<br></small>
                            <span class="badge badge-warning">{{list.is_additional == 0 ? 'MAiN' : "VO"}}</span>
                        </td>
                        <td style="position: sticky; left: 120px; width:auto;  background:#eee; z-index: 3;">
                            <span><small>Profile Code : </small> <b>{{list.customer.code}}</b></span><br>
                            <span><small>Contact Person : </small> <b>{{list.customer_entity.name}}</b></span><br>
                            <span><small>Contact No : </small> <b>{{list.customer_entity.mobile}}</b></span>
                        </td>
                        <td>
                            <span>{{list.customer_address.area}}</span><br>
                            <span>{{list.customer_address.state}}</span>
                        </td>
                        <td>
                            <!-- <span>{{list.main_status_title}}</span><br>
                            <span>{{list.child_status_title}}</span> -->
                            <select class="form-control" style="width: 100px; height: 37px;" [(ngModel)]="list.work_status" name="work_status[i]" appInputMonitor (inputChange)="updateTask(list, 'work_status')">
                                <option>Follow Up</option>
                                <option>Pending Client Confirm Date</option>
                                <option>Confirmed</option>
                                <option>Work Started</option>
                                <option>Cancelled</option>
                            </select>

                        </td>
                        <td>{{list.total_payable | number: '1.2-2'}}</td>
                        <td>{{list.invoice_no ?? '-'}}</td>
                        <td class="p-0">
                            <input class="form-control" style="width: 150px; height: 37px;" type="text" [(ngModel)]="list.type_of_work" name="type_of_work[i]" appInputMonitor (inputChange)="updateTask(list, 'type_of_work')">
                        </td>
                        <td class="p-0 px-1">
                            <div class="input-group" style="width: 250px; height: 37px;">
                                <div class="search-dropdown flex-grow-1" [ngClass]="{'open':list.shown && userList !== undefined && userList?.length !== 0}">
                                    <div class="search-dropdown__btn" (click)="show(list)"><span>{{list.value}}</span></div>
                                    <div class="search-dropdown__dropdown">
                                        <input class="search-dropdown__dropdown__search" type="text" placeholder="Search" [(ngModel)]="list.keyword" name="list.keyword" (keyup)="search(list.keyword)" #input/>
                                        <input type="hidden" [id]="'assign_to'" [name]="'assign_to'" class="form-control" [value]="list.assign_to"/>
                                        <div class="search-dropdown__dropdown__scroller">
                                            <div class="search-dropdown__dropdown__item" *ngFor="let ee of users" (click)="select('name', 'user_id', ee, list)">
                                            {{ee.name}}
                                            </div>
                                            <div class="search-dropdown__dropdown__msg" *ngIf="userList?.length == 0">No Result Found</div>
                                        </div>
                                    </div>
                                </div>
                                <button class="btn btn-danger p-2" (click)="toggleAction('cancelAssign', list)">
                                    <svg xmlns="http://www.w3.org/2000/svg" style="width: 18px; height: 18px;" fill="currentColor" class="bi bi-person-x" viewBox="0 0 16 16">
                                        <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m.256 7a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z"/>
                                        <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m-.646-4.854.646.647.646-.647a.5.5 0 0 1 .708.708l-.647.646.647.646a.5.5 0 0 1-.708.708l-.646-.647-.646.647a.5.5 0 0 1-.708-.708l.647-.646-.647-.646a.5.5 0 0 1 .708-.708"/>
                                    </svg>
                                </button>

                            </div>
                            
                        </td>
                        <td class="p-0">
                            <input class="form-control" style="height: 37px;" type="date" [(ngModel)]="list.start_work_date" name="start_work_date[i]" appInputMonitor (inputChange)="updateTask(list, 'start_work_date')">
                        </td>
                        <td>{{list.notes.work_period ?? '-'}}</td>
                        <td>{{list.notes.man_prower ?? '-'}}</td>
                        <td class="p-0">
                            <textarea class="form-control" style="width: 300px;" rows="2" [(ngModel)]="list.progress_remark" name="progress_remark[i]" appInputMonitor (inputChange)="updateTask(list, 'progress_remark')"></textarea>
                        </td>
                        <td class="p-0">
                            <div class="input-group" style="width: 200px; height: 37px;">
                                <select class="form-control" style="height: 37px;" [(ngModel)]="list.deposit_status" name="deposit_status[i]" appInputMonitor (inputChange)="updateTask(list, 'deposit_status')">
                                    <option>Yes</option>
                                    <option>No</option>
                                    <option>One off Credit Card</option>
                                    <option>Installment Credit Card</option>
                                    <option>Cash after work completion</option>
                                    <option>Cheque</option>
                                    <option>LOG Signed</option>
                                </select>
                                <button class="btn btn-warning btn-sm py-0" (click)="selectMedia(list, 'deposit_img', list.task_uuid)">&#8942;</button>
                            </div>
                        </td>
                        <td class="p-0">
                            <input class="form-control" style="height: 37px;" type="month" [(ngModel)]="list.deposit_month" name="deposit_month[i]" appInputMonitor (inputChange)="updateTask(list, 'deposit_month')">
                        </td>
                        <td class="p-0">
                            <textarea class="form-control" style="width: 300px;" rows="2" [(ngModel)]="list.payment_remark" name="payment_remark[i]" appInputMonitor (inputChange)="updateTask(list, 'payment_remark')"></textarea>
                        </td>
                        <td class="p-0">
                            <div class="input-group" style="width: 200px; height: 37px;">
                                <select class="form-control" style="height: 37px;" [(ngModel)]="list.quotation_signed_status" name="quotation_signed_status[i]" appInputMonitor (inputChange)="updateTask(list, 'quotation_signed_status')">
                                    <option>Yes</option>
                                    <option>No</option>
                                </select>
                                <button class="btn btn-warning btn-sm py-0" (click)="selectMedia(list, 'qtt_signed_img', list.task_uuid)">&#8942;</button>
                            </div>
                        </td>
                        <td class="p-0">
                            <div class="input-group" style="width: 200px; height: 37px;">
                                <select class="form-control" style="height: 37px;" [(ngModel)]="list.mo_status" name="mo_status[i]" appInputMonitor (inputChange)="updateTask(list, 'mo_status')">
                                    <option>Pending Order</option>
                                    <option>Pending client confirm date</option>
                                    <option>Pending client payment</option>
                                    <option>Done</option>
                                    <option>Not Applicable</option>
                                </select>
                                <button class="btn btn-dark btn-sm py-0" (click)="generateInquiry(list)">+</button>
                            </div>
                            
                        </td>
                        <td class="p-0">
                            <select class="form-control" style="width: 100px; height: 37px;" [(ngModel)]="list.insurance_status" name="insurance_status[i]" appInputMonitor (inputChange)="updateTask(list, 'insurance_status')">
                                <option>Yes</option>
                                <option>No</option>
                            </select>
                        </td>
                        <td>{{list.is_sent == 1 ? "YES" : "NO"}}</td>
                        <td class="p-0">
                            <div class="d-flex">
                                <button class="btn btn-primary btn-sm" [routerLink]="['/detail/task', list.task_uuid, 'list', 'proforma', list.task_uuid]" [disabled]="isBtnLoading">
                                    <span *ngIf="!isBtnLoading">View</span>
                                    <!-- <span *ngIf="isBtnLoading"><lib-dot></lib-dot></span> -->
                                    <span *ngIf="isBtnLoading"></span>
                                </button>
                                <button class="btn btn-success btn-sm ms-1" *ngIf="list.is_complete == 0" (click)="toggleAction('complete', list)" [disabled]="isBtnLoading">
                                    <span *ngIf="!isBtnLoading">Complete</span>
                                    <!-- <span *ngIf="isBtnLoading"><lib-dot></lib-dot></span> -->
                                    <span *ngIf="isBtnLoading"></span>
                                </button>
                                <button class="btn btn-danger btn-sm ms-1" *ngIf="list.is_complete == 1" (click)="toggleAction('cancelComplete', list)" [disabled]="isBtnLoading">
                                    <span *ngIf="!isBtnLoading">Cancel Complete</span>
                                    <!-- <span *ngIf="isBtnLoading"><lib-dot></lib-dot></span> -->
                                    <span *ngIf="isBtnLoading"></span>
                                </button>
                                <button class="btn btn-secondary btn-sm ms-1" *ngIf="list.is_converted == 0" (click)="toggleAction('convertInvoice', list, 'manual_convert')" [disabled]="isBtnLoading">
                                    <span *ngIf="!isBtnLoading">Convert to Invoice</span>
                                    <!-- <span *ngIf="isBtnLoading"><lib-dot></lib-dot></span> -->
                                    <span *ngIf="isBtnLoading"></span>
                                </button>
                                <button class="btn btn-black btn-sm ms-1" *ngIf="list.is_converted == 0" (click)="toggleAction('convertInvoice', list, 'live_convert')" [disabled]="isBtnLoading">
                                    <span *ngIf="!isBtnLoading">Convert to Invoice (LIVE)</span>
                                    <!-- <span *ngIf="isBtnLoading"><lib-dot></lib-dot></span> -->
                                    <span *ngIf="isBtnLoading"></span>
                                </button>
                                <button class="btn btn-dark btn-sm ms-1" *ngIf="list.is_complete == 1" (click)="toggleAction('sendSurveyForm', list)" [disabled]="isBtnLoading">
                                    <span *ngIf="!isBtnLoading">Send Survey Form</span>
                                    <!-- <span *ngIf="isBtnLoading"><lib-dot></lib-dot></span> -->
                                    <span *ngIf="isBtnLoading"></span>
                                </button>

                                <!-- <button class="btn btn-outline-success btn-sm ms-1" *ngIf="list.is_complete == 0 && !list.assign_to" (click)="toggleAction('postToGrab', list)" [disabled]="isBtnLoading">
                                    <span *ngIf="!isBtnLoading">Ready To Grab</span>
                                    <span *ngIf="isBtnLoading"></span>
                                </button> -->
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr class="bg-dark text-white">
                        <th scope="col" style="position: sticky; left: 0; width:33px; background:#eee; z-index: 3;"></th>
                        <th scope="col" style="position: sticky; left: 33px; width: 87px; background:#eee; z-index: 3;"></th>
                        <th scope="col" style="position: sticky; left: 120px; width:auto; background:#eee; z-index: 3;"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col">{{sumAmount | number: '1.2-2'}}</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                    </tr>
                </tfoot>
            </table>
        </div>

        <!-- <div class="d-flex flex-row justify-content-end pb-2 align-items-center small">
            <div class="btn-group" role="group">
                <button type="button" class="btn btn-light" [disabled]="data.pages.total_page == 1 || data.pages.current_page == 1" (click)="previous()">&laquo;</button>
                <button type="button" class="btn" [hidden]="data.pages.current_page -2 <= 0" (click)="selectedPage(data.pages.current_page -2)">{{data.pages.current_page -2}}</button>
                <button type="button" class="btn" [hidden]="data.pages.current_page -1 <= 0" (click)="selectedPage(data.pages.current_page -1)">{{data.pages.current_page -1}}</button>
                <button type="button" class="btn active" (click)="selectedPage(data.current_page)">{{data.pages.current_page}}</button>
                <button type="button" class="btn" [hidden]="data.pages.current_page +1 > data.pages.total_page" (click)="selectedPage(data.pages.current_page +1)">{{data.pages.current_page + 1}}</button>
                <button type="button" class="btn" [hidden]="data.pages.current_page +2 > data.pages.total_page" (click)="selectedPage(data.pages.current_page +2)">{{data.pages.current_page + 2}}</button>
                <button type="button" class="btn btn-light" [disabled]="data.pages.total_page == 1 || data.pages.current_page == data.pages.total_page" (click)="next()">&raquo;</button>
            </div>
        </div> -->
    </div>
</div>

<!-- <button class="btn btn-sm btn-outline-dark p-1 mt-5" (click)="dev_getCollection()">Developer TEST</button> -->