<div class="modal-body">
    <h5><b>Project Costing Complete Confirmation</b></h5>
    <hr>

    <p>Are you sure you want to mark this project costing as complete?
        <br>
        <em class="text-danger"><small>** After costing have been saved, amendment could not be allowed. Please take note.</small></em>
    </p>
    <p>
        <span>Job No. : <b>{{data.task_detail.reference_no}}</b></span>
        <br>
        <span>Total Company Profit : <b>RM {{data.costing.total_companyProfit | number: "1.2-2"}}</b> ({{data.figure_percent.totalCompanyProfitPercent | number: "1.2-2"}} %)</span>
    </p>

</div>
<div class="modal-footer">
    <button class="btn btn-success btn-sm w-100" (click)="submit()">YES</button>
    <button class="btn btn-danger btn-sm w-100" (click)="closeModal()">NO</button>
</div>


