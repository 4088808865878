
<div class="d-flex flex-row justify-content-between pb-2 align-items-center">
    <h4 class="m-0">{{title}}</h4>
    <span>
        <button type="button" class="btn btn-sm btn-primary me-2" (click)="selectMedia()">Select Media</button>    
        <button type="button" class="btn btn-sm btn-secondary" (click)="submit()">Save</button>   
    </span> 
</div>

<ng-container *ngIf="isLoading">
    <div class="py-4">
        <lib-bar></lib-bar>
    </div>
</ng-container>

<ng-container *ngIf="!isLoading">
    <div class="row" *ngIf="images && images.length > 0">
        <div class="col-6 col-sm-6 col-md-3" *ngFor="let img of images; let i = index">
            <div class="card border-0 h-100">
                <span class="badge badge-primary d-none position-absolute">Default</span>
                <div class="card-body border-0">
                    <ng-container *ngIf="img.file_type == 'png' || img.file_type == 'jpg' || img.file_type == 'peg' || img.file_type == 'webp' || img.file_type == 'jpeg'">
                        <img *ngIf="img.image || img.file" class="w-100" [src]="img.image ?? img.file" (click)="viewImg(content, (img.image ?? img.file))">
                    </ng-container>
                    <ng-container *ngIf="img.file_type == 'pdf'">
                        <div (click)="viewImg(content, (img.image ?? img.file))">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12.819 14.427c.064.267.077.679-.021.948-.128.351-.381.528-.754.528h-.637v-2.12h.496c.474 0 .803.173.916.644zm3.091-8.65c2.047-.479 4.805.279 6.09 1.179-1.494-1.997-5.23-5.708-7.432-6.882 1.157 1.168 1.563 4.235 1.342 5.703zm-7.457 7.955h-.546v.943h.546c.235 0 .467-.027.576-.227.067-.123.067-.366 0-.489-.109-.198-.341-.227-.576-.227zm13.547-2.732v13h-20v-24h8.409c4.858 0 3.334 8 3.334 8 3.011-.745 8.257-.42 8.257 3zm-12.108 2.761c-.16-.484-.606-.761-1.224-.761h-1.668v3.686h.907v-1.277h.761c.619 0 1.064-.277 1.224-.763.094-.292.094-.597 0-.885zm3.407-.303c-.297-.299-.711-.458-1.199-.458h-1.599v3.686h1.599c.537 0 .961-.181 1.262-.535.554-.659.586-2.035-.063-2.693zm3.701-.458h-2.628v3.686h.907v-1.472h1.49v-.732h-1.49v-.698h1.721v-.784z"/></svg>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="img.file_type == 'mp4' || img.file_type == 'mov' || img.file_type == 'ime'">
                        <div (click)="viewImg(content, (img.image ?? img.file))">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 1v22h24v-22h-24zm4 20h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2v-2h2v2zm14 12h-12v-10h12v10zm4 4h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2v-2h2v2zm-12 10v-6l5 3-5 3z"/></svg>
                        </div>
                    </ng-container>
                    <p class="text-muted file-name w-100 text-center my-1">{{getFileNameFromUrl(img.image ?? img.file)}}</p>
                    <div class="card-footer border-0 p-0" *ngIf="dataService?.checkIsHod() == 'true'">
                        <button class="btn btn-danger btn-sm w-100" (click)="removeImage(img, i)">Remove</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="!images || images.length == 0">
        <div class="py-4 text-muted">
            <p>No record found.</p>
        </div>
    </ng-container>

</ng-container>


<ng-template #content let-modal>
	<div class="modal-body">
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
        <ng-container *ngIf="preview_type == 'png' || preview_type == 'jpg' || preview_type == 'peg'">
            <img class="w-100 mb-4" [src]="preview" id="popupImage">
        </ng-container>
		<ng-container *ngIf="preview_type == 'mp4' || preview_type == 'mov' || preview_type == 'ime'">
            <video width="100%" height="auto" controls><source [src]="preview" type="video/mp4"></video>
        </ng-container>
	</div>
</ng-template>
