<div class="d-flex flex-row justify-content-between pb-2 align-items-center">
    <h4 class="m-0">Daily Record</h4>
    <h4 class="m-0">{{todayDate}}</h4>
</div>

<div class="card mt-4">
    <div class="card-header bg-dark"><h5 class="mb-0 text-white">Vehicle or Machine</h5></div>
    <table class="table border mb-0">
        <thead>
            <tr>
                <th></th>
                <th>Description</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Subtotal</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let vehicle of data.vehicles; let i = index;">
                <td style="width:35px;" class="p-0">
                    <button class="btn border-0" (click)="addNewRow(data.vehicles)">
                        <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m21 3.998c0-.478-.379-1-1-1h-16c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379 1-1zm-16.5.5h15v15h-15zm6.75 6.752h-3.5c-.414 0-.75.336-.75.75s.336.75.75.75h3.5v3.5c0 .414.336.75.75.75s.75-.336.75-.75v-3.5h3.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-3.5v-3.5c0-.414-.336-.75-.75-.75s-.75.336-.75.75z" fill-rule="nonzero"/></svg>
                    </button>
                </td>
                <td class="p-0 border-start">
                    <input class="form-control form-control-sm border-0 rounded-0" type="text" placeholder="Enter Description" [(ngModel)]="vehicle.descp">
                </td>
                <td style="width:10%" class="p-0 border-start">
                    <input type="number" class="form-control form-control-sm border-0 rounded-0" name="appointment_date" [(ngModel)]="vehicle.price" (input)="calculateRowSubtotal(vehicle)"/>
                </td>
                <td style="width:10%" class="p-0 border-start">
                    <input type="number" class="form-control form-control-sm border-0 rounded-0" name="appointment_date" [(ngModel)]="vehicle.quantity" (input)="calculateRowSubtotal(vehicle)"/>
                </td>
                <td style="width:10%" class="p-0 border-start">
                    <input type="number" class="form-control form-control-sm border-0 rounded-0" readonly [(ngModel)]="vehicle.subtotal" />
                </td>
                <td style="width:35px;" class="p-0 border-start">
                    <button class="btn border-0" (click)="removeRow(data.vehicles, i)" *ngIf="data.vehicles.length > 1">
                        <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m4.015 5.494h-.253c-.413 0-.747-.335-.747-.747s.334-.747.747-.747h5.253v-1c0-.535.474-1 1-1h4c.526 0 1 .465 1 1v1h5.254c.412 0 .746.335.746.747s-.334.747-.746.747h-.254v15.435c0 .591-.448 1.071-1 1.071-2.873 0-11.127 0-14 0-.552 0-1-.48-1-1.071zm14.5 0h-13v15.006h13zm-4.25 2.506c-.414 0-.75.336-.75.75v8.5c0 .414.336.75.75.75s.75-.336.75-.75v-8.5c0-.414-.336-.75-.75-.75zm-4.5 0c-.414 0-.75.336-.75.75v8.5c0 .414.336.75.75.75s.75-.336.75-.75v-8.5c0-.414-.336-.75-.75-.75zm3.75-4v-.5h-3v.5z" fill-rule="nonzero"/></svg>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="card mt-4">
    <div class="card-header bg-dark"><h5 class="mb-0 text-white">Workers</h5></div>
    <table class="table border mb-0">
        <thead>
            <tr>
                <th></th>
                <th>Description</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Subtotal</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let worker of data.workers; let i = index;">
                <td style="width:35px;" class="p-0">
                    <button class="btn border-0" (click)="addNewRow(data.workers)">
                        <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m21 3.998c0-.478-.379-1-1-1h-16c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379 1-1zm-16.5.5h15v15h-15zm6.75 6.752h-3.5c-.414 0-.75.336-.75.75s.336.75.75.75h3.5v3.5c0 .414.336.75.75.75s.75-.336.75-.75v-3.5h3.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-3.5v-3.5c0-.414-.336-.75-.75-.75s-.75.336-.75.75z" fill-rule="nonzero"/></svg>
                    </button>
                </td>
                <td class="p-0 border-start">
                    <input class="form-control form-control-sm border-0 rounded-0" type="text" placeholder="Enter Description" [(ngModel)]="worker.descp">
                </td>
                <td style="width:10%" class="p-0 border-start">
                    <input type="number" class="form-control form-control-sm border-0 rounded-0" name="appointment_date" [(ngModel)]="worker.price" (input)="calculateRowSubtotal(worker)"/>
                </td>
                <td style="width:10%" class="p-0 border-start">
                    <input type="number" class="form-control form-control-sm border-0 rounded-0" name="appointment_date" [(ngModel)]="worker.quantity" (input)="calculateRowSubtotal(worker)"/>
                </td>
                <td style="width:10%" class="p-0 border-start">
                    <input type="number" class="form-control form-control-sm border-0 rounded-0" readonly [(ngModel)]="worker.subtotal" />
                </td>
                <td style="width:35px;" class="p-0 border-start">
                    <button class="btn border-0" (click)="removeRow(data.workers, i)" *ngIf="data.workers.length > 1">
                        <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m4.015 5.494h-.253c-.413 0-.747-.335-.747-.747s.334-.747.747-.747h5.253v-1c0-.535.474-1 1-1h4c.526 0 1 .465 1 1v1h5.254c.412 0 .746.335.746.747s-.334.747-.746.747h-.254v15.435c0 .591-.448 1.071-1 1.071-2.873 0-11.127 0-14 0-.552 0-1-.48-1-1.071zm14.5 0h-13v15.006h13zm-4.25 2.506c-.414 0-.75.336-.75.75v8.5c0 .414.336.75.75.75s.75-.336.75-.75v-8.5c0-.414-.336-.75-.75-.75zm-4.5 0c-.414 0-.75.336-.75.75v8.5c0 .414.336.75.75.75s.75-.336.75-.75v-8.5c0-.414-.336-.75-.75-.75zm3.75-4v-.5h-3v.5z" fill-rule="nonzero"/></svg>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<button class="btn btn-sm btn-primary w-100 mt-4">Save</button>

