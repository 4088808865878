import { Component, OnInit } from '@angular/core';
import { ApiService } from 'lexi-api';
import { LexiNotifyService } from 'lexi-notify';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lib-temp-project',
  templateUrl: './temp-project.component.html',
  styleUrls: ['./temp-project.component.css']
})
export class TempProjectComponent implements OnInit {

  public listing:any = [];
  public isLoading:Boolean = false;
  public currentActive:any = "new";

  constructor(private api: ApiService, private notify: LexiNotifyService) { }

  ngOnInit(): void {
    this.initData()
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.currentActive = changeEvent.nextId;
    this.initData()
	}

  async initData() {
    this.isLoading = true;
    this.listing = await this.api.post("/setting/get/temp-project", {status: this.currentActive})
    this.isLoading = false;
  }

  async statusChange(data:any) {
    this.isLoading = true;
    await this.api.post("/setting/update/temp-project/status", data)
    this.notify.success("Project status change complete.")
    this.initData()
  }

  typeConverter(type:string): any {
    switch(type) {
      case "on_going":
        return "On Going";
      case "after_care":
        return "After Care";
      case "inspection":
        return "Inspection";
    }
  }


}
