import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'lexi-api';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'lib-task-project-filter',
  templateUrl: './task-project-filter.component.html',
  styleUrls: ['./task-project-filter.component.css']
})
export class TaskProjectFilterComponent implements OnInit {

  @Input() set setFilter (val:any) {
    this.data = val
  }
  @Output() retrieveFilter = new EventEmitter();

  public data:any = {}
  public users:any = [];
  public userList:any = [];
  public keyword:any | undefined = "";
  public value:any | undefined;
  public shown = false;
  public statusOption:any = []
  public depositStatusOptions:any = [];
  public qttSignedStatusOptions:any = [];
  public moStatusOptions:any = [];
  public insuranceStatusOptions:any = [];

  dropdownList:any = [];
  selectedItems:any = [];
  dropdownSettings:IDropdownSettings = {};

  constructor(private api: ApiService) { 
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'val',
      textField: 'title',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true
    };

    this.statusOption = [
      {title: 'Follow Up', val: 'Follow Up'},
      {title: 'Pending Client Confirm Date', val: 'Pending Client Confirm Date'},
      {title: 'Confirmed', val: 'Confirmed'},
      {title: 'Work Started', val: 'Work Started'},
      {title: 'Cancelled', val: 'Cancelled'}
    ]

    this.depositStatusOptions = [
      {title: 'Yes', val: 'Yes'}, 
      {title: 'No', val: 'No'},
      {title: 'One off Credit Card', val: 'One off Credit Card'},
      {title: 'Installment Credit Card', val: 'Installment Credit Card'},
      {title: 'Cash after work completion', val: 'Cash after work completion'},
      {title: 'Cheque', val: 'Cheque'},
      {title: 'LOG Signed', val: 'LOG Signed'}
    ]

    this.qttSignedStatusOptions = [
      {title: "Yes", val: "Yes"},
      {title: "No", val: "No"}
    ]

    this.moStatusOptions = [
      {title: "Pending Order", val: "Pending Order"},
      {title: "Pending client confirm date", val: "Pending client confirm date"},
      {title: "Pending client payment", val: "Pending client payment"},
      {title: "Done", val: "Done"},
      {title: "Not Applicable", val: "Not Applicable"}
    ]

    this.insuranceStatusOptions = [
      {title: "Yes", val: "Yes"},
      {title: "No", val: "No"}
    ]
  }

  ngOnInit(): void {
    this.initUsers()
  }

  async initUsers() {
    this.users = await this.api.post("/setting/get/users/bySiteLeader", {})
    this.userList = this.users;

    this.initVal()
  }

  async initVal() {
    if(this.data.assign_to && this.data.assign_to == 'unassigned'){
      this.select("unassigned", "unassigned")
      return;
    }

    const val = this.userList.find((x:any) => {
      return x.user_id == this.data.assign_to
    });
    if(val){
      this.select("name", "user_id", val)
    }
  }

  select(dataKey:any, dataValue:any, item?:any) {
    if(dataValue == 'unassigned'){
      this.keyword = dataKey
      this.value = dataKey
      this.data.assign_to = dataValue
    }else{
      this.keyword = item[dataKey];
      this.value = item[dataKey];
      this.data.assign_to = item[dataValue]
    }

    this.shown = false;
  }

  show() {
    this.shown = !this.shown;
    this.keyword = "";

    setTimeout(() => {
    }, 0);
  }

  search(e:any) {
    if(!e || e == undefined) {
      this.users = this.userList;
      return;
    }
    let val = e.toLowerCase()
    const temp = this.userList.filter((x:any) => {
      if (x['name'].toLowerCase().indexOf(val) !== -1 || !val) {
        return x;
      }
    });
    this.users = temp;
  }

  clear_assignTo() {
    this.keyword = null;
    this.value = null;
    delete this.data.assign_to;
  }


  onItemSelect(item: any) {
  }

  onSelectAll(items: any) {
  }

  onDeSelect(item: any) {
  }

  onDeSelectAll(items: any) {
  }

  async submit() {
    if (this.data.deposit_status != undefined && this.data.deposit_status.length == 0) {
      this.data.deposit_status = undefined;
    }
    if (this.data.insurance_status != undefined && this.data.insurance_status.length == 0) {
      this.data.insurance_status = undefined;
    }
    if (this.data.mo_status != undefined && this.data.mo_status .length == 0) {
      this.data.mo_status = undefined;
    }
    if (this.data.quotation_signed_status != undefined && this.data.quotation_signed_status.length == 0) {
      this.data.quotation_signed_status = undefined;
    }
    if (this.data.work_status != undefined && this.data.work_status.length == 0) {
      this.data.work_status = undefined;
    }
    this.retrieveFilter.emit(this.data)
  }

  closeModal() {
    this.retrieveFilter.emit(false)
  }
}
