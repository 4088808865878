import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'lexi-api';
import { LexiNotifyService } from 'lexi-notify';

@Component({
  selector: 'lib-temp-project-detail',
  templateUrl: './temp-project-detail.component.html',
  styleUrls: ['./temp-project-detail.component.css']
})
export class TempProjectDetailComponent implements OnInit {

  public data:any = {};
  public users:any = [];
  public departments:any = [];
  public uuid:any;
  public btnLoading:boolean = false;

  public userList:any = [];
  public deptList:any = [];
  public keyword:any = "";
  public value:any;
  public shown = false;

  constructor(private api: ApiService, private notify: LexiNotifyService, private activatedRoute: ActivatedRoute) {
    this.uuid = this.activatedRoute.snapshot.paramMap.get('uuid')
    if(this.uuid !== 'new'){
      this.initData();
    }
  }

  ngOnInit(): void {
    this.initUsers()
  }

  async initData() {
    const result = await this.api.post("/setting/get/temp-project", {uuid: this.uuid})
    if(result && result.length > 0){
      this.data = result[0]
    }
  }

  async initUsers() {
    this.departments = await this.api.post("/setting/get/site-user/by-department", {})
    console.log(this.departments)
    this.users = await this.api.post("/setting/get/all-users", {})
    this.deptList = this.departments;
    this.userList = this.users;

    this.initVal()
  }

  async initVal() {
    if(this.data.type == "inspection"){
      const val = this.userList.find((x:any) => {
        return x.user_id == this.data.assign_to
      });
      this.select("name", "user_id", val)

    }else{
      const val = this.deptList.find((x:any) => {
        return x.id == this.data.assign_to
      });
      this.select("descp", "id", val)
    }
  }

  async submit() {
    this.btnLoading = true;
    const result = await this.api.post("/setting/save/temp-project", this.data)
    this.btnLoading = false;
    if(!result.status){
      return this.notify.error(result.message)
    }

    this.notify.success("Submit successful.")
    this.data = {}
  }

  onChangeValue() {
    this.keyword = "";
    this.value = "";
    this.shown = false;
  }

  search(e:any) {
    if(!e || e == undefined) {
      this.users = this.userList;
      return;
    }
    let val = e.toLowerCase()
    const temp = this.userList.filter((x:any) => {
      if (x['name'].toLowerCase().indexOf(val) !== -1 || !val) {
        return x;
      }
    });
    this.users = temp;
  }

  searchDept(e:any) {
    if(!e || e == undefined) {
      this.departments = this.deptList;
      return;
    }
    let val = e.toLowerCase()
    const temp = this.deptList.filter((x:any) => {
      if (x['descp'].toLowerCase().indexOf(val) !== -1 || !val) {
        return x;
      }
    });
    this.departments = temp;
  }

  select(dataKey:any, dataValue:any, item:any) {
    console.log(item)
    this.keyword = item[dataKey];
    this.value = item[dataKey];
    this.data.assign_to = item[dataValue]

    this.shown = false;
  }


  show() {
    this.shown = !this.shown;
    this.keyword = "";

    setTimeout(() => {
    }, 0);
  }
}
