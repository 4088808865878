<nav class="d-flex justify-content-between mb-3">
    <h4 class="m-0">Budget Costing Summary</h4>
</nav>

<div class="d-flex mb-3">    
    <input class="form-control form-control-sm" type="text" placeholder="Search something... (ERP PR No / SQL PR No / Customer Name / Customer Contact No)" [(ngModel)]="filterData.search" name="search">

    <div class="input-group" style="width: 40% !important;">
        <select class="form-control form-control-sm" [(ngModel)]="filterData.month" name="month">
            <option *ngFor="let m of month;" [value]="m.value">{{m.key}}</option>
        </select>
        <input class="form-control form-control-sm" type="number" [(ngModel)]="filterData.year" name="year">
    </div>

        <button class="btn btn-primary btn-sm ms-2 text-nowrap" (click)="initData()">Search</button>
        <button class="btn btn-dark btn-sm ms-2 text-nowrap" (click)="reset()">Reset</button>
        <button class="btn btn-success btn-sm ms-2 text-nowrap" (click)="exportCSV()">Export CSV</button>
</div>

<div class="card">
    <div class="card-body p-0">
        <ng-container *ngIf="isLoading">
            <lib-bar></lib-bar>
        </ng-container>

        <ng-container *ngIf="!isLoading">
            <div class="table-responsive">
                <table class="table table-sm table-hover table-striped" style="font-size: 80%; white-space:nowrap">
                    <thead class="thead-dark thead-sticky">
                        <tr>
                            <th scope="col" style="position: sticky; left: 0; width:33px; z-index: 3;">No</th>
                            <th scope="col" style="position: sticky; left: 33px; width: 87px; z-index: 3;">PR/QTT NO.</th>
                            <th scope="col" style="position: sticky; left: 120px; z-index: 3;">Inspection Date</th>
                            <th scope="col">Request PR/QTT Date</th>
                            <th scope="col">Submission PR/QTT Date</th>
                            <th scope="col">Salesmen</th>
                            <th scope="col">Customer Name</th>
                            <th scope="col">Customer Contact No.</th>
                            <th scope="col" style="width:200px">Project Period (Day)</th>
                            <th scope="col">Site Worker Headcount</th>
                            <th scope="col">Site Worket Wages (RM)</th>
                            <th scope="col">Vehicle Qty</th>
                            <th scope="col">Vehicle Total Amount (RM)</th>
                            <th scope="col">Skylift / Crane (RM)</th>
                            <th scope="col">Rorobin (RM)</th>
                            <th scope="col">Iron Box (RM)</th>
                            <th scope="col">Scaffolding (RM)</th>
                            <th scope="col">Insurance (RM)</th>
                            <th scope="col">Admin Fees 1.50% / 3% (RM)</th>
                            <th scope="col">Salesmen Wages(RM150)</th>
                            <th scope="col">Sales Commission 5% (RM)</th>
                            <th scope="col">Third Party Commission (RM)</th>
                            <th scope="col">Material Cost (RM)</th>
                            <th scope="col">Total Cost Amount (RM)</th>
                            <th scope="col">Sales Amount (RM) <br>(Without RRB, Crane, Skylift, Scaffolding, Insurance)</th>
                            <th scope="col">PR/QTT Granc Total Amount (RM)</th>
                            <th scope="col">Balance Amount (RM)</th>
                            <th scope="col">Profit Margin (%)</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let list of listing; let i = index;" [class.selected]="(i+1)=== selectedRowIdx" (click)="onRowClick(i+1)">
                            <td style="position: sticky; left: 0; width:33px; background:#eee; z-index: 3;">{{i+1}}</td>
                            <td style="position: sticky; left: 33px; width: 87px; background:#eee; z-index: 3;">
                                {{list.doc_no}}
                                <span *ngIf="list.sql_doc_no" class="text-primary"><br><b>(SQL)</b> {{list.sql_doc_no}}</span>
                            </td>
                            <td style="position: sticky; left: 120px; width:auto;  background:#eee; z-index: 3;">{{list.detail.schedule_date}}</td>
                            <td>{{list.request_date | date: 'yyyy-MM-dd'}}</td>
                            <td>{{list.create_on | date: 'yyyy-MM-dd'}}</td>
                            <td>{{list.detail.assign_to}}</td>
                            <td>{{list.entity_name}}</td>
                            <td>{{list.entity_contact}}</td>
                            <td>{{list.project_period}}</td>
                            <td>{{list.total_worker_qty}}</td>
                            <td class="text-right">{{list.total_worker_cost | number: '1.2-2'}}</td>
                            <td>{{list.total_vehicle_qty}}</td>
                            <td class="text-right">{{list.total_vehicle_cost | number: '1.2-2'}}</td>
                            <td>{{list.equipment_cost | number: '1.2-2'}}</td>
                            <td>{{list.rorobin_cost | number: '1.2-2'}}</td>
                            <td>{{list.ironbox_cost | number: '1.2-2'}}</td>
                            <td>{{list.scaffolding_cost | number: '1.2-2'}}</td>
                            <td>{{list.insurance_cost | number: '1.2-2'}}</td>
                            <td class="text-right">{{list.administration | number: '1.2-2'}}</td>
                            <td>{{150 | number: '1.2-2'}}</td>
                            <td class="text-right">{{list.sales_commission | number: '1.2-2'}}</td>
                            <td></td>
                            <td class="text-right">{{list.totalCostAmt | number: '1.2-2'}}</td>
                            <td class="text-right">{{list.totalBudgetCost | number: '1.2-2'}}</td>
                            <td class="text-right">{{list.totalCustAmt | number: '1.2-2'}}</td>
                            <td class="text-right">{{list.totalDocAmt | number: '1.2-2'}}</td>
                            <td class="text-right">{{list.totalDifferences | number: '1.2-2'}}</td>
                            <td class="text-right">{{list.totalProfit}}</td>
                            <td><button class="btn btn-primary btn-sm" [routerLink]="['/detail/task/', list.task_uuid, 'extend', 'task-budget', list.uuid]">View</button></td>
                        </tr>
                    </tbody>
                </table>
                <ng-container *ngIf="!listing || listing.length == 0">
                    <p class="text-muted">No data found.</p>
                </ng-container>
            </div>
    
            <!-- <div class="d-flex flex-row justify-content-end pb-2 align-items-center small" *ngIf="listing && listing.length > 0">
                <div class="btn-group" role="group">
                    <button type="button" class="btn btn-light" [disabled]="pages.total_page == 1 || pages.current_page == 1" (click)="previous()">&laquo;</button>
                    <button type="button" class="btn" [hidden]="pages.current_page -2 <= 0" (click)="selectedPage(pages.current_page -2)">{{pages.current_page -2}}</button>
                    <button type="button" class="btn" [hidden]="pages.current_page -1 <= 0" (click)="selectedPage(pages.current_page -1)">{{pages.current_page -1}}</button>
                    <button type="button" class="btn active" (click)="selectedPage(pages.current_page)">{{pages.current_page}}</button>
                    <button type="button" class="btn" [hidden]="pages.current_page +1 > pages.total_page" (click)="selectedPage(pages.current_page +1)">{{pages.current_page + 1}}</button>
                    <button type="button" class="btn" [hidden]="pages.current_page +2 > pages.total_page" (click)="selectedPage(pages.current_page +2)">{{pages.current_page + 2}}</button>
                    <button type="button" class="btn btn-light" [disabled]="pages.total_page == 1 || pages.current_page == pages.total_page" (click)="next()">&raquo;</button>
                </div>
            </div> -->
        </ng-container>
    </div>
</div>