<nav class="d-flex justify-content-between mb-3">
    <h4 class="m-0 px-3">Project Schedule</h4>
    <div class="d-flex">
        <div class="form-group mb-0 me-1">
            <label class="mb-0"><small>Date From</small></label>
            <input class="form-control form-control-sm" type="date" [(ngModel)]="data.date_from" name="date_from">
        </div>
        <div class="form-group mb-0 me-1">
            <label class="mb-0"><small>Date From</small></label>
            <input class="form-control form-control-sm" type="date" [(ngModel)]="data.date_to" name="date_to">
        </div>
        
        <button class="btn btn-primary btn-sm" style="align-self: flex-end;" (click)="getSchedule()">Search</button>
    </div>
</nav>

<div class="card">
    <div class="card-body">
        <ng-container *ngIf="isLoading">
            <lib-bar></lib-bar>
        </ng-container>
        <div class="table-responsive" *ngIf="!isLoading">
            <table class="table table-sm table-hover table-striped" style="font-size: 80%; white-space:nowrap">
                <tbody>
                    <ng-container *ngFor="let list of listing | keyvalue; let i = index;">
                        <tr>
                            <td scope="col" style="position: sticky; left: 0; width:33px; z-index: 3;" class="bg-whb text-white"><b>{{i+1}}</b></td>
                            <td scope="col" style="position: sticky; left: 33px; width: 150px; z-index: 2;" class="bg-whb text-white"><b>{{list.key ?? "N/A"}}</b></td>
                            <td scope="col" colspan="4" class="bg-whb text-white"></td>
                        </tr>
                        <tr *ngFor="let task of getDetail(list.key)">
                            <td scope="col" style="position: sticky; left: 0; background:#eee; width:33px; z-index: 3;"></td>
                            <td scope="col" style="position: sticky; left: 33px; background:#eee; width: 150px; z-index: 3;"><b>Schedule Datetime</b><br>{{task.details.schedule_date}} ({{task.details.schedule_start_time}}<span *ngIf="task.details.schedule_end_time"> - {{task.details.schedule_end_time}}</span>)</td>
                            <td scope="col"><b>Address</b><br>{{task.address}}</td>
                            <td scope="col"><b>Contact Person</b><br>{{task.contact_person}} {{task.contact_mobile}}<br>{{task.contact_email}}</td>
                            <td scope="col" style="width: 20px;"><b>Customer</b><br>{{task.code}}<br>{{task.customer_name}}</td>
                            <td scope="col" style="width: 10px;"><a class="btn btn-primary btn-sm" [routerLink]="['/detail/task', task.task_uuid, 'extend', 'task-progress', task.task_uuid]">View</a></td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>