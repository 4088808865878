import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TaskScheduleRoutingModule } from './task-schedule.routing.module';
import { TaskScheduleComponent } from './task-schedule.component';
import { BarComponent } from 'whb-loading';

@NgModule({
  declarations: [
    TaskScheduleComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TaskScheduleRoutingModule,
    BarComponent
  ],
  exports: [
  ]
})
export class TaskScheduleModule { }
