<div class="modal-body">
    <h5><b>Materials Order Inquiry</b></h5>
    <hr>
    <div class="form-group">
        <label>Reference No</label>
        <input type="text" class="form-control" [(ngModel)]="data.reference_no" name="reference_no" disabled>
    </div>
    <div class="form-group">
        <label>Shipping Address</label>
        <textarea rows="2" class="form-control" [(ngModel)]="data.ship_address" name="ship_address" disabled></textarea>
    </div>
    <div class="form-group">
        <label>Shipping Date</label>
        <input type="date" class="form-control" [(ngModel)]="data.ship_date" name="ship_date" disabled>
    </div>
    <div class="form-group">
        <label>Order Items</label>
        <table class="table table-sm table-striped table-hover">
            <thead class="thead-dark">
                <tr>
                    <th style="width: 5%;"></th>
                    <th>Description</th>
                    <th style="width: 8%;">Quantity</th>
                    <th style="width: 5%;"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of data.items; let i = index">
                    <td class="p-0"><button class="btn btn-outline-dark w-100" (click)="addItem()">+</button></td>
                    <td class="p-0"><input type="text" class="form-control" [(ngModel)]="item.descp" name="descp[i]"></td>
                    <td class="p-0"><input type="text" class="form-control" [(ngModel)]="item.qty" name="qty[i]"></td>
                    <td class="p-0"><button class="btn btn-outline-danger w-100" (click)="removeItem(item, i)">x</button></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-success btn-sm w-100 mt-2" (click)="submit()">Submit</button>
    <button class="btn btn-danger btn-sm w-100 mt-2" (click)="closeModal()">Cancel</button>
</div>


