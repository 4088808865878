import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-task-progress',
  templateUrl: './task-progress.component.html',
  styleUrls: ['./task-progress.component.css']
})
export class TaskProgressComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
