import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from 'lexi-api';

@Component({
  selector: 'lib-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent {

  @Input() set setFilter(v:any) {
    this.filterData = v;
  }
  @Output() closeModal = new EventEmitter();

  public filterData:any = {};
  public locations:any = [];
  constructor(
    private api: ApiService
  ) { 
    this.init();
  }

  async init() {
    this.locations = await this.api.post("/inventory/get/warehouse", {status: 1})
  }

  submit(){
    this.closeModal.emit({filter: this.filterData})
  }

  reset() {
    let newFilter = {search: this.filterData?.search ?? null};
    this.closeModal.emit({filter: newFilter})
  }
  
  close() {
    this.closeModal.emit(false);
  }
}
