import { Component, OnInit } from '@angular/core';
import { ApiService } from 'lexi-api'
import { WhbDataService } from 'whb-data';

@Component({
  selector: 'lib-task-budget-report',
  templateUrl: './task-budget-report.component.html',
  styleUrls: ['./task-budget-report.component.css']
})
export class TaskBudgetReportComponent implements OnInit {

  public isLoading:boolean = false;
  public filterData:any = {};
  public listing:any = [];
  public pages:any = {current_page: 1};
  public selectedRowIdx:number = 0
  public month:any = [{key: "January", value: 1}, 
                      {key: "February", value: 2}, 
                      {key: "March", value: 3}, 
                      {key: "April", value: 4}, 
                      {key: "May", value: 5}, 
                      {key: "June", value: 6}, 
                      {key: "July", value: 7}, 
                      {key: "August", value: 8}, 
                      {key: "September", value: 9}, 
                      {key: "October", value: 10}, 
                      {key: "November", value: 11}, 
                      {key: "December", value: 12}]

  constructor(private api: ApiService, private dataService: WhbDataService) { 
    const date = new Date();
    this.filterData.month = date.getMonth() + 1
    this.filterData.year = date.getFullYear()
  }

  ngOnInit(): void {
    this.initData()
  }

  async initData() {
    this.isLoading = true;
    const result = await this.api.post('/task/get/report/budget', {filter: this.filterData, pages: this.pages})

    this.listing = result.collections
    this.pages = result.pages
    this.isLoading = false;
  }

  onRowClick(idx:number) {
    this.selectedRowIdx = idx;
  }

  numSequence(n: number): Array<number> {
    return Array(n);
  }

  selectedPage(page:any){
    this.pages.current_page = page;
    this.initData();
  }

  previous(){
    this.pages.current_page = this.pages.current_page == 1 ? 1 : this.pages.current_page - 1;
    this.initData();
  }

  next(){
    this.pages.current_page = this.pages.current_page == this.numSequence(this.pages.total_page).length ? this.numSequence(this.pages.total_page).length : this.pages.current_page+1;
    this.initData();
  }

  async reset() {
    this.filterData.search = null
    this.filterData.month = new Date().getMonth() + 1
    this.filterData.year = new Date().getFullYear()
    this.initData();
  }

  dateFormatConverter(date:any): any {
    let d = new Date(date)
    return d.getFullYear()+"-"+("0" + (d.getMonth() + 1)).slice(-2)+"-"+("0" + (d.getDate())).slice(-2)
  }

  async exportCSV() {
    let date = new Date();
    const todayDate = date.getFullYear()+("0" + (date.getMonth() + 1)).slice(-2)+("0" + (date.getDate())).slice(-2) 
    let filename = todayDate+" Budget Costing Report";
    const exportData:any = []

    exportData.push([
      "ERP PR/QTT NO.",
      "SQL PR/QTT NO.",
      "Inspection Date",
      "Request PR/QTT Date",
      "Submission PR/QTT Date",
      "Salesmen",
      "Customer Name",
      "Customer Contact No.",
      "Project Period (Day)",
      "Site Worker Headcount",
      "Site Worket Wages (RM)",
      "Vehicle Qty",
      "Vehicle Total Amount (RM)",
      "Skylift / Crane (RM)",
      "Rorobin (RM)",
      "Iron Box (RM)",
      "Scaffolding (RM)",
      "Insurance (RM)",
      "Admin Fees 1.50% / 3% (RM)",
      "Salesmen Wages(RM150)",
      "Sales Commission 5% (RM)",
      "Third Party Commission (RM)",
      "Material Cost (RM)",
      "Total Cost Amount (RM)",
      "Sales Amount (RM)",
      "PR/QTT Grand Total Amount (RM)",
      "Balance Amount (RM)",
      "Profit Margin (%)"
    ])
    
    for(let x = 0; x < this.listing.length; x++){
      const singleData:any = []
      singleData.push(this.listing[x]['doc_no'])
      singleData.push(this.listing[x]['sql_doc_no'])
      singleData.push(this.listing[x]['detail']['schedule_date'])
      singleData.push(this.dateFormatConverter(this.listing[x]['request_date']))
      singleData.push(this.dateFormatConverter(this.listing[x]['create_on']))
      singleData.push(this.listing[x]['detail']['assign_to'])
      singleData.push(this.listing[x]['entity_name'])
      singleData.push(this.listing[x]['entity_contact'])
      singleData.push(this.listing[x]['project_period'])
      singleData.push(this.listing[x]['total_worker_qty'])
      singleData.push(parseFloat(this.listing[x]['total_worker_cost']).toFixed(2))
      singleData.push(this.listing[x]['total_vehicle_qty'])
      singleData.push(parseFloat(this.listing[x]['total_vehicle_cost']).toFixed(2))
      singleData.push(parseFloat(this.listing[x]['equipment_cost']).toFixed(2))
      singleData.push(parseFloat(this.listing[x]['rorobin_cost']).toFixed(2))
      singleData.push(parseFloat(this.listing[x]['ironbox_cost']).toFixed(2))
      singleData.push(parseFloat(this.listing[x]['scaffolding_cost']).toFixed(2))
      singleData.push(parseFloat(this.listing[x]['insurance_cost']).toFixed(2))
      singleData.push(parseFloat(this.listing[x]['administration']).toFixed(2))
      singleData.push((150).toFixed(2))
      singleData.push(parseFloat(this.listing[x]['sales_commission']).toFixed(2))
      singleData.push("")
      singleData.push(parseFloat(this.listing[x]['totalCostAmt']).toFixed(2))
      singleData.push(parseFloat(this.listing[x]['totalBudgetCost']).toFixed(2))
      singleData.push(parseFloat(this.listing[x]['totalCustAmt']).toFixed(2))
      singleData.push(parseFloat(this.listing[x]['totalDocAmt']).toFixed(2))
      singleData.push(parseFloat(this.listing[x]['totalDifferences']).toFixed(2))
      singleData.push(this.listing[x]['totalProfit'])

      exportData.push(singleData)
    }
    this.dataService.exportCSV(filename, exportData)

  }
}
