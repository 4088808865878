import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {path: "card", loadChildren: () => import("./warranty-card/warranty-card.module").then(m => m.WarrantyCardModule)},
  {path: "location", loadChildren: () => import("./task-map/task-map.module").then(m => m.TaskMapModule)},
  {path: "salesman", loadChildren: () => import("./task-schedule/task-schedule.module").then(m => m.TaskScheduleModule)},
  {path: "progress", loadChildren: () => import("./task-project/task-project.module").then(m => m.TaskProjectModule)},
  {path: "project", loadChildren: () => import("./temp-project/temp-project.module").then(m => m.TempProjectModule)},
  {path: "calendar", loadChildren: () => import("./task-calendar/task-calendar.module").then(m => m.TaskCalendarModule)},
  {path: "preparation", loadChildren: () => import("./task-pr-preparation/task-pr-preparation.module").then(m => m.TaskPrPreparationModule)},
  {path: "worker-rate/:uuid", loadChildren: () => import("./task-worker-rate/task-worker-rate.module").then(m => m.TaskWorkerRateModule)},
  {path: "worker-rate-form/:uuid", loadChildren: () => import("./task-worker-rate/task-worker-rate-form/task-worker-rate-form.module").then(m => m.TaskWorkerRateFormModule)},
  {path: "worker-rate-list", loadChildren: () => import("./task-worker-rate/task-worker-rate-list/task-worker-rate-list.module").then(m => m.TaskWorkerRateListModule)},
  {path: "stock-report", loadChildren: () => import("./task-stock-report/task-stock-report.module").then(m => m.TaskStockReportModule)},
  {path: "costing", loadChildren: () => import("./task-costing-report/task-costing-report.module").then(m => m.TaskCostingReportModule)},
  {path: "exceed", loadChildren: () => import("./task-costing-exceed/task-costing-exceed.module").then(m => m.TaskCostingExceedModule)},
  {path: "report", children: [
    {path: "budget", loadChildren: () => import("./task-budget-report/task-budget-report.module").then(m => m.TaskBudgetReportModule)}
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WHBTaskRoutingModule { }
