
<nav class="d-flex justify-content-between mb-3">
    <h4 class="m-0">Warehouse Management</h4>
</nav>
<div class="d-flex flex-row mb-3">
    <div class="input-group">
        <input class="form-control" type="text" placeholder="Search something..." [(ngModel)]="filterData.search" name="search">
        <button class="btn btn-secondary" type="button" (click)="getSearch()">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-search">
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
            </svg>
        </button>
        <button class="btn btn-dark" type="button" (click)="getReset()">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-clockwise">
                <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
            </svg>
        </button>
    </div>
    <button class="btn btn-primary ms-1 text-nowrap" (click)="detailModal()">+ Add New</button>
</div>


<table class="table table-sm table-hover table-striped">
    <thead class="thead-primary">
        <tr>
            <th style="width: 3%;" class="text-center">No</th>
            <th style="width: 17%;">Code</th>
            <th>Description</th>
            <th style="width: 10%;">Balance</th>
            <th style="width: 10%;">Status</th>
            <th style="width: 10%;"></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let collect of collections; let i = index;">
            <td class="text-center">{{i+1}}</td>
            <td>{{collect.code}}</td>
            <td>{{collect.descp}}</td>
            <td>{{collect.balance ?? 0}}</td>
            <td>{{collect.status == 1 ? 'Active' : 'Disabled'}}</td>
            <td class="d-flex flex-row">
                <!-- <button class="btn btn-link btn-sm p-0 w-100 me-2 text-danger" (click)="deleteItem(collect)">Delete</button> -->
                <button class="btn btn-link btn-sm p-0 w-100" (click)="detailModal(collect)">View</button>
            </td>
        </tr>
        <tr *ngIf="!collections || collections.length == 0">
            <td colspan="6" class="text-muted">No data found.</td>
        </tr>
    </tbody>
</table>
