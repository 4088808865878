import { Component, OnInit } from '@angular/core';
import { ApiService } from 'lexi-api'
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'lib-task-costing-report',
  templateUrl: './task-costing-report.component.html',
  styleUrls: ['./task-costing-report.component.css']
})
export class TaskCostingReportComponent implements OnInit {

  public employees:any = [];
  public listing:any = [];
  public filter:any = {};
  public isLoading:boolean = false;
  public selectedRowIdx:number = 0;
  public month:any = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]

  employeeDropdownSettings:IDropdownSettings = {};

  constructor(private api: ApiService) { 
    const date = new Date();
    const sevenDaysAgo = new Date(date);
          sevenDaysAgo.setDate(date.getDate() - 7);

    this.filter.date_from = sevenDaysAgo.getFullYear() + "-" + ("0" + (sevenDaysAgo.getMonth() + 1)).slice(-2) + "-" + ("0" + sevenDaysAgo.getDate()).slice(-2);
    this.filter.date_to = date.getFullYear() +"-"+ ("0" + (date.getMonth() + 1)).slice(-2) +"-"+ ("0" + date.getDate()).slice(-2);
  
    this.employeeDropdownSettings = {
      singleSelection: false,
      idField: 'user_id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 20,
      allowSearchFilter: true
    };  
  }

  ngOnInit(): void {
    this.init()
    this.initData()
  }

  async init() {
    this.employees = await this.api.post("/setting/get/all-users", {})
  }

  async initData() {
    this.isLoading = true;
    this.listing = await this.api.post("/costing/report/summary", {filter: this.filter})
    console.log(this.listing)
    this.isLoading = false;
  }

  getBelt(leader_grade:any) {
    let belt;
    if (leader_grade.match(/BLACK/i)) {
      belt = "Black";
    } else if (leader_grade.match(/BLUE/i)) {
      belt = "Blue";
    } else if (leader_grade.match(/YELLOW/i)) {
      belt = "Yellow";
    }else{
      belt = "-"
    }

    return belt;
  }

  onRowClick(idx:number) {
    this.selectedRowIdx = idx;
  }
}
