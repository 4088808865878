import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'lexi-api';
import { ResourceManagerComponent } from 'lexi-resource';
import Panzoom from '@panzoom/panzoom'
import { WhbDataService } from 'whb-data';

@Component({
  selector: 'lib-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css']
})
export class MediaComponent implements OnInit {

  @Input() set parent_uuid(val : any) {
    this.uuid = val;
  };

  @Input() type:any;
  @Input() pathLink:any;
  @Input() title:any;
  
  public uuid:any
  public images:any = [];
  public preview:any;
  public preview_type:any;
  public isLoading:boolean = false;
  constructor(
    private modalService: NgbModal, 
    private apiService: ApiService,
    public dataService: WhbDataService
  ) { }

  ngOnInit(): void {
    this.initData();
  }

  async initData() {
    this.isLoading = true;
    var result = await this.apiService.post(this.pathLink.get, {uuid: this.uuid})
    if(result && result.length > 0){
      this.images = result;
      this.getFileType()
    }
    this.isLoading = false;
  }

  selectMedia() {
    let modal = this.modalService.open(ResourceManagerComponent, {size: "xl"});
    modal.componentInstance.setPath = "whb/"+this.type+"/" + this.uuid + "/"
    modal.componentInstance.selectType = "multiple"
    modal.componentInstance.setSupportedFileType = ['image/jpg', 'image/jpeg', 'image/png', 'video/mp4', 'video/ogg', 'video/avi', 'video/mov', 'application/pdf'];
    modal.componentInstance.getSelected.subscribe((v:any) => {
      for(let item of v){
        this.images.push({'image': item['key']})
      }
      this.getFileType()
      modal.close();
    })
  }

  async removeImage(image:any, ind:Number) {
    if(!image.uuid){
      this.images.splice(ind, 1)
      return;
    }

    const result = await this.apiService.post(this.pathLink.remove, image)
    if(!result){
      return alert("Unsuccessful remove image.")
    }
    alert("Image remove success")
    this.images.splice(ind, 1)
  }

  async submit() {
    if(!this.images || this.images.length == 0){
      return;
    }
    
    const result = await this.apiService.post(this.pathLink.save, {uuid: this.uuid, data: this.images})
    if(!result){
      return alert('Images unsuccessful save!');
    }

    alert("Successful save.")
    this.initData()
  }

  async getFileType() {
    // for(let x = 0; x < this.images.length; x++){
    //   let img = this.images[x]['file'] ?? this.images[x]['image']
    //   this.images[x]['file_type'] = img.substr(img.length - 3);
    // }

    this.images.forEach((image:any) => {
      const img = image['file'] || image['image']; // Use nullish coalescing for clarity
      if (img) {
        // Extract the file extension using a more robust approach
        const fileType = img.split('.').pop()?.toLowerCase() ?? '';
        image['file_type'] = fileType;
      } else {
        image['file_type'] = null; // Handle cases where img is undefined/null
      }
    });
  }


  viewImg(content:any, img:any) {
    const fileType = img.substr(img.length - 3);
    if(fileType == 'pdf'){
      window.open((img).replace(/#/, "%23"), "_blank");
      return;
    }
    
		this.modalService.open(content, {})
    setTimeout(() => {
      this.initZoom();
    }, 1000)
    this.preview = img;
    this.preview_type = fileType;
    console.log(this.preview, this.preview_type)
	}

  initZoom() {
    const elem = document.getElementById('popupImage')
    if(!elem){
      return;
    }
    const panzoom = Panzoom(elem, {
      maxScale: 5
    })
    elem.parentElement?.addEventListener('wheel', panzoom.zoomWithWheel)
  }

  getFileNameFromUrl(url: any) {
    const urlObject = new URL(url);
    const pathname = urlObject.pathname;
    const fileName = pathname.substring(pathname.lastIndexOf('/') + 1);
    
    return fileName;
}

}
