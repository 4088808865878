<div class="d-flex justify-content-between">
    <h3 class="mb-0"><b>Leader Report Management</b></h3>
</div>
<hr>

<ng-container *ngIf="isLoading">
    <lib-dot></lib-dot>
</ng-container>

<ng-container *ngIf="!isLoading">
    <ul ngbNav #nav="ngbNav" [(activeId)]="currentActive" (navChange)="onNavChange($event)" class="nav-tabs">
        <li [ngbNavItem]="'incompleteProgress'">
            <a ngbNavLink>Pending Progress Photo ({{data.incompleteProgress.length ?? 0}})</a>
            <ng-template ngbNavContent>
                <div class="card">
                    <div class="card-body px-0">
                        <div class="table-responsive">
                            <table class="table table-sm table-hover table-striped" style="font-size: 80%; white-space:nowrap">
                                <thead class="table-dark thead-sticky">
                                    <tr>
                                        <th style="width: 3%;">No</th>
                                        <th>Person In Charge</th>
                                        <th style="width: 10%;">Pending Case</th>
                                        <th style="width: 5%;"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let incomplete of data.incompleteProgress; let i = index;">
                                        <td>{{i+1}}</td>
                                        <td>{{incomplete.name}}</td>
                                        <td>{{incomplete.total}}</td>
                                        <td>
                                            <button class="btn btn-outline-primary btn-sm" (click)="onClick(incomplete.user_id)">View</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>

</ng-container>