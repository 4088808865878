import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TaskStockReportRoutingModule } from './task-stock-report.routing.module';
import { BarComponent, DotComponent } from 'whb-loading';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TaskStockReportComponent } from './task-stock-report.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


@NgModule({
  declarations: [
    TaskStockReportComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    NgbNavModule,
    TaskStockReportRoutingModule,
    BarComponent,
    DotComponent,
    NgMultiSelectDropDownModule.forRoot()
  ],
  exports: [
  ]
})
export class TaskStockReportModule { }
