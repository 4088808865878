import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TempProjectDetailComponent } from './temp-project-detail/temp-project-detail.component';
import { TempProjectComponent } from './temp-project.component';

const routes: Routes = [
  {path: "", component: TempProjectComponent},
  {path: "detail/:uuid", component: TempProjectDetailComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TempProjectRoutingModule { }
