import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TaskCostingExceedComponent } from './task-costing-exceed.component';

const routes: Routes = [
  {path: "", component: TaskCostingExceedComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TaskCostingExceedRoutingModule { }
