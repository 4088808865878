import { Injectable } from '@angular/core';
import { ApiService } from "lexi-api";
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class WhbDataService {

  private _selected_branch_uuid:any;
  private _token:any;
  private isHod: any = false;

  constructor(private api: ApiService, private cookieService: CookieService) { 
    this._token = this.cookieService.get('token') ? this.cookieService.get('token') : null;
  }

  async setBranch(branchUUID:any) {
    this._selected_branch_uuid = branchUUID;
    localStorage.setItem("branch", branchUUID)
  }

  async getBranch() {
    return localStorage.getItem("branch")
  }

  async setSwitcherToggle(switchToggle:any) {
    localStorage.setItem("switcher", switchToggle)
  }

  async getSwitcherToggle() {
    return localStorage.getItem("switcher")
  }

  async getWidgetPermission(identifier:any) {
    return await this.api.post("/setting/get/widget", {identifier: identifier, token: this._token})
  }

  async exportCSV(filename:any, collections:any) {
    const fileName = filename+'.csv';
    const csvContent = collections.map((row:any) => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }

  public setAsHod(value:any) {
    localStorage.setItem("isHod", value);
  }

  public checkIsHod() {
    return localStorage.getItem("isHod")
  }

}
