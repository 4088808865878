<nav class="d-flex justify-content-between mb-3">
    <h4 class="m-0 px-3">Project Exceed Record</h4>
</nav>

<div class="card">
    <div class="card-body">
        <ng-container *ngIf="isLoading">
            <lib-bar></lib-bar>
        </ng-container>

        <ng-container *ngIf="!isLoading">
            <div class="table-responsive">
                <table class="table table-sm table-hover table-striped" style="font-size: 80%; white-space:nowrap">
                    <thead class="thead-dark thead-sticky">
                        <tr>
                            <th style="width: 5%;">No</th>
                            <th style="width: 15%;">Job No</th>
                            <th style="width: 25%;">Invoice NO</th>
                            <th class="text-center">Budget Work Day</th>
                            <th class="text-center">Actual Work Day</th>
                            <th class="text-center">Budget Headcount</th>
                            <th class="text-center">Actual Headcount</th>
                            <th style="width: 10%;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let collect of collections; let i = index;">
                            <td>{{i+1}}</td>
                            <td>{{collect.reference_no}}</td>
                            <th>{{collect.docs}}</th>
                            <td class="text-center">{{collect.budget_work_day}}</td>
                            <td class="text-center">{{collect.actual_work_day}}</td>
                            <td class="text-center">{{collect.budget_headcount}}</td>
                            <td class="text-center">{{collect.actual_headcount}}</td>
                            <td class="p-0"><button class="btn btn-link btn-sm w-100" [routerLink]="['/detail/task', collect.task_uuid, 'view', 'task', collect.task_uuid]">View Project</button></td>
                        </tr>
                        <tr *ngIf="!collections || collections.length == 0">
                            <td colspan="7" class="text-muted">No data found.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>
    </div>
</div>