import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'lexi-api';

@Component({
  selector: 'lib-warranty-card',
  templateUrl: './warranty-card.component.html',
  styleUrls: ['./warranty-card.component.css']
})
export class WarrantyCardComponent implements OnInit {

  public uuid:any;
  public data:any = {};
  constructor(private api: ApiService, private activeRoute: ActivatedRoute) { 
    this.uuid = this.activeRoute.snapshot.paramMap.get('uuid')
  }

  ngOnInit(): void {
    this.initData()
  }

  async initData() {
    this.data = await this.api.post('/task/get/warranty-card', {uuid: this.uuid})
    console.log(this.data)
  }
}
