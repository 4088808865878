

<div class="modal-body">
    <div class="d-flex flex-row justify-content-between align-items-center">
        <h3 class="mb-0"><b>Warehouse Detail</b></h3>
        <button class="btn btn-link text-danger" (click)="close()">
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x-lg">
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
            </svg>
        </button>
    </div>
    <hr>
    <div class="form-group">
        <label>Code</label>
        <input class="form-control" type="text" [(ngModel)]="data.code" name="code">
    </div>
    <div class="form-group">
        <label>Description</label>
        <textarea class="form-control" rows="4" [(ngModel)]="data.descp" name="descp"></textarea>
    </div>
    <div class="form-group">
        <label>Remark</label>
        <textarea class="form-control" rows="4" [(ngModel)]="data.remark" name="remark"></textarea>
    </div>
    <div class="form-group">
        <label>Status</label>
        <select class="form-control" [(ngModel)]="data.status" name="status">
            <option [value]="1">Active</option>
            <option [value]="0">Disabled</option>
        </select>
    </div>
    <button class="btn btn-primary w-100" (click)="submit()" [disabled]="isLoading">
        <ng-container *ngIf="!isLoading">Save</ng-container>
        <ng-container *ngIf="isLoading"><lib-dot></lib-dot></ng-container>
    </button>
</div>