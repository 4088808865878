<div class="form-group">
    <label [for]="field.mapping.key">{{field.title}} <em *ngIf="field.required">*</em>
        <ng-container *ngIf="field.descp">
            <br>
            <small class="text-muted">{{field.descp}}</small>
        </ng-container>
    </label>
    <ng-container *ngIf="field.mapping.loopKey">
        <div class="input-group mb-1" *ngFor="let dt of list; let i = index;">
            <button class="btn btn-outline-dark btn-sm" type="button" (click)="addText()">+</button>
            <div class="d-flex">
                <ng-container *ngFor="let d of field.mapping.loopKey;">
                    <input type="text" class="form-control form-control-sm" [(ngModel)]="dt[d]" name="data_{{i}}">
                </ng-container>
            </div>
            <button class="btn btn-outline-dark btn-sm" type="button" (click)="removeText(i)">-</button>
        </div>
    </ng-container>

    <ng-container *ngIf="!field.mapping.loopKey">
        <div class="input-group mb-1" *ngFor="let dt of list; let ind = index; trackBy: trackByFn;">
            <button class="btn btn-outline-dark btn-sm" type="button" (click)="addText()">+</button>
            <input type="text" class="form-control form-control-sm" [(ngModel)]="dt.data" name="data_{{ind}}">
            <button class="btn btn-outline-dark btn-sm" type="button" (click)="removeText(ind)">-</button>
        </div>
    </ng-container>
</div>