import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'lexi-api';

@Component({
  selector: 'lib-task-aftercare-progress',
  templateUrl: './task-aftercare-progress.component.html',
  styleUrls: ['./task-aftercare-progress.component.css']
})
export class TaskAftercareProgressComponent implements OnInit {

  @Input() set parent_uuid(val : any) {
    this.uuid = val;
    this.initData()
  };

  public uuid:any;
  public collections:any = [];
  public isLoading:boolean = false;

  constructor(private api: ApiService) { }

  ngOnInit(): void {
  }

  async initData() {
    this.isLoading = true;
    this.collections = await this.api.post("/task/get/aftercare/full-progress-detail", {task_uuid: this.uuid});
    this.isLoading = false;
  }

}
