import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'lexi-api';

@Component({
  selector: 'lib-task-worker-rate',
  templateUrl: './task-worker-rate.component.html',
  styleUrls: ['./task-worker-rate.component.css']
})
export class TaskWorkerRateComponent implements OnInit {

  public uuid:any;
  public data:any = {rates: []}
  constructor(private api: ApiService, private activatedRoute: ActivatedRoute) { 
    this.uuid = this.activatedRoute.snapshot.params['uuid']
    this.init()
  }

  ngOnInit(): void {
  }

  async init() {
    this.data = await this.api.post("/inventory/get/worker/rates", {uuid: this.uuid})
  }
}
