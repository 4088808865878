import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TaskCostingExceedRoutingModule } from './task-costing-exceed.routing.module';
import { BarComponent, DotComponent } from 'whb-loading';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TaskCostingExceedComponent } from './task-costing-exceed.component';


@NgModule({
  declarations: [
    TaskCostingExceedComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    NgbNavModule,
    TaskCostingExceedRoutingModule,
    BarComponent,
    DotComponent,
    NgMultiSelectDropDownModule.forRoot()
  ],
  exports: [
  ]
})
export class TaskCostingExceedModule { }
