import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lib-task-costing-confirmation',
  templateUrl: './task-costing-confirmation.component.html',
  styleUrls: ['./task-costing-confirmation.component.css']
})
export class TaskCostingConfirmationComponent implements OnInit {

  @Input() set setData(val:any) {
    this.data = val;
  }
  @Output() dataEmitter = new EventEmitter();

  public data:any = {}
  constructor() { }

  ngOnInit(): void {
  }

  async submit() {
    this.dataEmitter.emit(this.data)
  }

  closeModal() {
    this.dataEmitter.emit(false)
  }

}
