<nav class="d-flex justify-content-between mb-3">
    <h4 class="m-0">Stock Report Management</h4>
</nav>
<div class="row">
    <div class="col-12 col-sm-12 col-md-9 col-lg-4">
        <div class="form-group">
            <label class="mb-1">Stock Type</label>
            <select class="form-control" [(ngModel)]="type" name="type">
                <option [value]="'stock_balance'">Stock Balance</option>
                <option [value]="'stock_transaction'">Stock Transaction</option>
            </select>
        </div>
    </div>
    <ng-container *ngIf="type">
        <div class="col-12 col-sm-12 col-md-3 col-lg-2">
            <div class="form-group">
                <label class="mb-1">Quantity Status</label>
                <select class="form-control" [(ngModel)]="filterData.qty_status" name="qty_status">
                    <option [value]="'all'">All</option>
                    <option [value]="'more_then_0'">More Than 0 ( > 0 )</option>
                    <option [value]="'less_then_0'">Less Than 0 ( 0 < )</option>
                </select>
            </div>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-3" *ngIf="type == 'stock_transaction'">
            <div class="form-group mb-2">
                <label class="mb-1">From Date</label>
                <input class="form-control" type="date" [(ngModel)]="filterData.date_from" name="date">
            </div>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-3" *ngIf="type == 'stock_transaction'">
            <div class="form-group mb-2">
                <label class="mb-1">To Date</label>
                <input class="form-control" type="date" [(ngModel)]="filterData.date_to" name="date">
            </div>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-6">
            <div class="form-group mb-2">
                <label class="mb-1">Item Name</label>
                <ng-multiselect-dropdown
                    [placeholder]="''"
                    [settings]="itemDropdownSettings"
                    [data]="items"
                    [(ngModel)]="filterData.item_ids">
                </ng-multiselect-dropdown>
            </div>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-6" *ngIf="type == 'stock_transaction'">
            <div class="form-group">
                <label class="mb-1">Invoice No</label>
                <input class="form-control" type="text" [(ngModel)]="filterData.inv_no" name="inv_no">
            </div>
        </div>
        <div class="col-12 col-sm-12 col-md-12" *ngIf="type == 'stock_transaction'">
            <div class="form-group mb-2">
                <label class="mb-1">Employee Name</label>
                <ng-multiselect-dropdown
                    [placeholder]="''"
                    [settings]="userDropdownSettings"
                    [data]="users"
                    [(ngModel)]="filterData.user_ids">
                </ng-multiselect-dropdown>
            </div>
        </div>
    </ng-container>
</div>
<div class="d-flex">
    <button class="btn btn-primary w-100" [disabled]="isLoading" (click)="submit()">Search</button>
    <button class="btn btn-secondary ms-2" (click)="reset()" [disabled]="isLoading">Reset</button>
</div>

<div class="mt-4">
    <div class="d-flex justify-content-end mt-4 mb-2" *ngIf="!isLoading && (listing && listing.length > 0)">
        <button class="btn btn-success btn-sm" (click)="exportCSV()">Export CSV</button>
    </div>
    <div class="card">
        <div class="card-body p-0">
            <ng-container *ngIf="isLoading">
                <lib-dot></lib-dot>
            </ng-container>
            <div class="table-responsive" *ngIf="!isLoading && (listing && listing.length > 0)">
                <table class="table table-sm table-hover table-striped" style="font-size: 80%; white-space:nowrap">
                    <ng-container *ngIf="type == 'stock_balance'">
                        <thead class="thead-dark thead-sticky">
                            <tr>
                                <th>No</th>
                                <th>Name</th>
                                <th>Remark</th>
                                <th>Balance</th>
                                <th>Stock Balance Amount</th>
                                <th>Updated Date</th>
                                <th>View</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let list of listing; let i = index;">
                                <td>{{i+1}}</td>
                                <td>{{list.name}}</td>
                                <td>{{list.remark ?? '-'}}</td>
                                <td>{{list.balance ?? 0}}</td>
                                <td class="text-end">{{(list.balance_amount ?? 0) | number: "1.2-2"}}</td>
                                <td>{{(list.updated_date | date: 'dd-MM-yyyy') ?? "-"}}</td>
                                <td><a [routerLink]="['/detail/stock', list.uuid]">View</a></td>
                            </tr>
                        </tbody>
                        <tfoot class="tfoot-sticky bg-dark text-white">
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td class="text-end fw-semibold">{{grandTotal | number: '1.2-2'}}</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </ng-container>

                    <ng-container *ngIf="type == 'stock_transaction'">
                        <thead class="thead-dark thead-sticky">
                            <tr>
                                <th>No</th>
                                <th>Name</th>
                                <th>Supplier</th>
                                <th>Invoice No</th>
                                <th>Invoice Date</th>
                                <th>Worker</th>
                                <th>Project Invoice</th>
                                <th>U/Price</th>
                                <th>Stock In</th>
                                <th>Stock Out</th>
                                <th>Balance</th>
                                <th>Stock Closing Amount</th>
                                <th>Updated Date</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let list of listing; let i = index;">
                                <td>{{i+1}}</td>
                                <td>{{list.name}}</td>
                                <td>{{list.supplier}}</td>
                                <td>{{list.invoice_no}}</td>
                                <td>{{list.invoice_date | date: 'dd-MM-yyyy'}}</td>
                                <td>{{list.task_id ? list.user : '-'}}</td>
                                <td>{{list.task_id ? list.project_invoice : '-'}}</td>
                                <td>{{list.rate | number: "1.2-2"}}</td>
                                <td>{{list.debit}}</td>
                                <td>{{list.credit}}</td>
                                <td>{{list.balance}}</td>
                                <td class="text-end">{{list.balance_amount | number: "1.2-2"}}</td>
                                <td>{{list.create_on | date: 'dd-MM-yyyy'}}</td>
                                <td><a [routerLink]="['/detail/stock', list.stock_uuid]">View</a></td>
                            </tr>
                        </tbody>
                        <tfoot class="tfoot-sticky bg-dark text-white">
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td class="text-end fw-semibold">{{grandTotal | number: "1.2-2"}}</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </ng-container>
                </table>
            </div>
        </div>
    </div>
</div>