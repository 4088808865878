<div class="d-flex flex-row justify-content-between pb-2 align-items-center">
    <h4 class="m-0">Budget Cost</h4>
    <div *ngIf="!isLoading">
        <button type="button" class="btn btn-sm btn-primary" *ngIf="editable" (click)="submit('save')" [disabled]="submitLoading">
            <ng-container *ngIf="submitLoading">
                <div class="spinner-grow spinner-grow-sm" role="status"></div>
                <div class="spinner-grow spinner-grow-sm" role="status"></div>
                <div class="spinner-grow spinner-grow-sm" role="status"></div>
            </ng-container>
            <span *ngIf="!submitLoading">Save</span>
        </button>   
        <button type="button" class="btn btn-sm btn-danger ms-2" *ngIf="editable" (click)="submit('edit')" [disabled]="submitLoading">Cancel</button>   
        <button type="button" class="btn btn-sm btn-primary" *ngIf="!editable && !project?.is_projectStart && type !== 'NEW'" (click)="submit('edit')" [disabled]="submitLoading">Edit</button> 
        <button type="button" class="btn btn-sm btn-primary" *ngIf="!editable && type == 'NEW'" (click)="submit('edit')" [disabled]="submitLoading">Edit</button> 
        <button type="button" class="btn btn-sm btn-primary ms-2" *ngIf="!editable" (click)="submit('print')" [disabled]="submitLoading">Print</button> 
    </div>  
</div>

<ng-container *ngIf="isLoading">
    <lib-bar></lib-bar>
</ng-container>

<ng-container *ngIf="!isLoading">
    <div class="row my-4">
        <div class="col-12 col-sm-12 col-md-8 col-lg-8">
            <table class="table table-sm">
                <tbody>
                    <tr>
                        <th style="width: 25%;">Name</th>
                        <td>{{project?.detail?.assign_to}}</td>
                    </tr>
                    <tr>
                        <th style="width: 25%;">Site Inspection Date</th>
                        <td>{{project?.detail?.schedule_date}}</td>
                    </tr>
                    <tr>
                        <th style="width: 25%;">Job No</th>
                        <td>{{project?.reference_no}}</td>
                    </tr>
                    <tr>
                        <th style="width: 25%;">Customer Profile</th>
                        <td>{{project?.customer?.name}} <small>({{project?.customer?.code}})</small></td>
                    </tr>
                    <tr>
                        <th style="width: 25%;">Contact Person</th>
                        <td>{{project?.entity?.name}} <small>({{project?.entity?.mobile}})</small></td>
                    </tr>
                    <tr>
                        <th style="width: 25%;">Site Address</th>
                        <td>{{project?.address?.full_address}}</td>
                    </tr>
                    <tr>
                        <th style="width: 25%;">Quotation</th>
                        <td class="p-0">
                            <div class="input-group">
                                <select class="form-control form-control-sm" [disabled]="!editable" [(ngModel)]="data.qtt_id" name="qtt_id" (ngModelChange)="getDocumentAmount()">
                                    <option *ngIf="!documents.quotation || documents.quotation.length == 0" disabled>No data found.</option>
                                    <option *ngIf="documents.quotation && documents.quotation.length > 0" disabled selected value="">Select Quotation No</option>
                                    <option *ngFor="let dt of documents.quotation" [value]="dt.id">{{dt.entry_no}} <span *ngIf="dt.sql_reference_no">({{dt.sql_reference_no}})</span></option>
                                </select>
                                <button class="btn btn-black rounded-0" type="button" (click)="refresh()" [disabled]="!editable">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
                                        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
                                    </svg>
                                </button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th style="width: 25%;">Proforma Invoice</th>
                        <td class="p-0">
                            <div class="input-group">
                                <select class="form-control form-control-sm" [disabled]="!editable" [(ngModel)]="data.pr_id" name="pr_id" (ngModelChange)="getDocumentAmount()">
                                    <option *ngIf="!documents.proforma || documents.proforma.length == 0" disabled>No data found.</option>
                                    <option *ngIf="documents.proforma && documents.proforma.length > 0" selected value="">Select Proforma No</option>
                                    <option *ngFor="let dt of documents.proforma" [value]="dt.id">{{dt.entry_no}} <span *ngIf="dt.sql_reference_no">(<small>SQL : </small>{{dt.sql_reference_no}})</span></option>
                                </select>
                                <button class="btn btn-black rounded-0" type="button" (click)="refresh()" [disabled]="!editable">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
                                        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
                                    </svg>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    
    <p class="mb-1"><b>Material Cost</b></p>
    <table class="table table-sm table-bordered mb-5">
        <thead class="table-head table-dark">
            <tr>
                <th style="width:2%" class="p-0">
                    <button class="btn btn-link btn-sm py-1 px-2" [disabled]="!editable" (click)="addRow()"><svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="24" height="24" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg></button>
                </th>
                <th style="width:10%">Code</th>
                <th>Name</th>
                <th style="width:10%">Measurement</th>
                <th style="width:8%">Cost Rate (RM)</th>
                <th style="width:10%">Total Cost (RM)</th>
                <th style="width:8%">Cust Rate (RM)</th>
                <th style="width:10%">Amount</th>
            </tr>
        </thead>
        <tbody class="table-body">
            <tr *ngFor="let cost of costs;let i = index; first as isFirst">
                <td class="p-0"> 
                    <!-- *ngIf="!isFirst" -->
                    <button class="btn btn-link btn-sm py-1 px-2" [disabled]="!editable" (click)="removeRow(i)"><svg xmlns="http://www.w3.org/2000/svg" fill="red" width="24" height="24" viewBox="0 0 24 24"><path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z"/></svg></button>
                </td>
                <td class="p-0">
                    <select class="form-control form-control-sm" [disabled]="!editable" [(ngModel)]="cost.code" name="code[i]" (ngModelChange)="retrieveInventoryData(cost, 'code', $event)">
                        <option *ngFor="let inventory of inventories;" [value]="inventory.code">{{inventory?.code}}</option>
                    </select>
                </td>
                <td class="p-0">
                    <div class="input-group">
                        <ng-container *ngIf="cost.checker">
                            <input type="text" class="form-control form-control-sm" [(ngModel)]="cost.name" name="name[i]" (input)="summaryMaterialCost()" [disabled]="!editable">
                        </ng-container>
                        <ng-container *ngIf="!cost.checker">
                            <select class="form-control form-control-sm" [disabled]="!editable" [(ngModel)]="cost.name" name="name[i]" (ngModelChange)="retrieveInventoryData(cost, 'name', $event)">
                                <option *ngFor="let inventory of inventories;" [value]="inventory.name">{{inventory?.name}}</option>
                            </select>
                        </ng-container>
                        <span class="input-group-text">
                            <input style="width: 1.5em; height: 1.5em;" class="form-check-input" type="checkbox" [disabled]="!editable" [(ngModel)]="cost.checker" name="checker[i]" (ngModelChange)="retrieveInventoryData(cost, 'code', cost.code)">
                        </span>
                    </div>
                    
                </td>
                <td class="p-0"><input class="form-control form-control-sm" type="number" [disabled]="!editable" [(ngModel)]="cost.cost_measurement" name="cost_measurement[i]" (input)="calculate(cost)"></td>
                <td class="p-0">
                    <div class="input-group input-group-sm">
                        <input class="form-control form-control-sm" type="number" [disabled]="!editable" [(ngModel)]="cost.cost_rate" name="cost_rate[i]" (input)="calculate(cost)">
                        <span class="input-group-text" *ngIf="cost.rate_type == 'percent'">%</span>
                    </div>
                </td>
                <td class="p-0"><input class="form-control form-control-sm" type="number" disabled [(ngModel)]="cost.cost_amt" name="cost_amt[i]"></td>
                <td class="p-0"><input class="form-control form-control-sm" type="number" disabled [(ngModel)]="cost.cust_rate" name="cust_rate[i]"></td>
                <td class="p-0"><input class="form-control form-control-sm" type="number" [disabled]="!editable" [(ngModel)]="cost.amount" name="amount[i]" (input)="calculate(cost)"></td>
            </tr>
        </tbody>
    
        <thead class="table-head table-dark">
            <tr>
                <th colspan="2"></th>
                <th>Total Measurement (SQFT)</th>
                <th>QTY</th>
                <th>Cost Rate (RM)</th>
                <th></th>
            </tr>
        </thead>
        <tbody class="table-body">
            <tr>
                <td colspan="2">Total Cost Of Membrane (Roll)</td>
                <td class="p-0"><input class="form-control form-control-sm" type="number" [disabled]="!editable" [(ngModel)]="data.membrane.measurement" name="membrane_measurement"></td>
                <td class="p-0"><input class="form-control form-control-sm" type="number" [disabled]="!editable" [(ngModel)]="data.membrane.qty" (input)="cutomCalculate('membrane')" name="membrane_qty"></td>
                <td class="p-0"><input class="form-control form-control-sm" type="number" [disabled]="!editable" [(ngModel)]="data.membrane.rate" (input)="cutomCalculate('membrane')" name="membrane_rate"></td>
                <td class="p-0"><input class="form-control form-control-sm" type="number" disabled [(ngModel)]="data.membrane.total" name="membrane_total"></td>
            </tr>
            <tr>
                <td colspan="2">Total Cost If Bitumen Primer (Pail)</td>
                <td class="p-0"><input class="form-control form-control-sm" type="number" [disabled]="!editable" [(ngModel)]="data.bitumen.measurement" name="bitumen_measurement"></td>
                <td class="p-0"><input class="form-control form-control-sm" type="number" [disabled]="!editable" [(ngModel)]="data.bitumen.qty" (input)="cutomCalculate('bitumen')" name="bitumen_qty"></td>
                <td class="p-0"><input class="form-control form-control-sm" type="number" [disabled]="!editable" [(ngModel)]="data.bitumen.rate" (input)="cutomCalculate('bitumen')" name="bitumen_rate"></td>
                <td class="p-0"><input class="form-control form-control-sm" type="number" disabled [(ngModel)]="data.bitumen.total" name="bitumen_total"></td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{data.totalHiddenCost | number: '1.2-2'}}</td>
                <td></td>
                <td></td>
            </tr>
        </tbody>
    
        <tfoot class="table-foot">
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td><b>{{data.totalCostAmt | number: '1.2-2'}}</b></td>
                <td></td>
                <td><b>{{data.totalCustAmt | number: '1.2-2'}}</b></td>
            </tr>
        </tfoot>
    </table>
    
    <p class="mb-1"><b>Workmanship Cost</b></p>
    <table class="table table-sm table-bordered mb-5">
        <thead class="table-head table-dark">
            <tr>
                <th style="width:2%" class="p-0">
                    <button class="btn btn-link btn-sm py-1 px-2" [disabled]="!editable" (click)="addWorkmanship()"><svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="24" height="24" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg></button>
                </th>
                <th style="width:5%" class="text-center">No</th>
                <th>Description</th>
                <th style="width:10%">Pax/Unit</th>
                <th style="width:8%">Project Period</th>
                <th style="width:8%">Cost Rate (RM)</th>
                <th style="width:10%">Total Cost (RM)</th>
                <th style="width:16%"></th>
            </tr>
        </thead>
        <tbody class="table-body">
            <tr *ngFor="let workman of workmanship; let i = index; first as isFirst">
                <td class="p-0">
                    <button *ngIf="!isFirst && workman.is_default !== 1" class="btn btn-link btn-sm py-1 px-2" [disabled]="!editable" (click)="removeWorkmanship(i)"><svg xmlns="http://www.w3.org/2000/svg" fill="red" width="24" height="24" viewBox="0 0 24 24"><path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z"/></svg></button>
                </td>
                <td class="text-center">{{i+1}}</td>
                <td class="p-0"><input class="form-control form-control-sm" type="text" [disabled]="!editable" [(ngModel)]="workman.descp" name="descp[i]"></td>
                <td class="p-0"><input class="form-control form-control-sm" type="number" [disabled]="!editable" [(ngModel)]="workman.cost_measurement" name="cost_measurement[i]" (input)="calculateWorkman(workman)"></td>
                <td class="p-0">
                    <input class="form-control form-control-sm" type="number" [disabled]="!editable" [(ngModel)]="workman.project_period" name="project_period[i]" (input)="calculateWorkman(workman)">
                </td>
                <td class="p-0">
                    <span *ngIf="!workman.rate_type || workman.rate_type == 'fixed'">
                        <input class="form-control form-control-sm" type="number" [disabled]="!editable" [(ngModel)]="workman.cost_rate" name="cost_rate[i]" (input)="calculateWorkman(workman)">
                    </span>
                    <span *ngIf="workman.rate_type && workman.rate_type == 'percent'">
                        <div class="input-group input-group-sm">
                            <input class="form-control" type="number" [disabled]="!editable" [(ngModel)]="workman.cost_rate" name="cost_rate[i]" (input)="calculateWorkman(workman)">
                            <span class="input-group-text">%</span>
                        </div>
                    </span>
                </td>
                <td class="p-0"><input class="form-control form-control-sm" type="number" disabled [(ngModel)]="workman.cost_amt" name="cost_amt[i]"></td>
                <td class="p-0"></td>
            </tr>
            <tr *ngFor="let other of others; let i = index;">
                <td class="p-0"></td>
                <td class="text-center">{{workmanship.length + (i+1)}}</td>
                <td class="p-0"><input class="form-control form-control-sm" type="text" disabled [(ngModel)]="other.descp" name="descp[i]"></td>
                <td class="p-0"><input class="form-control form-control-sm" type="number" [disabled]="!editable" [(ngModel)]="other.cost_measurement" name="cost_measurement[i]" (input)="calculateOthers(other)"></td>
                <td class="p-0"><input class="form-control form-control-sm" type="number" [disabled]="!editable" [(ngModel)]="other.project_period" name="project_period[i]" (input)="calculateOthers(other)"></td>
                <td class="p-0">
                    <span *ngIf="!other.rate_type || other.rate_type == 'fixed'">
                        <input class="form-control form-control-sm" type="number" [disabled]="!editable" [(ngModel)]="other.cost_rate" name="cost_rate[i]" (input)="calculateOthers(other)">
                    </span>
                    <span *ngIf="other.rate_type && other.rate_type == 'percent'">
                        <div class="input-group input-group-sm">
                            <input class="form-control" type="number" [disabled]="!editable" [(ngModel)]="other.cost_rate" name="cost_rate[i]" (input)="calculateOthers(other)">
                            <span class="input-group-text">%</span>
                        </div>
                    </span>
                </td>
                <td class="p-0"><input class="form-control form-control-sm" type="number" disabled [(ngModel)]="other.cost_amt" name="cost_amt[i]"></td>
                <td class="p-0"></td>
            </tr>
        </tbody>
        <tfoot class="table-foot">
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td><b>{{data.totalWorkmanCostAmt | number: '1.2-2'}}</b></td>
                <td class="p-0"></td>
            </tr>
        </tfoot>
    </table>
    
    <div class="row">
        <div class="col-12 col-sm-12 col-md-8 col-lg-8">
            <table class="table table-sm table-bordered mb-5">
                <thead class="table-head table-dark">
                    <tr>
                        <th>Description</th>
                        <th>Total Amount</th>
                        <th>DISCOUNT 10%</th>
                        <th>DISCOUNT 20%</th>
                        <th>DISCOUNT 30%</th>
                    </tr>
                </thead>
                <tbody class="table-body">
                    <tr>
                       <td>QTT / PR Amount</td>
                       <!-- <td class="text-right">{{data.totalCustAmt  | number: '1.2-2'}}</td> -->
                       <td class="text-right">{{data.totalDocAmt | number: '1.2-2'}}</td>
                       <td class="text-right">{{discount.dis10.amount | number: '1.2-2'}}</td>
                       <td class="text-right">{{discount.dis20.amount | number: '1.2-2'}}</td>
                       <td class="text-right">{{discount.dis30.amount | number: '1.2-2'}}</td> 
                    </tr>
                    <tr>
                        <td>Total Cost Amount</td>
                        <td class="text-right">{{data.totalBudgetCost | number: '1.2-2'}}</td>
                        <td class="text-right">{{data.totalBudgetCost | number: '1.2-2'}}</td>
                        <td class="text-right">{{data.totalBudgetCost | number: '1.2-2'}}</td>
                        <td class="text-right">{{data.totalBudgetCost | number: '1.2-2'}}</td> 
                     </tr>
                     <tr class="bg-warning">
                        <td>Differences Amount</td>
                        <td class="text-right">{{data.totalDifferences | number: '1.2-2'}}</td>
                        <td class="text-right">{{discount.dis10.balance | number: '1.2-2'}}</td>
                        <td class="text-right">{{discount.dis20.balance | number: '1.2-2'}}</td>
                        <td class="text-right">{{discount.dis30.balance | number: '1.2-2'}}</td> 
                     </tr>
                     <tr>
                        <td>Profit Margin</td>
                        <td class="text-right" [ngClass]="{'bg-success': data.totalProfit >= 20, 'bg-danger': data.totalProfit < 20}">{{data.totalProfit | number: '1.2-2'}}</td>
                        <td class="text-right" [ngClass]="{'bg-success': discount.dis10.profit >= 20, 'bg-danger': discount.dis10.profit < 20}">{{discount.dis10.profit | number: '1.2-2'}}</td>
                        <td class="text-right" [ngClass]="{'bg-success': discount.dis20.profit >= 20, 'bg-danger': discount.dis20.profit < 20}">{{discount.dis20.profit | number: '1.2-2'}}</td>
                        <td class="text-right" [ngClass]="{'bg-success': discount.dis30.profit >= 20, 'bg-danger': discount.dis30.profit < 20}">{{discount.dis30.profit | number: '1.2-2'}}</td> 
                     </tr>
                     <tr>
                        <td>Discount Amount</td>
                        <td> - </td>
                        <td class="text-right">{{discount.dis10.disValue | number: '1.2-2'}}</td>
                        <td class="text-right">{{discount.dis20.disValue | number: '1.2-2'}}</td>
                        <td class="text-right">{{discount.dis30.disValue | number: '1.2-2'}}</td> 
                     </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-container>

<!-- <button class="btn btn-outline-dark btn-sm" (click)="previewPDFTemplate_DEMO()">Developer Test</button> -->
