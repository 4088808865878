import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TaskCalendarRoutingModule } from './task-calendar.routing.module';
import { TaskCalendarComponent } from './task-calendar.component';
import { LexiCalendarModule } from 'lexi-calendar';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  declarations: [
    TaskCalendarComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TaskCalendarRoutingModule,
    LexiCalendarModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  exports: [
  ]
})
export class TaskCalendarModule { }
