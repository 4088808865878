import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'lexi-api';
import { WhbDataService } from 'whb-data';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TransactionDetailComponent } from './transaction-detail/transaction-detail.component';
import { FilterComponent } from './filter/filter.component';

@Component({
  selector: 'lib-task-stock-transaction-list',
  templateUrl: './task-stock-transaction-list.component.html',
  styleUrls: ['./task-stock-transaction-list.component.css']
})
export class TaskStockTransactionListComponent implements OnInit {

  @Input() set parent_uuid(val : any) {
    this.uuid = val;
    this.initData();
  };

  public uuid:any;
  public collections:any = [];
  public grandTotal:any = 0;
  public isLoading:boolean = false;
  public filterData:any = {};
  private timeout:any = null;

  constructor(private api: ApiService, private dataService: WhbDataService, private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  async initData() {
    this.isLoading = true;
    const result = await this.api.post("/inventory/get/stock/transaction", {uuid: this.uuid, filter: this.filterData});
    this.collections = result.collections;
    this.grandTotal = result.total;

    this.isLoading = false;
  }

  async getSearch() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {

      this.timeout = null;
      this.initData()
    }, 1000);
  }

  dateFormatConverter(date:any): any {
    let d = new Date(date)
    return d.getFullYear()+"-"+("0" + (d.getMonth() + 1)).slice(-2)+"-"+("0" + (d.getDate())).slice(-2)
  }

  async exportCSV() {
    let date = new Date();
    const todayDate = date.getFullYear()+("0" + (date.getMonth() + 1)).slice(-2)+("0" + (date.getDate())).slice(-2) 
    
    let filename = todayDate+" Stock Transaction Report";
    const exportData:any = []
    exportData.push(["Date", "Item Name", "Leader Name", "Job No", "U/Price", "Stock In", "Stock Out", "Return", "Expired", "Supplier", "Supplier Invoice No", "Balance", "Remark"])
    
    for(let x = 0; x < this.collections.length; x++){
      const singleData:any = []
      singleData.push(this.dateFormatConverter(this.collections[x]['date']))
      singleData.push(this.collections[x]['item_name'])
      singleData.push(this.collections[x]['name'])
      singleData.push(this.collections[x]['reference_no'])
      singleData.push(this.collections[x]['rate'])
      singleData.push(this.collections[x]['type'] !== 'RETURN' ? this.collections[x]['debit'] : 0)
      singleData.push(this.collections[x]['type'] !== 'EXPIRED' ? this.collections[x]['credit'] : 0)
      singleData.push(this.collections[x]['type'] == 'RETURN' ? this.collections[x]['debit'] : 0)
      singleData.push(this.collections[x]['type'] == 'EXPIRED' ? this.collections[x]['credit'] : 0)
      singleData.push(this.collections[x]['supplier'])
      singleData.push(this.collections[x]['invoice_no'])
      singleData.push(this.collections[x]['balance'])
      singleData.push(this.collections[x]['license'])
      singleData.push(this.dateFormatConverter(this.collections[x]['license_expiry_date']))
      singleData.push(this.collections[x]['remark'])

      exportData.push(singleData)
    }
    this.dataService.exportCSV(filename, exportData)
  }

  async editData(data:any) {
    let modal = this.modalService.open(TransactionDetailComponent, {size: "xl"});
    modal.componentInstance.setData = data;
    modal.componentInstance.closeModal.subscribe(async(v:any) => {
      modal.close();
      this.initData();
    })
  }

  async openFilter() {
    let modal = this.modalService.open(FilterComponent, {size: "md"});
    modal.componentInstance.setFilter = this.filterData;
    modal.componentInstance.closeModal.subscribe(async(v:any) => {
      console.log(v)
      if(v?.filter){
        this.filterData = v.filter;
        this.initData();
      }
      modal.close();
    })
  }
}
