import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ApiService } from 'lexi-api';
import { LexiNotifyService } from 'lexi-notify';
import { WhbDataService } from 'whb-data';

@Component({
  selector: 'lib-task-stock-report',
  templateUrl: './task-stock-report.component.html',
  styleUrls: ['./task-stock-report.component.css']
})
export class TaskStockReportComponent implements OnInit {

  itemDropdownSettings:IDropdownSettings = {};
  userDropdownSettings:IDropdownSettings = {};
  public filterData:any = {qty_status: 'all'};
  public type:any = "stock_balance"
  public items:any = [];
  public users:any = [];
  public listing:any = [];
  public grandTotal:any = 0;
  public isLoading = false;

  constructor(private api: ApiService, private notify: LexiNotifyService, private dataService: WhbDataService) { 
    this.itemDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true
    };  

    this.userDropdownSettings = {
      singleSelection: false,
      idField: 'user_id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true
    };  
  }
  

  ngOnInit(): void {
    this.initSelection()
  }

  async initDate() {
    const date = new Date();
    this.filterData.date_from = date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-01";
    this.filterData.date_to = date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
  }

  async initSelection() {
    this.items = await this.api.post("/setting/get/stock", {})
    this.users = await this.api.post("/setting/get/all-users", {})
  }

  async submit() {
    this.isLoading = true;
    const result = await this.api.post("/admin/stock/report", {type: this.type, filterData: this.filterData})
    this.listing = result.collections;
    this.grandTotal = result.total;

    this.isLoading = false;
  }

  reset() {
    this.filterData = {qty_status: 'all'};
    this.listing = [];
    this.submit();
  }

  fileTitle(): any {
    switch(this.type){
      case "stock_balance":
        return "Stock Balance";
      case "stock_transaction":
        return "Stock Transaction"
    }
  }

  dateFormatConverter(date:any): any {
    let d = new Date(date)
    return d.getFullYear()+"-"+("0" + (d.getMonth() + 1)).slice(-2)+"-"+("0" + (d.getDate())).slice(-2)
  }

  async exportCSV() {
    let date = new Date();
    const todayDate = date.getFullYear()+("0" + (date.getMonth() + 1)).slice(-2)+("0" + (date.getDate())).slice(-2) 
    let filename = todayDate+ this.fileTitle();
    let exportData:any = []
    if(this.type == "stock_balance"){
      exportData.push(["Name", "Remark", "Balance", "Stock Balance Amount", "Updated Date"])
    }else{
      exportData.push(["Name", "Supplier", "Invoice No", "Invoice Date", "Worker", "Project Invoice", "U/Price", "Stock In", "Stock Out", "Balance", "Stock Closing Amount", "Updated Date"])
    }
    
    for(let x = 0; x < this.listing.length; x++){
      let singleData:any = []
      singleData.push(this.listing[x]['name'])
      if(this.type == "stock_balance"){
        singleData.push(this.listing[x]['remark'])
        singleData.push(this.listing[x]['balance'])
        singleData.push(this.listing[x]['balance_amount'])
        singleData.push(this.dateFormatConverter(this.listing[x]['updated_date']))
      }else{
        singleData.push(this.listing[x]['supplier'])
        singleData.push(this.listing[x]['invoice_no'])
        singleData.push(this.listing[x]['invoice_date'])
        singleData.push(this.listing[x]['user'])
        singleData.push(this.listing[x]['project_invoice'])
        singleData.push(this.listing[x]['rate'])
        singleData.push(this.listing[x]['debit'])
        singleData.push(this.listing[x]['credit'])
        singleData.push(this.listing[x]['balance'])
        singleData.push(this.listing[x]['balance_amount'])
        singleData.push(this.dateFormatConverter(this.listing[x]['create_on']))
      }

      exportData.push(singleData)
    }
    this.dataService.exportCSV(filename, exportData)
  }
}
