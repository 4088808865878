import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lib-task-costing-aftercare',
  templateUrl: './task-costing-aftercare.component.html',
  styleUrls: ['./task-costing-aftercare.component.css']
})
export class TaskCostingAftercareComponent implements OnInit {

  @Input() set parent_uuid(val : any) {
    this.uuid = val;
    this.initData()
  };

  public uuid:any;
  constructor() { }

  ngOnInit(): void {
  }

  async initData() {
    
  }
}
