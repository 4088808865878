import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'lexi-api'

@Component({
  selector: 'lib-task-stock-supplier-list',
  templateUrl: './task-stock-supplier-list.component.html',
  styleUrls: ['./task-stock-supplier-list.component.css']
})
export class TaskStockSupplierListComponent implements OnInit {

  @Input() set parent_uuid(val : any) {
    this.uuid = val;
    this.initData();
  };

  public uuid:any;
  public collections:any = [];
  public isLoading:boolean = false;
  constructor(private api: ApiService) { }

  ngOnInit(): void {
  }

  async initData() {
    this.isLoading = true;
    this.collections = await this.api.post('/inventory/get/stock/supplier', {uuid: this.uuid})
    this.isLoading = false;
  }
}
