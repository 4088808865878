import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TaskProjectComponent } from './task-project.component';
import { TaskProjectCalendarComponent } from './task-project-calendar/task-project-calendar.component';
import { TaskProjectGrabComponent } from './task-project-grab/task-project-grab.component';
import { TaskProjectReportComponent } from './task-project-report/task-project-report.component';
import { TaskProjectReportDetailComponent } from './task-project-report/task-project-report-detail/task-project-report-detail.component';

const routes: Routes = [
  {path: "", component: TaskProjectComponent},
  {path: "calendar", component: TaskProjectCalendarComponent},
  {path: "grab", component: TaskProjectGrabComponent},
  {path: "leader/report", component: TaskProjectReportComponent},
  {path: "leader/report-detail", component: TaskProjectReportDetailComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TaskProjectRoutingModule { }
