import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TaskProjectRoutingModule } from './task-project.routing.module';
import { TaskProjectComponent } from './task-project.component';
import { TaskProjectFilterComponent } from './task-project-filter/task-project-filter.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown'
import { TaskProjectConvertComponent } from './task-project-convert/task-project-convert.component';
import { BarComponent, DotComponent } from 'whb-loading';
import { TaskProjectMaterialInquiryComponent } from './task-project-material-inquiry/task-project-material-inquiry.component';
import { InputMonitorDirective } from '../input-monitor.directive';
import { TaskProjectCompleteConfirmationComponent } from './task-project-complete-confirmation/task-project-complete-confirmation.component';
import { TaskProjectCompleteCancelComponent } from './task-project-complete-cancel/task-project-complete-cancel.component';
import { TaskProjectCalendarComponent } from './task-project-calendar/task-project-calendar.component';
import { LexiCalendarModule } from 'lexi-calendar';
import { TaskProjectGrabComponent } from './task-project-grab/task-project-grab.component';
import { TaskProjectReportComponent } from './task-project-report/task-project-report.component';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TaskProjectReportDetailComponent } from './task-project-report/task-project-report-detail/task-project-report-detail.component';

@NgModule({
  declarations: [
    TaskProjectComponent,
    TaskProjectFilterComponent,
    TaskProjectConvertComponent,
    TaskProjectMaterialInquiryComponent,
    TaskProjectCompleteConfirmationComponent,
    InputMonitorDirective,
    TaskProjectCompleteCancelComponent,
    TaskProjectCalendarComponent,
    TaskProjectGrabComponent,
    TaskProjectReportComponent,
    TaskProjectReportDetailComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TaskProjectRoutingModule,
    NgMultiSelectDropDownModule.forRoot(),
    BarComponent,
    DotComponent,
    LexiCalendarModule,
    NgbNavModule
  ],
  exports: [
  ]
})
export class TaskProjectModule { }
