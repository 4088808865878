import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'lexi-api';
import { LexiNotifyService } from 'lexi-notify';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'lib-transaction-detail',
  templateUrl: './transaction-detail.component.html',
  styleUrls: ['./transaction-detail.component.css']
})
export class TransactionDetailComponent implements OnInit {

  employeeDropdownSettings:IDropdownSettings = {};

  @Input() set setData(v:any) {
    this.data = v;
    this.initData()
  }
  @Output() closeModal = new EventEmitter();

  public data:any = {};
  public users:any = [];
  public locations:any = [];
  public isLoading:boolean = false;
  public isBtnLoading:boolean = false;

  constructor(private api: ApiService, private notify: LexiNotifyService) { 
    this.employeeDropdownSettings = {
      singleSelection: true,
      idField: 'user_id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 20,
      allowSearchFilter: true
    };
  }

  ngOnInit(): void {
  }

  async initData() {
    this.isLoading = true;
    this.locations = await this.api.post("/inventory/get/warehouse", {status: 1})
    this.users = await this.api.post("/setting/get/all-users", {});
    const user = this.users.find((u:any) => u.user_id === this.data.user_id);
    if(user){
      this.data.userIds = [{user_id: user.user_id, name: user.name}]
    }
    this.isLoading = false;
  }

  async submit() {
    this.isBtnLoading = true;
    const result = await this.api.post('/inventory/update/stock/transaction', this.data)
    this.isBtnLoading = false;
    if(!result.status){
      return this.notify.error(result.message)
    }

    this.notify.success("Transaction update successfully.")
    this.close()
  }

  close() {
    this.closeModal.emit(false)
  }
}
