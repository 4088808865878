import { Directive, ElementRef, EventEmitter, Output, OnDestroy } from '@angular/core';
import { fromEvent, Subject, timer } from 'rxjs';
import { debounce, debounceTime, switchMap, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appInputMonitor]'
})
export class InputMonitorDirective implements OnDestroy {
  @Output() inputChange: EventEmitter<string> = new EventEmitter<string>();

  private destroy$: Subject<void> = new Subject<void>();

  constructor(private elementRef: ElementRef) {
    fromEvent(this.elementRef.nativeElement, 'input')
      .pipe(
        debounceTime(3000), // Debounce for 3 seconds
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.inputChange.emit(this.elementRef.nativeElement.value);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}