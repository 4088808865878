import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'lexi-api';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { TaskMapDetailComponent } from '../task-map-detail/task-map-detail.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'lib-task-map',
  templateUrl: './task-map.component.html',
  styleUrls: ['./task-map.component.css']
})
export class TaskMapComponent implements OnInit {

  dropdownList:any = [];
  selectedItems:any = [];
  dropdownSettings:IDropdownSettings = {};

  public uuid: any;
  public statusList:any = [];
  public maps:any = [];
  public data:any = {}
  public options:any = [];

  center: google.maps.LatLngLiteral = {lat: 2.9954062687961254, lng: 101.68030593390412};
  zoom = 16;
  markerOptions: google.maps.MarkerOptions = {draggable: false};
  markerPositions: {title:string, label:string, position: google.maps.LatLngLiteral, options: google.maps.MarkerOptions}[] = [];

  constructor(
    private api: ApiService,
    private activeRoute: ActivatedRoute,
    private offcanvasService: NgbOffcanvas,
    private modalService: NgbModal
  ) { 
    if(this.activeRoute.snapshot.paramMap.get('latitude') && this.activeRoute.snapshot.paramMap.get('longitude')){
      const latitude:any = this.activeRoute.snapshot.paramMap.get('latitude')
      const longitude:any = this.activeRoute.snapshot.paramMap.get('longitude')
      this.center = {lat: parseFloat(latitude), lng: parseFloat(longitude)};
    }

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'val',
      textField: 'title',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true
    };
  }

  ngOnInit(): void {
    this.getStatus();
    this.initData();
  }

  async initData() {
    this.markerPositions = [];
    this.maps = await this.api.post('/task/maps', this.data)
    console.log(this.maps)
    
    this.markerPositions.push({label: "WAI HONG BROTHERS SDN BHD", position:{lat: 2.9954062687961254, lng: 101.68030593390412}, title: "WHB HQ", 
                  options: {draggable: false,
                    icon:{
                      path: 'M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z', 
                      fillOpacity: 0.8,
                      fillColor: "black",
                      anchor: new google.maps.Point(12,17),
                      strokeWeight: 2,
                      strokeColor: "white",
                      scale: 2
                    }
                  }})
    
    for(var x = 0; x < this.maps.length; x++) {
      let current = this.maps[x];
      var markColor;
      switch(current.child_status) {
        case "pending_inspec_schedule":
          markColor = "red";
        break;
        case "pending_confirm":
          markColor = 'yellow'
        break;
        case "pending_site_inspec":
          markColor = 'green';
        break;
        default:
          markColor = 'blue'
      }

      this.markerPositions.push({label: current.name, position:{lat: parseFloat(current.latitude), lng: parseFloat(current.longitude)}, title: current.name, 
      options: {draggable: false,
      icon:{
        path: 'M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z', 
        fillOpacity: 0.8,
        fillColor: markColor,
        anchor: new google.maps.Point(12,17),
        strokeWeight: 2,
        strokeColor: "white",
        scale: 2
      }}})
    }
  }

  async getStatus() {
    this.statusList = await this.api.post('/task/get-progress', {identifier: "task-progress"}) 
    for(let x = 0; x < this.statusList.length; x++){
      for(let y = 0; y < this.statusList[x]['child'].length; y++){
        let child = this.statusList[x]['child'][y];
        this.options.push({title: child['title'], val: child['identifier']})
      }
    }   
  }

  viewDetail(detail:any) {
    this.maps.forEach((map:any) => {
      if(parseFloat(map.latitude) == detail.position.lat && parseFloat(map.longitude) == detail.position.lng){
        if(this.offcanvasService.hasOpenOffcanvas()){
          this.offcanvasService.dismiss()
        }
    
        let canvas = this.offcanvasService.open(TaskMapDetailComponent, {position: "end", backdropClass: "custom-canvas-backdrop", panelClass: "bg-light custom-canvas", scroll: true})
        canvas.componentInstance.data = map;
        canvas.componentInstance.close.subscribe(async (status:boolean) => {
          canvas.close()  
        })
      }
    });
  }

  openModal(target:any){
    this.modalService.open(target, {})
  }

  closeModal(){
    this.modalService.dismissAll()
  }

  search() {
    this.initData()
    this.closeModal()
  }

  onItemSelect(item: any) {
    console.log(item)
  }
  onSelectAll(items: any) {
    console.log(items)
  }

}
