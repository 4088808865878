import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'lexi-api';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lib-task-project-report',
  templateUrl: './task-project-report.component.html',
  styleUrls: ['./task-project-report.component.css']
})
export class TaskProjectReportComponent implements OnInit {

  public filter:any = {}
  public data:any = {incompleteProgress: []}
  public isLoading:boolean = false;
  public currentActive:any = "incompleteProgress";

  constructor(private api: ApiService, private router: Router) { 
    const today = new Date();

    const sevenDaysAgo = new Date(today);
          sevenDaysAgo.setDate(today.getDate() - 7);

    this.filter.date_from = sevenDaysAgo.getFullYear() + "-" + ("0" + (sevenDaysAgo.getMonth() + 1)).slice(-2) + "-" + ("0" + sevenDaysAgo.getDate()).slice(-2);
    this.filter.date_to = today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + ("0" + today.getDate()).slice(-2);
    this.init()
  }

  ngOnInit(): void {
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.currentActive = changeEvent.nextId;
	}

  async init() {
    this.isLoading = true;
    this.data = await this.api.post("/admin/leader/report", this.filter)
    console.log(this.data)
    this.isLoading = false
  }

  onClick(userID:number) {
    this.router.navigate(['/task-schedule/progress/leader/report-detail'], { queryParams: {user_id: userID} })
  }

}
