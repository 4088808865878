<div class="map-detail">
    <button class="btn btn-link" (click)="closeCanvas()">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"/></svg>
    </button>
    <div class="row">
        <div class="col-12">
            <table class="table table-hover table-sm table-slot">
            <thead class="thead-dark">
                <tr>
                    <th colspan="2">Detail</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th style="width: 35%;">Owner Name</th>
                    <td>{{data.owner_name}}</td>
                </tr>
                <tr>
                    <th style="width: 35%;">Contact</th>
                    <td>{{data.owner_mobile}}</td>
                </tr>
                <tr>
                    <th style="width: 35%;">Email</th>
                    <td>{{data.owner_email}}</td>
                </tr>
                <tr>
                    <th style="width: 35%;">Building Type</th>
                    <td>{{data.building}}</td>
                </tr>
                <tr>
                    <th style="width: 35%;">Address 1</th>
                    <td>{{data.address_1}}</td>
                </tr>
                <tr>
                    <th style="width: 35%;">Address 2</th>
                    <td>{{data.address_2}}</td>
                </tr>
                <tr>
                    <th style="width: 35%;">Area</th>
                    <td>{{data.area}}</td>
                </tr>
                <tr>
                    <th style="width: 35%;">Postcode</th>
                    <td>{{data.postcode_id}}</td>
                </tr>
                <tr>
                    <th style="width: 35%;">State</th>
                    <td>{{data.state}}</td>
                </tr>
                <tr>
                    <th style="width: 35%;">Country</th>
                    <td>{{data.country}}</td>
                </tr>
                
                <tr>
                    <th style="width: 35%;">Work Estimate</th>
                    <td>
                        <span class="d-flex justify-content-between">
                            <span>{{data.entry_estimate}}</span>
                            <button class="btn btn-sm btn-outline-dark py-0" (click)="viewDocument('estimate')">View</button>
                        </span>
                    </td>
                </tr>
                <tr>
                    <th style="width: 35%;">Quotation</th>
                    <td>
                        <span class="d-flex justify-content-between">
                            <span>{{data.entry_quotation}}</span>
                            <button class="btn btn-sm btn-outline-dark py-0" (click)="viewDocument('quotation')">View</button>
                        </span>
                    </td>
                </tr>
                <tr>
                    <th style="width: 35%;">Proforma Invoice</th>
                    <td>
                        <span class="d-flex justify-content-between">
                            <span>{{data.entry_proforma}}</span>
                            <button class="btn btn-sm btn-outline-dark py-0" (click)="viewDocument('proforma')">View</button>
                        </span>
                    </td>
                </tr>
                <tr>
                    <th style="width: 35%;">Invoice</th>
                    <td>
                        <span class="d-flex justify-content-between">
                            <span>{{data.entry_invoice}}</span>
                            <button class="btn btn-sm btn-outline-dark py-0" (click)="viewDocument('invoice')">View</button>
                        </span>
                    </td>
                </tr>
                
                <tr>
                    <td colspan="2">
                        <button class="btn btn-primary w-100" (click)="view()">Customer Detail</button>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <button class="btn btn-dark w-100" (click)="viewProject()">Project Detail</button>
                    </td>
                </tr>
                <hr>
                <tr>
                    <td colspan="2">
                        <button class="btn waze-btn w-100" (click)="getDirection('waze')">
                            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48"><path fill="#37474f" d="M27,38C9.1,38,5.2,33.2,3.6,31.1c-0.4-0.4-0.6-1-0.6-1.6C3,28.1,4.1,27,5.5,27C6.4,27,9,27,9,22.1 v-0.6C9,12.4,17.1,5,27,5s18,7.4,18,16.5S36.9,38,27,38z"/><path fill="#eceff1" d="M27,36c8.8,0,16-6.5,16-14.5S35.8,7,27,7s-16,6.5-16,14.5v0.6c0,6.2-3.8,6.9-5.5,6.9 C5.2,29,5,29.2,5,29.5c0,0.1,0,0.2,0.1,0.3C6.6,31.7,10,36,27,36z"/><path fill="#37474f" d="M32 16A2 2 0 1 0 32 20 2 2 0 1 0 32 16zM22 16A2 2 0 1 0 22 20 2 2 0 1 0 22 16zM27 29c-4.8 0-6.7-3.5-7-5.3-.1-.5.3-1.1.8-1.2.5-.1 1.1.3 1.2.8 0 .1.7 3.7 5 3.7 4.3 0 5-3.5 5-3.7.1-.5.6-.9 1.2-.8.5.1.9.6.8 1.1C33.7 25.5 31.8 29 27 29zM16.5 34A4.5 4.5 0 1 0 16.5 43 4.5 4.5 0 1 0 16.5 34z"/><path fill="#607d8b" d="M16.5 37A1.5 1.5 0 1 0 16.5 40A1.5 1.5 0 1 0 16.5 37Z"/><path fill="#37474f" d="M32.5 34A4.5 4.5 0 1 0 32.5 43A4.5 4.5 0 1 0 32.5 34Z"/><path fill="#607d8b" d="M32.5 37A1.5 1.5 0 1 0 32.5 40A1.5 1.5 0 1 0 32.5 37Z"/></svg>
                            <span class="ms-2">WAZE</span>
                        </button>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <button class="btn google-btn w-100" (click)="getDirection('google')">
                            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48"><path fill="#48b564" d="M35.76,26.36h0.01c0,0-3.77,5.53-6.94,9.64c-2.74,3.55-3.54,6.59-3.77,8.06	C24.97,44.6,24.53,45,24,45s-0.97-0.4-1.06-0.94c-0.23-1.47-1.03-4.51-3.77-8.06c-0.42-0.55-0.85-1.12-1.28-1.7L28.24,22l8.33-9.88	C37.49,14.05,38,16.21,38,18.5C38,21.4,37.17,24.09,35.76,26.36z"/><path fill="#fcc60e" d="M28.24,22L17.89,34.3c-2.82-3.78-5.66-7.94-5.66-7.94h0.01c-0.3-0.48-0.57-0.97-0.8-1.48L19.76,15	c-0.79,0.95-1.26,2.17-1.26,3.5c0,3.04,2.46,5.5,5.5,5.5C25.71,24,27.24,23.22,28.24,22z"/><path fill="#2c85eb" d="M28.4,4.74l-8.57,10.18L13.27,9.2C15.83,6.02,19.69,4,24,4C25.54,4,27.02,4.26,28.4,4.74z"/><path fill="#ed5748" d="M19.83,14.92L19.76,15l-8.32,9.88C10.52,22.95,10,20.79,10,18.5c0-3.54,1.23-6.79,3.27-9.3	L19.83,14.92z"/><path fill="#5695f6" d="M28.24,22c0.79-0.95,1.26-2.17,1.26-3.5c0-3.04-2.46-5.5-5.5-5.5c-1.71,0-3.24,0.78-4.24,2L28.4,4.74	c3.59,1.22,6.53,3.91,8.17,7.38L28.24,22z"/></svg>
                            <span class="ms-2">Google Map</span>
                        </button>
                    </td>
                </tr>
                <tr *ngIf="data.address_link">
                    <td colspan="2">
                        <button class="btn btn-outline-dark w-100" (click)="viewProvidedLink()">Custom Address Link</button>
                    </td>
                </tr>
            </tbody>
        </table>
        </div>
    </div>
</div>