<div class="card">
    <div class="card-body">
        <div class="d-flex">
            <ng-multiselect-dropdown
                [placeholder]="''"
                [settings]="dropdownSettings"
                [data]="taskStatus"
                [(ngModel)]="selectedStatus" class="w-100">
            </ng-multiselect-dropdown>
            <button class="btn btn-primary btn-sm w-25 ms-1" (click)="search()">Search</button>
        </div>


        <lib-lexi-calendar #calendar></lib-lexi-calendar>
    </div>
</div>