
<div class="modal-body px-0 pb-0">
    <div class="d-flex flex-row justify-content-between align-items-center mb-4 px-3">
        <h3 class="mb-0"><b>Quick Transaction</b></h3>
        <button class="btn btn-link text-danger" (click)="close()">
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x-lg">
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
            </svg>
        </button>
    </div>
    <ng-container *ngIf="isLoading">
        <lib-dot></lib-dot>
    </ng-container>
    <ng-container  *ngIf="!isLoading">
        <ul ngbNav #nav="ngbNav" [(activeId)]="currentActive" (navChange)="onNavChange($event)" class="nav-tabs">
            <li [ngbNavItem]="'stock_in'" class="col">
                <a ngbNavLink class="rounded-0">Stock In</a>
                <ng-template ngbNavContent>
                    <div class="card p-5 border-top-0 rounded-0">
                        <h5><b>STOCK IN</b></h5>
                        <hr>
                        <form>
                            <div class="form-group">
                                <label>Warehouse</label>
                                <ng-select class="form-control p-0 border-0" (change)="detectItems()" [(ngModel)]="data.location_id" name="location_id">
                                    <ng-option *ngFor="let location of locations" [value]="location.id">{{location.code}}</ng-option>
                                </ng-select>
                            </div>
                            <div class="form-group">
                                <label>Item</label>
                                <ng-select class="form-control p-0 border-0" (change)="detectChanges($event)" [(ngModel)]="selectedItem" name="item">
                                    <ng-option *ngFor="let item of items" [value]="item">{{item.name}}</ng-option>
                                </ng-select>
                            </div>
                            <h5 class="text-primary mb-4" *ngIf="selectedItem && data.stock_balance"><b><span>Item Balance :</span><span class="ms-3">{{data.stock_balance}}</span><span class="ms-2">PCS</span></b></h5>
                            <div class="form-group">
                                <label>Date</label>
                                <input class="form-control" type="date" [(ngModel)]="data.date" name="date">
                            </div>
                            <div class="form-group">
                                <label>Supplier Name</label>
                                <input class="form-control" type="text" [(ngModel)]="data.supplier" name="supplier_name">
                            </div>   
                            <div class="form-group">
                                <label>Supplier Invoice No</label>
                                <input class="form-control" type="text" [(ngModel)]="data.invoice_no" name="invoice_no">
                            </div> 
                            <div class="form-group">
                                <label>Transaction Type</label>
                                <select class="form-control" [(ngModel)]="data.type" name="type">
                                    <option [value]="'IN'">IN</option>
                                    <option [value]="'RETURN'">RETURN</option>
                                    <option [value]="'ADJUST'">ADJUSTMENT</option>
                                </select>
                            </div> 
                            <div class="form-group">
                                <label>Employee Name</label>
                                <ng-multiselect-dropdown
                                    [placeholder]="''"
                                    [settings]="employeeDropdownSettings"
                                    [data]="users"
                                    [(ngModel)]="data.userIds"
                                    name="userIds">
                                </ng-multiselect-dropdown>
                            </div>   
                            <div class="form-group">
                                <label>Job Invoice No</label>
                                <input class="form-control" type="text" [(ngModel)]="data.reference_no" name="reference_no">
                            </div> 
                            <div class="form-group">
                                <label>Unit Price</label>
                                <input class="form-control" type="number" [(ngModel)]="data.rate" name="rate">
                            </div>
                            <div class="form-group">
                                <label>Stock In Quantity</label>
                                <input class="form-control" type="number" [(ngModel)]="data.debit" name="debit">
                            </div>
                            <div class="form-group">
                                <label>License</label>
                                <select class="form-control" [(ngModel)]="data.license" name="license">
                                    <option [value]="'YES'">YES</option>
                                    <option [value]="'NO'">NO</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label>License Expired</label>
                                <input class="form-control" type="date" [(ngModel)]="data.license_expiry_date" name="license_expiry_date">
                            </div>
                            <div class="form-group">
                                <label>Remark</label>
                                <textarea class="form-control" rows="3" [(ngModel)]="data.remark" name="remark"></textarea>
                            </div>
    
                            <button class="btn btn-primary w-100" (click)="submit()" [disabled]="isBtnLoading">
                                <ng-container *ngIf="!isBtnLoading">Save</ng-container>
                                <ng-container *ngIf="isBtnLoading"><lib-dot></lib-dot></ng-container>
                            </button>
                        </form>
                    </div>
                </ng-template>
            </li>
            <li [ngbNavItem]="'stock_out'" class="col">
                <a ngbNavLink class="rounded-0">Stock Out</a>
                <ng-template ngbNavContent>
                    <div class="card p-5 border-top-0 rounded-0">
                        <h5><b>STOCK OUT</b></h5>
                        <hr>
                        <form>
                            <div class="form-group">
                                <label>Warehouse</label>
                                <ng-select class="form-control p-0 border-0" (change)="detectItems()" [(ngModel)]="data.location_id" name="location_id">
                                    <ng-option *ngFor="let location of locations" [value]="location.id">{{location.code}}</ng-option>
                                </ng-select>
                            </div>
                            <div class="form-group">
                                <label>Item</label>
                                <ng-select class="form-control p-0 border-0" (change)="detectChanges($event)" [(ngModel)]="selectedItem" name="item">
                                    <ng-option *ngFor="let item of items" [value]="item">{{item.name}}</ng-option>
                                </ng-select>
                            </div>
                            <h5 class="text-primary mb-4" *ngIf="selectedItem && data.stock_balance"><b><span>Item Balance :</span><span class="ms-3">{{data.stock_balance}}</span><span class="ms-2">PCS</span></b></h5>

                            <div class="form-group">
                                <label>Date</label>
                                <input class="form-control" type="date" [(ngModel)]="data.date" name="date">
                            </div>
                            <div class="form-group">
                                <label>Employee Name</label>
                                <ng-multiselect-dropdown
                                    [placeholder]="''"
                                    [settings]="employeeDropdownSettings"
                                    [data]="users"
                                    [(ngModel)]="data.userIds"
                                    name="userIds">
                                </ng-multiselect-dropdown>
                            </div>   
                            <div class="form-group">
                                <label>Job Invoice No</label>
                                <input class="form-control" type="text" [(ngModel)]="data.reference_no" name="reference_no">
                            </div> 
                            <div class="form-group">
                                <label>Transaction Type</label>
                                <select class="form-control" [(ngModel)]="data.type" name="type">
                                    <option [value]="'OUT'">OUT</option>
                                    <option [value]="'EXPIRED'">EXPIRED</option>
                                    <option [value]="'ADJUST'">ADJUSTMENT</option>
                                </select>
                            </div> 
                            <div class="form-group">
                                <label>Stock Out Quantity</label>
                                <input class="form-control" type="number" [(ngModel)]="data.credit" name="credit">
                            </div>
                            <div class="form-group">
                                <label>License</label>
                                <select class="form-control" [(ngModel)]="data.license" name="license">
                                    <option [value]="'YES'">YES</option>
                                    <option [value]="'NO'">NO</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label>License Expired</label>
                                <input class="form-control" type="date" [(ngModel)]="data.license_expiry_date" name="license_expiry_date">
                            </div>
                            <div class="form-group">
                                <label>Remark</label>
                                <textarea class="form-control" rows="3" [(ngModel)]="data.remark" name="remark"></textarea>
                            </div>
    
                            <button class="btn btn-primary w-100" (click)="submit()" [disabled]="isBtnLoading">
                                <ng-container *ngIf="!isBtnLoading">Save</ng-container>
                                <ng-container *ngIf="isBtnLoading"><lib-dot></lib-dot></ng-container>
                            </button>
                        </form>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
    </ng-container>


</div>
